function styles(theme) {
  return {
    root: {
      '& .MuiDialog-paper': {
        borderRadius: 0
      }
    },
    adminInput: {
      '& .MuiOutlinedInput-root': {
        height: 34,
        fontSize: 12
      },
      '& .MuiInputLabel-root': {
        fontSize: 12,
        lineHeight: 1,
        marginTop: -4,
        width: 'auto'
      },
      '& .MuiInputBase-input': {
        paddingTop: 8,
        paddingBottom: 8
      }
    },
    textInput: {
      '& > label, & > div > input': {
        color: '#fff'
      }
    },
    halfInput: {
      width: '50%'
    },
    removePadding: {
      '& input': {
        padding: 3
      }
    },
    gridStyle: {
      backgroundColor: '#364150'
    },
    headingStyle: {
      float: 'right',
      paddingRight: 16,
      paddingBottom: 8
    }
  };
}

export default styles;
