import React, { useEffect, useState, useContext } from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/core/styles';
import useStyles from '../../../../../../styles';
import { formatter, numberformatter } from '../../../../../../helpers';
import { ReportContext } from '../../../../../../context/ReportContext';
import { ReportGridContext } from '../../../../../../context/ReportGridContext';
import Overlay from '../../../../../../components/Overlay';
import { getPublihserSummary } from '../../../../../../server';

// const publishers = [12, '12a', '12b'];
// const softwares = ['12c', '12d'];

const Counter = styled('div')(({ theme }) => ({
  height: 150,
  minWidth: 100,
  width: '33%',
  // maxWidth: 150,
  marginRight: 10,
  padding: '0 10px',
  background: '#434F5E',
  display: 'inline-block',
  borderRadius: '5px',
  boxShadow: '0px 0px 8px 2px #0000001a'
}));

const CounterContent = styled('div')({
  // position: 'relative',
  // top: '50%',
  // transform: 'translateY(-50%)'
});

const CounterHits = styled('p')({
  fontSize: 24,
  color: '#F4F4F4',
  textAlign: 'right',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});

const CounterLabel = styled('p')({
  fontWeight: 400,
  fontSize: 15,
  color: '#F4F4F4',
  textAlign: 'right'
});

export default function Tab(props) {
  const classes = useStyles();
  const [rowData, setRowData] = useState(null);
  const contextData = useContext(ReportContext);
  const contextGridData = useContext(ReportGridContext);
  const publisherIdArr = [];
  const productLineIdArr = [];
  const productIdArr = [];

  useEffect(() => {
    if (contextData?.level[0]) {
      for (let i = 0; i < contextData?.level[0].allLeafChildren.length; i += 1) {
        if (!publisherIdArr.includes(contextData?.level[0].allLeafChildren[i].data.publisherId)) {
          publisherIdArr.push(contextData?.level[0].allLeafChildren[i].data.publisherId);
        }
        // if (
        //   !productLineIdArr.includes(contextData?.level[0].allLeafChildren[i].data.productLineId)
        // ) {
        //   productLineIdArr.push(contextData?.level[0].allLeafChildren[i].data.productLineId);
        // }
        // if (!productIdArr.includes(contextData?.level[0].allLeafChildren[i].data.productId)) {
        //   productIdArr.push(contextData?.level[0].allLeafChildren[i].data.productId);
        // }
      }
      getPublihserSummary(publisherIdArr, contextGridData.report)
        .then((data) => updateData(data))
        .catch((e) => {
          console.log(e);
        });
    } else {
      for (let i = 0; i < contextData?.data.length; i += 1) {
        if (!publisherIdArr.includes(contextData?.data[i].publisherId)) {
          publisherIdArr.push(contextData?.data[i].publisherId);
        }
        // if (!productLineIdArr.includes(contextData?.data[i].productLineId)) {
        //   productLineIdArr.push(contextData?.data[i].productLineId);
        // }
        // if (!productIdArr.includes(contextData?.data[i].productId)) {
        //   productIdArr.push(contextData?.data[i].productId);
        // }
      }
      getPublihserSummary(publisherIdArr, contextGridData.report)
        .then((data) => updateData(data))
        .catch((e) => {
          console.log(e);
        });
    }
  }, [contextData]);

  // const getPublihserSummary = async () => {
  //   try {
  //     if (publisherIdArr.length === 0) {
  //       throw new Error();
  //       return;
  //     }
  //     const reportId =
  //       softwares.includes(contextGridData.report) || publishers.includes(contextGridData.report)
  //         ? 12
  //         : contextGridData.report;
  //     const url = `${BASE_URL}/Reports/GetPublihserSummary`;

  //     const response = await fetch(url, {
  //       method: 'post',
  //       credentials: 'include',
  //       body: JSON.stringify({
  //         publisherID: publisherIdArr[0],
  //         reportName: reportId,
  //         productLineIds: '', //productLineIdArr.toString(),
  //         productIds: '' //productIdArr.toString()
  //       }),
  //       headers: { 'Content-Type': 'application/json' }
  //     });
  //     const data = await response.json();
  //     return data;
  //   } catch (e) {
  //     throw new Error(e);
  //     console.log(e);
  //   }
  // };

  const updateData = (data) => {
    if (data?.data) setRowData(data.data);
  };

  return (
    <Box
      className={clsx(classes.dFlex)}
      sx={{
        overflowX: 'auto',
        minWidth: '500',
        paddingLeft: 2,
        paddingTop: '15px',
        height: 'calc(100vh - 165px)',
        position: 'relative'
      }}
    >
      <Box sx={{ width: '100%' }}>
        {rowData ? (
          <>
            <Box className={clsx(classes.dFlex, classes.flexEvenly)} sx={{ minWidth: 400 }}>
              <Counter sx={{ background: '#3b4652' }}>
                <CounterContent>
                  <CounterLabel gutterBottom sx={{ mt: 1 }}>
                    ProductLines
                  </CounterLabel>
                  <CounterHits>{numberformatter(rowData?.productLinesCount)}</CounterHits>
                  <CounterLabel gutterBottom sx={{ mt: 2 }}>
                    Licensable
                  </CounterLabel>
                  <CounterHits>{numberformatter(rowData?.licensableProductLines)}</CounterHits>
                </CounterContent>
              </Counter>
              <Counter sx={{ background: '#207e57' }}>
                <CounterContent>
                  <CounterLabel gutterBottom sx={{ mt: 1 }}>
                    Financial Footprint
                  </CounterLabel>
                  <CounterHits>{formatter.format(rowData?.financialFootprint)}</CounterHits>
                </CounterContent>
              </Counter>
              <Counter sx={{ background: '#323134' }}>
                <CounterContent>
                  <CounterLabel gutterBottom sx={{ mt: 1 }}>
                    Licensable Product Lines without License Quantities
                  </CounterLabel>
                  <CounterHits>{numberformatter(rowData?.withoutLicensesCount)}</CounterHits>
                </CounterContent>
              </Counter>
              <Counter sx={{ background: '#925552', textAlign: 'center' }}>
                <CounterContent>
                  <CounterLabel gutterBottom sx={{ mt: 1, textAlign: 'center' }}>
                    License Exposure
                  </CounterLabel>
                  <CounterHits title={formatter.format(rowData?.licesnseExposure)}>
                    {formatter.format(rowData?.licesnseExposure)}
                  </CounterHits>
                  {/* <ReactSpeedometer
                  value={rowData?.licesnseExposure}
                  valueFormat="$.2s"
                  textColor="#F4F4F4"
                  width={100}
                  height={100}
                  segments={1}
                  needleColor="#5e6d7d"
                  segmentColors={['#50d0b4']}
                  ringWidth={10}
                /> */}
                </CounterContent>
              </Counter>
              {/* <Speedometer>
              <ReactSpeedometer value={rowData?.licesnseExposure} textColor="#F4F4F4" />
            </Speedometer> */}
            </Box>
          </>
        ) : (
          // <div
          //   style={{
          //     display: 'flex',
          //     justifyContent: 'center',
          //     alignItems: 'center',
          //     alignContent: 'space-around',
          //     height: '100%',
          //     minWidth: '100%'
          //   }}
          // >
          //   <p>
          //     <CircularProgress size={16} sx={{ mr: 1 }} />
          //     {''}
          //     Loading...
          //   </p>
          // </div>
          <Overlay>Loading...</Overlay>
        )}
      </Box>
    </Box>
  );
}
