import { useState, useEffect } from 'react';
import clsx from 'clsx';
// material
import {
  Button,
  Stack,
  Container,
  IconButton,
  Typography,
  Grid,
  Autocomplete
} from '@material-ui/core';
import { uniqBy } from 'lodash';
// components
import Page from '../components/Page';
import WatchlistGrid from '../components/grids/watchlistGrid';
import '../styles/css/Split.css';
import useStyles from '../styles';
import { GetGridAccountProductLinesWL, GetSoftwareTitlesWL, AddToWatchList } from '../server';
// import WatchlistGrid from '../components/grids/watchlistGrid';

export default function Watchlist() {
  const [data, setData] = useState();
  const [softwareTitles, setSoftwareTitles] = useState(null);
  const [gridApi, setGridApi] = useState();
  const [productline, setProductline] = useState();
  const [status, setStatus] = useState(0);
  const classes = useStyles();

  const exportToExcel = () => {
    if (gridApi) {
      gridApi.exportDataAsExcel({
        fileName: `WatchList.xlsx`
      });
    }
  };

  useEffect(() => {
    GetGridAccountProductLinesWL()
      .then((res) => {
        if (res?.data?.modelList) {
          setData(res?.data?.modelList);
        } else {
          setData([]);
        }
      })
      .catch(() => {});

    GetSoftwareTitlesWL()
      .then((res) => {
        if (res?.data?.reportList) {
          const uniq = uniqBy(res.data.reportList, 'productline');
          setSoftwareTitles(uniq);
        }
      })
      .catch(() => {});
  }, [status]);

  const addToWatchList = () => {
    if (productline) {
      AddToWatchList(productline)
        .then((res) => {
          if (res?.status) {
            alert('success');
            setStatus(status + 1);
          }
        })
        .catch(() => {});
    }
  };

  return (
    <Page title="Watchlist Grid | AssetLabs" className={classes.bodyContainer}>
      <Container className={classes.fullWidth}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4">Licensing WatchList</Typography>
        </Stack>
        <div style={{ borderRadius: 0 }} className={clsx(classes.cardContainer, classes.pa0)}>
          <>
            <Typography variant="h6" sx={{ fontWeight: 500, mb: '-7px' }}>
              Select Softare Title
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              className={classes.controlsContainer}
            >
              {/* left icon buttons */}
              <Grid sx={{ display: 'flex' }}>
                <Autocomplete
                  disablePortal
                  id="productlineid"
                  getOptionLabel={(option) => option?.productline || ''}
                  options={softwareTitles}
                  disabled={softwareTitles === null}
                  // value={subCategoryId}
                  renderOption={(props, option) => (
                    <span key={option.productlineid} {...props}>{`${option.productline}`}</span>
                  )}
                  onChange={(event, newValue) => {
                    if (newValue && newValue.productlineid) {
                      setProductline(newValue.productlineid);
                    }
                  }}
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <input
                        name="productlineid"
                        type="text"
                        style={{
                          width: 400,
                          height: 25,
                          background: 'url(assets/caret_down.png) right 4px center no-repeat scroll'
                        }}
                        {...params.inputProps}
                      />
                    </div>
                  )}
                />
                <Button
                  sx={{ ml: 2 }}
                  onClick={addToWatchList}
                  className={classes.tinyButton}
                  size="small"
                >
                  Add to Watchlist
                </Button>
              </Grid>
              {/* right icon buttons */}
              <Grid>
                <IconButton
                  onClick={exportToExcel}
                  aria-label="export"
                  className={classes.iconBtn}
                  disableRipple
                >
                  <img alt="export" src="/assets/icons/excel_export.png" />
                  <Typography variant="p" className={classes.mlHalf}>
                    Export
                  </Typography>
                </IconButton>
              </Grid>
            </Stack>
          </>
          <WatchlistGrid {...{ data, setGridApi }} getStatus={(status) => setStatus(status)} />
        </div>
        {/* <Box sx={{ border: '1px solid #ddd' }}></Box> */}
      </Container>
    </Page>
  );
}
