import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { numberformatter } from '../../../helpers';

export default function Normalization(props) {
  return (
    <>
      <h4 style={{ fontWeight: 500 }}>Streamline Stats</h4>
      {props.StreamLineStatData && (
        <Box>
          <h5 style={{ marginTop: 15 }}>Product Streamlining</h5>
          <Typography sx={{ fontSize: 12 }}>
            <span style={{ color: 'red' }}>
              {numberformatter(props.StreamLineStatData?.rawSoftwareTitles)} software titles
            </span>
            &nbsp;(across {numberformatter(props.StreamLineStatData?.devices)} devices) reduced to{' '}
            {numberformatter(props.StreamLineStatData?.normalProductLines)} unique productlines
          </Typography>
          <h5 style={{ marginTop: 15 }}>Publisher Streamlining</h5>
          <Typography sx={{ fontSize: 12 }}>
            Found{' '}
            <span style={{ color: 'red' }}>
              {numberformatter(props.StreamLineStatData?.pubisherErrors)} publisher errors
            </span>
            &nbsp;(missing, misspelled, acquisitions) reducing your publisher count from{' '}
            {numberformatter(props.StreamLineStatData?.rawPublishers)} to{' '}
            {numberformatter(props.StreamLineStatData?.normalPublishers)}
          </Typography>
        </Box>
      )}
    </>
  );
}
