import React, { useContext } from 'react';
import clsx from 'clsx';
// material
import { Stack, Typography, Breadcrumbs, Link, Box, IconButton } from '@material-ui/core';
// components
import ProductsGrid from '../../../../components/grids/productsGrid';
import useStyles from '../../../../styles';
import { ReportGridContext } from '../../../../context/ReportGridContext';

export default function PageFunc(props) {
  const classes = useStyles();
  const contextReportData = useContext(ReportGridContext);
  const breadcrumbs = [
    <Link underline="none" key="1" color="inherit" fontSize="small">
      Reports
    </Link>,
    <Typography key="3" color="text.primary" fontSize="small">
      {contextReportData.name}
    </Typography>
  ];

  return (
    <div className={classes.reportsContainer}>
      <Typography variant="p" sx={{ fontSize: 16, fontWeight: 500 }}>
        {contextReportData.name}
      </Typography>
      <Box spacing={2} className={clsx(classes.mbHalf, classes.dFlex, classes.flexBetween)}>
        <Breadcrumbs separator=">" aria-label="breadcrumb" sx={{ mt: 0.2 }}>
          {breadcrumbs}
        </Breadcrumbs>
        <Box
          sx={{
            p: 0.25,
            width: '70px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            float: 'right',
            mb: 0.5
          }}
          className={classes.exportBtn}
        >
          <button
            type="button"
            onClick={props.exportToExcel}
            className={clsx(
              classes.dFlex,
              classes.alignCenter,
              classes.mxAuto,
              classes.exportBtnStyle
            )}
          >
            <img alt="export" src="/assets/icons/excel_export.png" style={{ marginLeft: 2 }} />
            <Typography sx={{ fontSize: 13, ml: 0.5, textDecoration: 'none' }}>Export</Typography>
          </button>
        </Box>
      </Box>
      <div className={classes.reportsBorder}>
        <ProductsGrid
          setReportParams={props.setReportParams}
          getLevel={props.getLevel}
          getData={props.getData}
        />
      </div>
    </div>
  );
}
