import React, { useEffect, useState, useContext } from 'react';
import Chart from 'react-apexcharts';
import Overlay from '../../../components/Overlay';
import { numberformatter } from '../../../helpers';

export default function VerticalBar(props) {
  const [seriesData, setSeriesData] = useState([]);
  const data = {
    series: seriesData,
    options: {
      colors: ['#41729F', '#5885AF'],
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        },
        dropShadow: {
          enabled: true,
          top: 2,
          left: 1,
          blur: 2,
          color: '#acacac',
          opacity: 0.75
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: false,
          columnWidth: '50%',
          dataLabels: {
            position: 'top'
          }
        }
      },
      stroke: {
        show: true,
        curve: 'straight',
        lineCap: 'butt',
        colors: ['#fff'],
        width: 2,
        dashArray: 0
      },
      dataLabels: {
        enabled: true,
        offsetX: 0,
        offsetY: -25,
        style: {
          colors: ['#000'],
          fontWeight: 600
        },
        yaxis: {
          show: true,
          labels: {
            formatter: (value) => numberformatter(value, true)
          }
        },
        dropShadow: {
          enabled: true,
          top: 0,
          left: 0,
          blur: 2,
          color: '#acacac',
          opacity: 0.25
        }
      },
      xaxis: {
        categories: ['Publishers', 'Product Lines', 'Products']
      },
      yaxis: {
        show: false,
        labels: {
          formatter: (value) => numberformatter(value, true)
        }
      }
    }
  };
  useEffect(() => {
    if (props?.data) {
      const series = [
        {
          name: 'Raw',
          data: [props.data.rawPublishers, props.data.rawProductLines, props.data.rawProducts]
        },
        {
          name: 'Normalized',
          data: [
            props.data.normalizedPublishers,
            props.data.normalizedProductLines,
            props.data.normalizedProducts
          ]
        }
      ];

      setSeriesData(series);
    }
  }, [props]);

  return (
    <>
      {props?.data ? (
        <Chart options={data.options} series={data.series} type="bar" height={350} />
      ) : (
        <Overlay>Loading...</Overlay>
      )}
    </>
  );
}
