import React, { useEffect, useState } from 'react';
import MuiTypography from '@material-ui/core/Typography';
import Stack from '@material-ui/core/Stack';
import Paper from '@material-ui/core/Paper';
import { styled, withStyles } from '@material-ui/styles';
import { getAccountInformation } from '../../../../server';
import useStyles from '../../../../styles';

const Typography = withStyles({
  root: {
    width: 200
  }
})(MuiTypography);

const Heading = styled('p')({
  fontSize: 18,
  fontWeight: 500,
  paddingBottom: 4,
  marginTop: 16,
  marginBottom: 16,
  borderBottom: '8px solid #ccc'
});

function createDemo(currSubs, custSince, samPartner) {
  return { currSubs, custSince, samPartner };
}

function createInventory(lastInventory, daysSince, total) {
  return { lastInventory, daysSince, total };
}

const inventory = [createInventory('3/29/2018 9:33:53 AM', 1247, 2)];

export default function Tab() {
  const [rowData, setRowData] = useState(null);
  useEffect(() => {
    getAccountInformation().then((data) => setRowData(data.data));
  }, []);
  const classes = useStyles();
  return (
    <>
      <Stack sx={{ height: 'calc(100vh - 182px)', overflowY: 'scroll' }}>
        <Stack>
          <Heading>Amtra - internal</Heading>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Typography>Current Subscription:</Typography>
            <Typography>Reseller Trial Account</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Typography>Customer Since:</Typography>
            <Typography>Friday, July 22, 2016</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Typography>Your SAM Partner:</Typography>
            <Typography>Amtra</Typography>
          </Stack>
        </Stack>
        <Stack>
          <Heading>Inventory Details</Heading>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Typography>Last inventory:</Typography>
            <Typography>7/22/2016 12:30:05 PM</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Typography>Days since last inventory:</Typography>
            <Typography>1977</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Typography>Number of Inventories:</Typography>
            <Typography>2</Typography>
          </Stack>
        </Stack>
        <Stack>
          <Heading>Subscription Details</Heading>
          <Stack direction="row" alignItems="center" justifyContent="center" mb={2}>
            <span>
              *Devices have not been included with the software inventory, the information will not
              be shown within the productline and products sub section.
            </span>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
