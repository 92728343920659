function styles(theme) {
  return {
    bodyContainer: {
      borderRadius: theme.spacing(2),
      minHeight: 'calc(100vh - 40px)',
      background: '#fff',
      paddingTop: 8,
      paddingLeft: 12,
      paddingRight: 12
    },
    bodySpacing: {
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0
    },
    downloadContainer: {
      height: '100%',
      minHeight: 900,
      [theme.breakpoints.up(900)]: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      }
    },
    fullWidth: {
      maxWidth: '100%'
    },
    cardContainer: {
      borderRadius: theme.spacing(2),
      padding: '16px 12px',
      background: '#FFFFFF',
      maxHeight: 'calc(100vh - 40px)',
      overflow: 'auto'
    },
    widthFull: {
      maxWidth: '100%'
    },
    widthHalf: {
      maxWidth: '50%'
    },
    borderRadius1: {
      borderRadius: theme.spacing(1)
    },
    mHalf: {
      margin: theme.spacing(0.5)
    },
    mtHalf: {
      marginTop: theme.spacing(0.5)
    },
    mbHalf: {
      marginBottom: theme.spacing(0.5)
    },
    mlHalf: {
      marginLeft: theme.spacing(0.5)
    },
    mrHalf: {
      marginRight: theme.spacing(0.5)
    },
    m1: {
      margin: theme.spacing(1)
    },
    mt1: {
      marginTop: theme.spacing(1)
    },
    mb1: {
      marginBottom: theme.spacing(1)
    },
    ml1: {
      marginLeft: theme.spacing(1)
    },
    mr1: {
      marginRight: theme.spacing(1)
    },
    m2: {
      margin: theme.spacing(2)
    },
    mt2: {
      marginTop: theme.spacing(2)
    },
    mb2: {
      marginBottom: theme.spacing(2)
    },
    ml2: {
      marginLeft: theme.spacing(2)
    },
    mr2: {
      marginRight: theme.spacing(2)
    },
    mt10: {
      marginTop: 10
    },
    mb10: {
      marginBottom: 10
    },
    mt20: {
      marginTop: 20
    },
    mb20: {
      marginBottom: 20
    },
    ml20: {
      marginLeft: 20
    },
    mr20: {
      marginRight: 20
    },
    mx20: {
      marginLeft: 20,
      marginRight: 20
    },
    mt40: {
      marginTop: theme.spacing(5)
    },
    mb40: {
      marginBottom: theme.spacing(5)
    },
    ml40: {
      marginLeft: theme.spacing(5)
    },
    mlAuto: {
      marginLeft: 'auto'
    },
    mxAuto: {
      margin: '0 auto'
    },
    pa20: {
      padding: 20
    },
    py20: {
      paddingTop: 20,
      paddingBottom: 20
    },
    pt20: {
      paddingTop: 20
    },
    pb20: {
      paddingBottom: 20
    },
    pr20: {
      paddingRight: 20
    },
    py10: {
      paddingTop: 10,
      paddingBottom: 10
    },
    pb10: {
      paddingBottom: 10
    },
    pa0: {
      padding: 0
    },
    px0: {
      paddingLeft: 0,
      paddingRight: 0
    },
    textCenter: {
      textAlign: 'center'
    },
    fontWhite: {
      color: '#ffffff'
    },
    fontWeightNormal: {
      fontWeight: 400
    },
    fontSlim: {
      fontWeight: 300
    },
    fontItalic: {
      fontStyle: 'italic'
    },
    fontSmall: {
      fontSize: 12
    },
    pagination: {
      marginTop: 40
    },
    dBlock: {
      display: 'block'
    },
    dInlineBlock: {
      display: 'inline-block'
    },
    dInline: {
      display: 'inline'
    },
    dFlex: {
      display: 'flex'
    },
    alignStart: {
      alignItems: 'flex-start'
    },
    alignCenter: {
      alignItems: 'center'
    },
    alignEnd: {
      alignItems: 'flex-end'
    },
    flexStart: {
      justifyContent: 'flex-start'
    },
    flexCenter: {
      justifyContent: 'center'
    },
    flexEnd: {
      justifyContent: 'flex-end'
    },
    flexBetween: {
      justifyContent: 'space-between'
    },
    flexAround: {
      justifyContent: 'space-around'
    },
    flexEvenly: {
      justifyContent: 'space-evenly'
    },
    flexRow: {
      flexDirection: 'row'
    },
    flexCol: {
      flexDirection: 'column'
    },
    floatLeft: {
      float: 'left'
    },
    floatRight: {
      float: 'right'
    },
    hideOverXs: {
      [theme.breakpoints.up('xs')]: {
        display: 'none'
      }
    },
    hideOverSm: {
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    hideOverMd: {
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    hideOverLg: {
      [theme.breakpoints.up('lg')]: {
        display: 'none'
      }
    },
    hideUnderSm: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    hideUnderMd: {
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    hideUnderLg: {
      [theme.breakpoints.down('lg')]: {
        display: 'none'
      }
    },
    hideUnderXl: {
      [theme.breakpoints.down('xl')]: {
        display: 'none'
      }
    },
    simpleInput: {
      border: '1px solid #525252',
      background: 'white',
      textAlign: 'right !important',
      padding: '2px',
      maxHeight: '18px',
      '&::after': {
        content: '""',
        display: 'none'
      },
      '&::before': {
        content: '""',
        display: 'none'
      }
    },
    caretSign: {
      position: 'relative',
      display: 'inline-block',
      '&::before': {
        position: 'absolute',
        content: '▼',
        top: 0,
        right: -8,
        height: 20,
        width: 20
      }
    }
  };
}

export default styles;
