import { AccordionSummary as MuiAccordionSummary, Typography } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const AccordionSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    marginBottom: -1,
    minHeight: 31,
    height: 31,
    paddingLeft: 6,
    paddingRight: 6,
    '&:first-of-type': {
      borderTop: '1px solid rgba(255, 255, 255, 0.1)'
    },
    '& .MuiTypography-root': {
      fontWeight: 400,
      fontSize: 12,
      // color: '#9a9ea4',
      // textTransform: 'uppercase',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    '&$expanded': {
      minHeight: 31,
      height: 31
    }
  },
  content: {
    paddingTop: 5,
    '&$expanded': {
      paddingTop: 5
    }
  },
  expanded: {}
})(MuiAccordionSummary);

const activeMenu = { borderLeft: '2px solid #4395d0', background: '#ffffff1a', color: '#ffffff' };
const activeColor = { color: '#ffffff' };

export default (props) => (
  <>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel2a-content"
      id="panel2a-header"
      sx={props.pathname.includes(props.route) ? activeMenu : null}
    >
      <Typography color="inherit">
        <div style={{ height: 31, paddingTop: 5 }}>
          <span
            style={{
              display: 'inlineBlock',
              float: 'left',
              marginTop: -5
            }}
          >
            {props.pathname.includes(props.route) ? (
              <img src={`./static/icons/${props.imgActive}`} alt="" />
            ) : (
              <img src={`./static/icons/${props.img}`} alt="" />
            )}
          </span>
          <span
            style={{
              display: 'inlineBlock',
              color: props.pathname.includes(props.route) ? activeColor.color : 'inherit'
            }}
          >
            &nbsp; {props.name}
          </span>
        </div>
      </Typography>
    </AccordionSummary>
  </>
);
