import * as React from 'react';
import MuiTypography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import PieChart from '../../../../../../components/charts/PieChart';

const Typography = withStyles({
  root: {
    fontSize: 15,
    fontWeight: 500
  }
})(MuiTypography);

export default function Tab() {
  return (
    <>
      <Typography gutterBottom>Category State Fidelity for Microsoft</Typography>
      <Box sx={{ mt: 2 }}>
        <PieChart />
      </Box>
      <Box sx={{ mt: 3 }}>
        <PieChart />
      </Box>
    </>
  );
}
