import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { getNormalizedSoftware } from '../../server';
import RawAndStreamlineStatusDropdown from './RawAndStreamlineStatusDropdown';

export default function GridExample(props) {
  const [rowData, setRowData] = useState(null);
  const [gridApi, setGridApi] = useState(null);

  useEffect(() => {
    setRowData(props.NormalizedSoftware);
  }, []);

  // useEffect(() => getDeviceList(), [props]);

  // const getDeviceList = () =>
  //   new Promise((resolve, reject) => {
  //     getNormalizedSoftware(props.arpId, props.grid).then((data) => {
  //       updateData(data);
  //       resolve();
  //     });
  //   });

  // const updateData = (data) => {
  //   if (data?.data) setRowData(data.data.reportList);
  // };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  return (
    <>
      <RawAndStreamlineStatusDropdown
        {...{
          gridApi,
          softwareStates: props.softwareStates,
          getSoftwareList: props.getSoftwareList
        }}
      />
      <div
        style={{
          height: props.fullScreen ? 'calc(100vh - 180px)' : 'calc(100vh - 245px)',
          width: '100%'
        }}
        className="ag-theme-alpine"
      >
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={{
            flex: 1,
            minWidth: 140,
            sortable: true,
            filter: true,
            floatingFilter: true,
            resizable: true
          }}
          // autoGroupColumnDef={{ minWidth: 200 }}
          headerHeight="25"
          // rowHeight="25"
          animateRows="true"
          rowClassRules={{
            selectedDeviceRow: (params) => params.data.softwareDeviceId === props.arpId
          }}
          suppressContextMenu
          rowData={rowData}
          statusBar={{
            statusPanels: [
              {
                statusPanel: 'agTotalAndFilteredRowCountComponent',
                align: 'left'
              }
            ]
          }}
        >
          <AgGridColumn
            field="commonName"
            headerName="Publisher"
            filter="agTextColumnFilter"
            headerCheckboxSelection="true"
            checkboxSelection
          />
          <AgGridColumn field="productLine" headerName="Product Line" filter="agTextColumnFilter" />
          <AgGridColumn field="licenseType" headerName="License Type" filter="agSetColumnFilter" />
          <AgGridColumn field="subCategoryName" headerName="Category" filter="agTextColumnFilter" />
          <AgGridColumn
            field="statusName"
            headerName="Software State"
            filter="agSetColumnFilter"
            cellStyle={{ backgroundColor: '#ffffcc' }}
          />
        </AgGridReact>
      </div>
    </>
  );
}
