import React, { useState } from 'react';
// material
import { Stack, Typography, IconButton, Grid, Box } from '@material-ui/core';
// icons
import SettingsIcon from '@material-ui/icons/Settings';
import SoftwareState from '../../reports/components/devices/softwareState';
import useStyles from '../../../styles';

// control component used in main devices page
export default function Controls(props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  return (
    <>
      <Stack className={classes.controlsRibbon}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 0.5 }}>
          {/* left icon buttons */}
          <Grid>
            <IconButton
              aria-label="delete"
              className={classes.iconBtnCR}
              sx={{ ml: 1 }}
              disableRipple
            >
              <SettingsIcon className={classes.selectIcon} />
              <SoftwareState
                selectedRows={props.selectedRows}
                grid={props.grid}
                getStatus={props.getStatus}
                refreshDeviceData={props.refreshDeviceData}
                gridApi={props.gridApi}
              />
            </IconButton>
            {/* {props.name !== 'Clients' ? ( */}
            <IconButton
              onClick={props.exportToExcel}
              aria-label="export"
              className={classes.iconBtnCR}
              sx={{ ml: 1 }}
              disableRipple
            >
              <img alt="export" src="/assets/icons/excel_export.png" style={{ marginLeft: 2 }} />
              <Typography variant="p" className={classes.mlHalf}>
                Export
              </Typography>
            </IconButton>
            {/* ) : null} */}
          </Grid>
        </Stack>
      </Stack>
    </>
  );
}
