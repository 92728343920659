import React, { useState, useEffect, useContext } from 'react';
import { Box, Dialog as MuiDialog } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import DeviceSummary from '../modals/DeviceSummary';
import useStyles from '../../styles';
import { formatDate } from '../../helpers';

export default function GridExample(props) {
  const [gridApi, setGridApi] = useState(null);
  const [open, setOpen] = useState(false);
  const [arpId, setArpId] = useState(0);
  const [deviceId, setDeviceId] = useState(0);
  const [rowIndex, setRowIndex] = useState(null);
  const [fullScreen, setFullScreen] = useState(false);

  const classes = useStyles();

  // useEffect(() => {
  //   gridApi.deselectAll();
  // }, [arpId]);

  const Dialog = withStyles(
    fullScreen
      ? {
          container: {
            height: 'unset !important'
          },
          paper: {
            // minWidth: '97%',
            minWidth: '100%',
            height: '100vh !important',
            margin: '0px !important'
          }
        }
      : {
          paper: {
            minWidth: '97%'
          }
        }
  )(MuiDialog);

  const handleFullScreen = () => {
    setFullScreen(true);
  };

  const handleFullScreenExit = () => {
    setFullScreen(false);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    props.setReportParams(params);
  };

  const deltaIndicator = (params) => {
    const element = document.createElement('span');
    const imageElement = document.createElement('img');
    const dataSpan = document.createElement('span');
    const data = document.createTextNode(params.value);
    if (params.data.subCategoryId === 261) {
      imageElement.src = '/assets/client_icon_grid.png';
    } else {
      imageElement.src = '/assets/server_icon_grid.png';
    }
    dataSpan.appendChild(data);
    element.appendChild(imageElement);
    element.appendChild(dataSpan);
    imageElement.classList.add('gridIcon');
    dataSpan.classList.add('gridSpan');
    return element;
  };

  const cellClicked = (params) => {
    setOpen(true);
    setArpId(params.data.deviceARPId);
    setRowIndex(params.rowIndex);
    setDeviceId(params.data.deviceId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        style={{
          height: `calc(100vh - ${props.gridHeight || 200}px)`,
          width: '100%'
        }}
        className="ag-theme-alpine"
      >
        <AgGridReact
          components={{
            deltaIndicator
          }}
          defaultColDef={{
            flex: 1,
            minWidth: 80,
            sortable: true,
            filter: true,
            floatingFilter: true,
            resizable: true
          }}
          // autoGroupColumnDef={{ minWidth: 200 }}
          headerHeight="25"
          floatingFiltersHeight="31"
          rowHeight="25"
          animateRows="true"
          rowData={props.rowData}
          rowSelection="multiple"
          onGridReady={onGridReady}
          statusBar={{
            statusPanels: [
              {
                statusPanel: 'agTotalAndFilteredRowCountComponent',
                align: 'left'
              }
            ]
          }}
        >
          <AgGridColumn
            cellClass="deviceName"
            field="deviceIdentifier"
            headerName="Device Name"
            filter="agTextColumnFilter"
            headerCheckboxSelection="true"
            checkboxSelection
            minWidth={200}
            onCellClicked={cellClicked}
            cellRenderer="deltaIndicator"
          />
          <AgGridColumn
            field="statusId"
            headerName="Production State"
            filter="agSetColumnFilter"
            // valueGetter={(params) => {
            //   if (params.data) {
            //     return props.dStatuses[params.data?.statusId];
            //   }
            // }}
            // cellStyle={(params) => {
            //   return { backgroundColor: '#ffffcc' };
            // }}
            cellStyle={{ backgroundColor: '#ffffcc' }}
          />
          <AgGridColumn
            field="subCategoryId"
            headerName="OS Type"
            filter="agSetColumnFilter"
            valueGetter={(params) => {
              if (params.data) {
                return props.dSubCategories[params.data?.subCategoryId];
              }
            }}
          />
          <AgGridColumn field="manufacturer" filter="agSetColumnFilter" minWidth={70} />
          <AgGridColumn field="model" filter="agTextColumnFilter" />
          <AgGridColumn
            field="os"
            headerName="Operating System"
            filter="agSetColumnFilter"
            hide="true"
          />
          <AgGridColumn field="isVirtual" headerName="Machine Type" filter="agTextColumnFilter" />
          <AgGridColumn field="serialNumber" headerName="Serial" filter="agTextColumnFilter" />
          <AgGridColumn
            field="cpuCount"
            headerName="Proc"
            filter="agNumberColumnFilter"
            enableValue="true"
            // hide="true"
          />
          <AgGridColumn
            field="coresPerCPU"
            headerName="Cores"
            filter="agNumberColumnFilter"
            enableValue="true"
            minWidth={70}
          />
          <AgGridColumn
            field="cpuReleaseDate"
            headerName="Birth date"
            filter="agDateColumnFilter"
            // valueGetter={(params) => {
            //   return formatDate(params.data?.cpuReleaseDate);
            // }}
            valueGetter={(params) =>
              params.data?.cpuReleaseDate !== '' ? formatDate(params.data?.cpuReleaseDate) : ''
            }
          />
          <AgGridColumn
            field="firstSeen"
            headerName="First seen"
            filter="agDateColumnFilter"
            // valueGetter={(params) => {
            //   return formatDate(params.data?.cpuReleaseDate);
            // }}
            valueGetter={(params) =>
              params.data?.firstSeen !== '' ? formatDate(params.data?.firstSeen) : ''
            }
          />
          <AgGridColumn field="deviceUserName" headerName="Username" filter="agTextColumnFilter" />
        </AgGridReact>
      </div>
      {props.rowData && open && (
        <Box>
          <Dialog open={open} onClose={handleClose} className={classes.root}>
            <DeviceSummary
              {...{
                handleClose,
                open,
                arpId,
                setArpId: (arpId) => setArpId(arpId),
                rowIndex,
                setRowIndex,
                devicesData: props.rowData,
                refreshDeviceData: props.refreshDeviceData,
                dStatuses: props.dStatuses,
                deviceId,
                handleFullScreen,
                handleFullScreenExit,
                fullScreen
              }}
            />
          </Dialog>
        </Box>
      )}
    </>
  );
}
