import React, { useEffect, useState, useContext } from 'react';
import { Stack, Container, Typography, Button } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import clsx from 'clsx';
// components
import MuiAlert from '@material-ui/core/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Page from '../../components/Page';
import UploadTable from './components/FilesTable';
import BtnLight from '../../components/buttons/ButtonLight';
import { UploadProviderLogo, GetProviderLogoPath } from '../../server';
import { LayoutContext } from '../../context/LayoutContext';
import useStyles from '../../styles';

// ----------------------------------------------------------------------

const Alert = React.forwardRef((contextData, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...contextData} />
));

export default function Upload() {
  const [filesList, setFilesList] = useState(null);
  const layoutContextData = useContext(LayoutContext);
  const [selectedFilesList, setSelectedFilesList] = useState(null);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [show, setShow] = useState(true);
  const [imgData, setImgData] = useState(null);
  const [count, setCount] = useState(0);

  useEffect(() => {
    GetProviderLogoPath()
      .then((res) => {
        if (res?.data?.fileData) {
          setFilesList(res?.data?.fileData);
          setImgData(res?.data?.fileData?.filePath);
        }
        if (res.data.roleRank <= 20) {
          setShow(false);
        }
      })
      .catch(() => {});
  }, []);

  const handleFileSelected = (e) => {
    const files = Array.from(e.target.files);
    const reader = new FileReader();
    // reader.onload = () => {
    //   setImgData(reader.result);
    //   setSelectedFilesList([{ files, imgData }]);
    // };
    reader.onloadend = () => {
      setImgData(reader?.result);
    };
    reader.readAsDataURL(e?.target?.files[0]);
    setSelectedFilesList(files);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const uploadFilesSelected = (e) => {
    if (selectedFilesList !== null) {
      UploadProviderLogo(selectedFilesList)
        .then((res) => {
          if (res && res.status) {
            setSelectedFilesList([]);
            setImgData(null);
            GetProviderLogoPath()
              .then((res) => {
                if (res?.data?.fileData) {
                  setFilesList(res?.data?.fileData);
                  setImgData(res?.data?.fileData?.filePath);
                  setSelectedFilesList(null);
                  setOpen(true);
                  layoutContextData(() => {
                    setCount(count + 1);
                  });
                }
              })
              .catch(() => {});
          }
        })
        .catch(() => {});
    }
  };

  const removeFile = (fileName) => {
    const files = selectedFilesList.filter((x) => x.name !== fileName);
    setSelectedFilesList(files);
    setImgData(null);
  };

  return (
    <>
      <Page
        title="Upload | AssetLabs"
        className={classes.bodyContainer}
        style={{ position: 'relative' }}
      >
        <Container className={classes.fullWidth} sx={{ pt: 1, px: 1.5 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom>
              Select a new logo
            </Typography>
          </Stack>

          <Typography variant="h6" component="p" sx={{ fontWeight: 400 }}>
            Make sure your logo is 220 pixels wide and 40 pixels in height.
          </Typography>
          {imgData !== null ? (
            <div
              style={{
                width: '30%',
                height: '200px',
                margin: '20px auto',
                backgroundImage: `url(${imgData})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center'
              }}
            />
          ) : (
            <div
              style={{
                width: '30%',
                height: '200px',
                margin: '20px auto',
                backgroundImage: 'url(/static/download.png)',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center'
              }}
            />
          )}

          {show ? (
            <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
              <BtnLight
                disabled={selectedFilesList === null}
                onClick={uploadFilesSelected}
                sx={{ mt: 1 }}
              >
                upload
              </BtnLight>
              <label htmlFor="contained-button-file">
                <input
                  style={{ display: 'none' }}
                  onChange={handleFileSelected}
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                />
                <Button
                  variant="contained"
                  component="span"
                  className={classes.btnLight}
                  sx={{ mt: 1 }}
                >
                  Choose File
                </Button>
              </label>
            </Stack>
          ) : null}
          {/* <UploadTable {...{ filesList, selectedFilesList, removeFile, imgData }} /> */}
        </Container>
      </Page>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={1000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="success"
          sx={{ width: '100%', background: '#2196f3' }}
        >
          Logo Updated
        </Alert>
      </Snackbar>
    </>
  );
}
