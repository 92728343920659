function styles(theme) {
  return {
    btnDark: {
      background: '#364150',
      color: '#ffffff',
      textAlign: 'center',
      textTransform: 'uppercase',
      borderRadius: 4,
      marginLeft: 5,
      fontSize: 12,
      fontWeight: 400,
      paddingLeft: 10,
      paddingRight: 10,
      '&:hover': {
        background: '#333D47'
      }
    },
    btnLight: {
      background: '#2196f3',
      color: '#ffffff',
      textAlign: 'center',
      minWidth: 100,
      fontWeight: 500,
      textTransform: 'capitalize',
      fontSize: 12,
      borderRadius: 4,
      paddingLeft: 10,
      paddingRight: 10,
      marginLeft: 5,
      boxShadow: 'none',
      '&:hover': {
        background: '#0C87E8'
      },
      '&:disabled': {
        color: '#ffffff',
        backgroundColor: '#cecece'
      }
    },
    btnDanger: {
      background: '#D42822',
      color: '#ffffff',
      textAlign: 'center',
      minWidth: 100,
      fontWeight: 500,
      textTransform: 'capitalize',
      fontSize: 12,
      borderRadius: 4,
      paddingLeft: 10,
      paddingRight: 10,
      marginLeft: 5,
      boxShadow: 'none',
      '&:hover': {
        background: '#CA2620'
      },
      '&:disabled': {
        color: '#ffffff',
        backgroundColor: '#cecece'
      }
    },
    tinyButton: {
      background: '#EEEFF0',
      color: '#364150',
      minWidth: 100,
      fontWeight: 400,
      textTransform: 'capitalize',
      fontSize: 12,
      borderRadius: 4,
      '&.-root': {
        padding: '2px'
      },
      '&:hover': {
        background: '#E5E5E5'
      }
    },
    iconBtn: {
      borderRadius: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.6),
      paddingRight: theme.spacing(0.6),
      paddingTop: theme.spacing(0.4),
      paddingBottom: theme.spacing(0.4)
    },
    iconBtnCR: {
      borderRadius: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.6),
      paddingRight: theme.spacing(0.6),
      paddingTop: theme.spacing(0.4),
      paddingBottom: theme.spacing(0.4),
      background: '#FFF',
      border: '1px solid #818181'
    },
    iconBtnIcon: {
      height: 20,
      width: 20
    },
    selectIcon: {
      height: 20,
      width: 20,
      color: '#637381'
    },
    selectDrop: {
      fontSize: 12,
      height: 15,
      color: '#637381',
      '&:before': {
        borderBottom: 'none'
      },
      '&:after': {
        borderBottom: 'none'
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: 'none'
      }
    },
    dropdownCaret: {
      position: 'relative',
      display: 'inline-block',
      '&:before': {
        position: 'absolute',
        content: '▼',
        top: 0,
        right: -8,
        height: 20,
        width: 20
      }
    },
    selectBordered: {
      border: '1px solid #5c5c5c',
      borderRadius: 0,
      padding: '2px 8px',
      height: '24px !important'
    },
    selectDropDialog: {
      fontSize: 13,
      height: 18,
      color: '#000',
      paddingTop: '2px',
      '&:before': {
        borderBottom: 'none'
      },
      '&:after': {
        borderBottom: 'none'
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: 'none'
      }
    },
    selectOptions: {
      fontSize: 13,
      height: 13,
      color: '#000',
      padding: 1
    },
    controlsContainer: {
      marginTop: theme.spacing(0.8),
      marginBottom: theme.spacing(0.8),
      minWidth: 380
    },
    controlsContainerCR: {
      marginTop: theme.spacing(0.8)
    },
    controlsRibbon: {
      minWidth: 380,
      background: '#F3F3F2',
      borderTop: '1px solid #818181',
      borderLeft: '1px solid #818181',
      borderRight: '1px solid #818181'
      // py: theme.spacing(1),
      // px: theme.spacing(0.5)
    },
    submenuIndent: {
      padding: theme.spacing(0.8)
    },
    menuActive: {
      '&-content': {
        borderLeft: '3px solid #4395d0',
        background: '#ffffff1a'
      }
    },
    navTabActive: {
      borderRadius: '6px 6px 0 0',
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1.25),
      color: '#A0A0A0',
      fontWeight: 500,
      fontSize: 13,
      minHeight: 30,
      background: '#FFFFFF',
      textTransform: 'uppercase',
      lineHeight: 1,
      '&:hover': {
        background: '#FFFFFF',
        color: '#A0A0A0'
      }
    },
    navTabInactive: {
      borderRadius: '6px 6px 0 0',
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1.25),
      color: '#B5B5B5',
      fontWeight: 500,
      fontSize: 13,
      minHeight: 30,
      textTransform: 'uppercase',
      lineHeight: 1,
      '&:hover': {
        background: '#FFFFFF',
        color: '#B5B5B5'
      }
    },
    exportBtn: {
      '&:hover': {
        background: 'rgba(145, 158, 171, 0.08)',
        transition: 'all .2s ease-in-out'
      }
    },
    exportBtnStyle: {
      border: 'none',
      background: 'transparent',
      cursor: 'pointer'
    },
    floatingFilterInput: {
      border: '1px solid #b7b7b7',
      borderRadius: '2px',
      minWidth: '80px',
      minHeight: '24px',
      width: '100%'
    }
  };
}

export default styles;
