import * as React from 'react';
import MuiTypography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import MuiTableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/styles';
import useStyles from '../../../../../styles';

const Typography = withStyles({
  root: {
    fontSize: 15,
    fontWeight: 500
  }
})(MuiTypography);

const TableCell = withStyles({
  root: {
    paddingLeft: 12,
    paddingRight: 12,
    fontSize: 12
  }
})(MuiTableCell);

const TableHead = withStyles({
  root: {
    background: '#ddd'
  }
})(MuiTableHead);

function createDemo(currSubs, custSince, samPartner) {
  return { currSubs, custSince, samPartner };
}

function createInventory(lastInventory, daysSince, total) {
  return { lastInventory, daysSince, total };
}

// function createSubscription(name, calories, fat) {
//   return { name, calories, fat };
// }

const demo = [createDemo('Demo', 'Thursday, September 17, 2009', 'AssetLabs USA')];

const inventory = [createInventory('3/29/2018 9:33:53 AM', 1247, 2)];

// const subscription = [createSubscription('Frozen yoghurt', 159, 6.0)];

export default function Tab() {
  const classes = useStyles();
  return (
    <>
      <Typography gutterBottom>Demo</Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Current Subscription</TableCell>
              <TableCell align="right">Customer Since</TableCell>
              <TableCell align="right">Your SAM Partner</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {demo.map((row) => (
              <TableRow
                key={row.currSubs}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.currSubs}
                </TableCell>
                <TableCell align="right">{row.custSince}</TableCell>
                <TableCell align="right">{row.samPartner}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography gutterBottom className={classes.mt40}>
        Inventory Details
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Last inventory</TableCell>
              <TableCell align="right">Days since last inventory</TableCell>
              <TableCell align="right">Number of Inventories</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inventory.map((row) => (
              <TableRow
                key={row.lastInventory}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.lastInventory}
                </TableCell>
                <TableCell align="right">{row.daysSince}</TableCell>
                <TableCell align="right">{row.total}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography gutterBottom className={classes.mt40}>
        Subscription Details
      </Typography>
    </>
  );
}
