// material
import { Stack, Container, Card, Typography } from '@material-ui/core';
// components
import Page from '../../components/Page';
import useStyles from '../../styles';
import Reports from './components/index';
import { publishers, softwares, REPORTS } from '../../constants/reports';
import { ReportGridContext } from '../../context/ReportGridContext';

export default function SoftwareByCategory() {
  const classes = useStyles();
  const report = REPORTS.SoftwareByCategory;
  const name = 'Software by Category';
  return (
    <Page title="Reports | AssetLabs" className={classes.reportsBody}>
      <Container className={classes.fullWidth}>
        <ReportGridContext.Provider value={{ report, name }}>
          <Reports />
        </ReportGridContext.Provider>
      </Container>
    </Page>
  );
}
