// ----------------------------------------------------------------------

export default function Pagination(theme) {
  return {
    MuiPagination: {
      styleOverrides: {
        ul: {
          '& li button.Mui-selected': {
            background: '#2196f3',
            color: '#fff'
          },
          '& li button.MuiButtonBase-root:hover': {
            background: '#364150',
            color: '#fff'
          }
        }
      }
    }
  };
}
