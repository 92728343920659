import { styled } from '@material-ui/styles';
import { Link } from '@material-ui/core';
import { saveAs } from 'file-saver';
import { formatDate, bytesToSize } from '../../../helpers';
import { DownloadFile } from '../../../server';
import BtnDanger from '../../../components/buttons/ButtonDanger';

const Table = styled('table')({
  fontSize: 16,
  borderCollapse: 'collapse',
  width: '100%',
  marginBottom: 32,
  border: '1px solid #999'
});

const HeadRow = styled('tr')({
  background: '#364150',
  color: '#FFFFFF'
});

const HeadCell = styled('th')({
  padding: '1.5rem',
  paddingTop: '0.25rem',
  paddingBottom: '0.25rem',
  textAlign: 'left',
  fontWeight: 400,
  width: '25%',
  '&:first-child': {
    width: '50%'
  }
});

const Row = styled('tr')({
  borderBottom: '1px solid #999'
});

const Cell = styled('td')({
  padding: '1.5rem',
  paddingTop: '0.75rem',
  paddingBottom: '0.75rem',
  border: 'none',
  width: '25%',
  '&:first-child': {
    width: '50%'
  }
});

export default function UploadFiles(props) {
  const downloadFile = ({ filePath, fileName }) => {
    DownloadFile(filePath)
      .then(({ blob, fName }) => {
        saveAs(blob, fName || fileName);
      })
      .catch((e) => {});
  };
  return (
    <>
      {props?.selectedFilesList?.length > 0 && (
        <Table border="0">
          <thead>
            <HeadRow>
              <HeadCell>File name</HeadCell>
              <HeadCell>File size</HeadCell>
              <HeadCell style={{ textAlign: 'right' }}>Action</HeadCell>
            </HeadRow>
          </thead>
          <tbody>
            {props.selectedFilesList.map((item) => {
              const fname = item.name;
              return (
                <Row>
                  <Cell>{fname}</Cell>
                  <Cell>{bytesToSize(item.size)}</Cell>
                  <Cell style={{ textAlign: 'right' }}>
                    <BtnDanger onClick={() => props.removeFile(item.name)}>remove</BtnDanger>
                  </Cell>{' '}
                </Row>
              );
            })}
          </tbody>
        </Table>
      )}
      <Table border="0">
        <thead>
          <HeadRow>
            <HeadCell>File name</HeadCell>
            <HeadCell>File size</HeadCell>
            <HeadCell style={{ textAlign: 'right' }}>Uploaded</HeadCell>
          </HeadRow>
        </thead>
        <tbody>
          {props?.filesList?.length > 0 ? (
            <>
              {props.filesList.map((item) => {
                const name = '';
                return (
                  <Row>
                    <Cell>
                      <Link
                        onClick={() => downloadFile(item)}
                        underline="always"
                        color="inherit"
                        sx={{ cursor: 'pointer' }}
                      >
                        {item.fileName}
                      </Link>
                    </Cell>
                    <Cell>{item.fileSize}</Cell>
                    <Cell style={{ textAlign: 'right' }}>{formatDate(item.uploadedDateTime)}</Cell>
                  </Row>
                );
              })}
            </>
          ) : (
            <Row>
              <Cell colSpan="3">No Data</Cell>
            </Row>
          )}
        </tbody>
      </Table>
    </>
  );
}
