import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Box, Typography, TextField, Checkbox, Link } from '@material-ui/core';
import useStyles from '../../styles/index';

export default function EmailSent() {
  const classes = useStyles();
  const label = { inputProps: { 'aria-label': 'Remember Me' } };
  return (
    <div className={classes.authBody}>
      <Box className={classes.loginSection}>
        <Box
          className={classes.loginCard}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <div>
            <div className={clsx(classes.dFlex, classes.flexCenter)}>
              <img src="./static/assetlabs.png" alt="" height="50px" className={classes.mb10} />
            </div>
            <div
              className={clsx(classes.mt20, classes.dFlex, classes.alignCenter, classes.flexCenter)}
            >
              <img src="./static/verified.png" alt="" height="50" />
              <Typography
                variant="h5"
                className={clsx(classes.fontWeightNormal, classes.mlHalf)}
                color="text.secondary"
              >
                Activation code has been sent.
              </Typography>
            </div>
            <div className={clsx(classes.mt20, classes.textCenter)}>
              <Typography variant="body2" color="text.secondary" sx={{ maxWidth: 500 }}>
                Please check your email to find your account activation code. If you can't find the
                that email, give it a few more minutes or check your spam filter.
              </Typography>
            </div>
          </div>
        </Box>
        <Box className={clsx(classes.mt20, classes.textCenter, classes.authFooter)}>
          <Typography color="secondary" variant="body2" className={classes.authFooterText}>
            &copy; 2021 AssetLabs Inc. All rights reserved.&nbsp;
            <Link href="#" className={clsx(classes.footerLinks, classes.fontWeightNormal)}>
              Contact Us
            </Link>
          </Typography>
        </Box>
      </Box>
    </div>
  );
}
