import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
// auth pages
import Login from './pages/auth/Login';
import ResetPassword from './pages/auth/ResetPassword';
import ResendEmail from './pages/auth/ResendEmail';
import EmailSent from './pages/auth/EmailSent';
import EmailVerified from './pages/auth/EmailVerified';
// import ForgotPassword from './pages/auth/ForgotPassword';
// admin pages
import ProviderManagement from './pages/admin/provider-mgmt';
import SubscriptionManagement from './pages/admin/subscription-mgmt';
import ManageAccountSubs from './pages/admin/ManageAccountSubs';
import SubsServiceManager from './pages/admin/Subs&ServiceManager';
import ManageAccountUsers from './pages/admin/OldManageAccount';
import ManageAccount from './pages/admin/manage-account';
import CreateAccount from './pages/admin/CreateAccount';
// report pages
import LineOfBusiness from './pages/reports/LineOfBusiness';
import EndUserDistractions from './pages/reports/EndUserDistractions';
import AllInstalls from './pages/reports/AllInstalls';
import AdobeAuditable from './pages/reports/AdobeAuditable';
import AllAuditable from './pages/reports/AllAuditable';
import AllMicrosoft from './pages/reports/AllMicrosoft';
import BusinessAppAuditable from './pages/reports/BusinessAppAuditable';
import BusinessAppNonAuditable from './pages/reports/BusinessAppNonAuditable';
import Games from './pages/reports/Games';
import IBMAuditable from './pages/reports/IBMAuditable';
import PeerToPeer from './pages/reports/PeerToPeer';
import Malware from './pages/reports/Malware';
import MicrosoftLicensable from './pages/reports/MicrosoftLicensable';
import OperatingSystems from './pages/reports/OperatingSystems';
// softwares
import SoftwareByCategory from './pages/reports/SoftwareByCategory';
import SoftwareByLicenseType from './pages/reports/SoftwareByLicenseType';
// publishers
import PublishersAtoZ from './pages/publishers/PublishersAtoZ';
import PublishersByInstallCount from './pages/publishers/PublishersByInstallCount';
import PublishersByFinancialFootprint from './pages/publishers/PublishersByFinancialFootprint';
// other pages
import Dashboard2 from './pages/dashboard';
import Empty from './pages/Empty';
import WatchList from './pages/WatchList';
import Blacklist from './pages/blackList';
import Upload from './pages/upload';
import Download from './pages/Download';
import Slim360 from './pages/Slim360';
import Modals from './pages/Modals';
import NotFound from './pages/Page404';
import ChangeLogo from './pages/settings/LogoChange';
import PrivateRoute from './auth/PrivateRoute';
// cross reference
import CrossReference from './pages/reports/CrossReference';
// devices
import AllDevices from './pages/devices/AllDevices';
import Clients from './pages/devices/Clients';
import Servers from './pages/devices/Servers';
// admin
import AccountMgmt from './pages/admin/account-mgmt';
// import { Subscriptions } from '@material-ui/icons';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    { path: '/login', element: <Login /> },
    { path: 'reset-password', element: <ResetPassword /> },
    { path: 'resend-email', element: <ResendEmail /> },
    { path: 'email-sent', element: <EmailSent /> },
    { path: 'email-verified', element: <EmailVerified /> },
    { path: '404', element: <NotFound /> },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        PrivateRoute({
          path: '/',
          element: <Navigate to="/report/microsoft-licensable" replace />
        }),
        {
          path: '/publishers',
          children: [
            PrivateRoute({ path: '/a-to-z', element: <PublishersAtoZ /> }),
            PrivateRoute({ path: '/by-install-count', element: <PublishersByInstallCount /> }),
            PrivateRoute({
              path: '/by-financial-footprint',
              element: <PublishersByFinancialFootprint />
            })
          ]
        },
        {
          path: '/devices',
          children: [
            PrivateRoute({ path: '/all', element: <AllDevices /> }),
            PrivateRoute({ path: '/clients', element: <Clients /> }),
            PrivateRoute({ path: '/servers', element: <Servers /> })
          ]
        },
        {
          path: '/admin',
          children: [
            PrivateRoute({ path: '/member-and-account', element: <AccountMgmt /> }),
            PrivateRoute({ path: '/account-subscriptions', element: <AccountMgmt /> }),
            PrivateRoute({ path: '/account-assignments', element: <AccountMgmt /> }),
            PrivateRoute({ path: '/unassigned-members', element: <AccountMgmt /> }),
            PrivateRoute({ path: '/provider-management', element: <ProviderManagement /> }),
            PrivateRoute({ path: '/subscription-management', element: <SubscriptionManagement /> }),
            PrivateRoute({ path: '/manage-account', element: <ManageAccount /> }),
            PrivateRoute({ path: '/create-account', element: <CreateAccount /> })
          ]
        },
        {
          path: '/report',
          children: [
            PrivateRoute({ path: '/microsoft-licensable', element: <MicrosoftLicensable /> }),
            PrivateRoute({ path: '/manage-subcription', element: <ManageAccountSubs /> }),
            PrivateRoute({ path: '/subs-and-service-manager', element: <SubsServiceManager /> }),
            PrivateRoute({ path: '/manage-account', element: <ManageAccountUsers /> }),
            PrivateRoute({ path: '/line-of-business', element: <LineOfBusiness /> }),
            PrivateRoute({ path: '/end-user-distractions', element: <EndUserDistractions /> }),
            PrivateRoute({ path: '/all-installs', element: <AllInstalls /> }),
            PrivateRoute({ path: '/adobe-auditable', element: <AdobeAuditable /> }),
            PrivateRoute({ path: '/all-auditable', element: <AllAuditable /> }),
            PrivateRoute({ path: '/all-microsoft', element: <AllMicrosoft /> }),
            PrivateRoute({ path: '/business-app-auditable', element: <BusinessAppAuditable /> }),
            PrivateRoute({
              path: '/business-app-nonAuditable',
              element: <BusinessAppNonAuditable />
            }),
            PrivateRoute({ path: '/games', element: <Games /> }),
            PrivateRoute({ path: '/ibm-auditable', element: <IBMAuditable /> }),
            PrivateRoute({ path: '/peer-top-peer', element: <PeerToPeer /> }),
            PrivateRoute({ path: '/malware', element: <Malware /> }),
            PrivateRoute({ path: '/operating-systems', element: <OperatingSystems /> }),
            PrivateRoute({ path: '/by-category', element: <SoftwareByCategory /> }),
            PrivateRoute({ path: '/by-license-type', element: <SoftwareByLicenseType /> }),
            PrivateRoute({ path: '/cross-reference', element: <CrossReference /> })
          ]
        },
        PrivateRoute({ path: '/watchlist', element: <WatchList /> }),
        PrivateRoute({ path: '/blacklist', element: <Blacklist /> }),
        PrivateRoute({ path: 'dashboard2', element: <Dashboard2 /> }),
        PrivateRoute({ path: '/m365', element: <Slim360 /> }),
        PrivateRoute({ path: '/settings/select-logo', element: <ChangeLogo /> }),
        PrivateRoute({ path: '/modals', element: <Modals /> }),
        PrivateRoute({ path: 'upload', element: <Upload /> }),
        PrivateRoute({ path: 'download', element: <Download /> }),
        PrivateRoute({ path: 'empty', element: <Empty /> })
      ]
    },
    {
      path: '*',
      element: <Navigate to="/" />
    }
  ]);
}
