import * as React from 'react';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
// import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from '../../styles';
import BtnLight from '../buttons/ButtonLight';

// const DialogActions = withStyles({
//   root: {
//     backgroundColor: '#364150',
//     color: '#F4F6F8'
//   }
// })(MuiDialogActions);

const DialogContent = withStyles({
  root: {
    backgroundColor: '#364150',
    color: '#F4F6F8'
  }
})(MuiDialogContent);

const DialogContentText = withStyles({
  root: {
    backgroundColor: '#364150',
    color: '#F4F6F8'
  }
})(MuiDialogContentText);

const DialogTitle = withStyles({
  root: {
    backgroundColor: '#364150',
    color: '#F4F6F8'
  }
})(MuiDialogTitle);

export default function DialogBox() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <BtnLight onClick={handleClickOpen}>Member info</BtnLight>
      <Dialog open={open} onClose={handleClose} className={classes.root}>
        <DialogTitle>
          Member Information
          <IconButton aria-label="close" onClick={handleClose} className={classes.iconStyle}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className={clsx(classes.mb10, classes.fontSlim)}>
              Username: <span id="username">sample123</span>
            </div>
            <div className={clsx(classes.mb10, classes.fontSlim)}>
              Email: <span id="email">sample123@mail.com</span>
            </div>
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          <BtnLight variant="contained" size="small" onClick={handleClose}>
            Ok
          </BtnLight>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
