function styles(theme) {
  return {
    split: {
      display: 'flex',
      flexDirection: 'row'
    },
    gutter: {
      backgroundColor: '#eee',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50%',
      '&.gutter-horizontal': {
        backgroundImage:
          "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==')",
        cursor: 'col-resize'
      }
    }
  };
}

export default styles;
