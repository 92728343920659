import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Box, Typography, TextField, Checkbox, Link } from '@material-ui/core';
import useStyles from '../../styles/index';
import BtnLight from '../../components/buttons/ButtonLight';

export default function EmailAuth() {
  const classes = useStyles();
  const label = { inputProps: { 'aria-label': 'Remember Me' } };
  return (
    <div className={classes.authBody}>
      <Box className={classes.loginSection}>
        <Box
          className={classes.loginCard}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <div>
            <div className={clsx(classes.dFlex, classes.flexCenter)}>
              <img src="./static/assetlabs.png" alt="" height="50px" className={classes.mb10} />
            </div>
            <div className={clsx(classes.mt20, classes.dFlex, classes.alignCenter)}>
              <img src="./static/verified.png" alt="" height="50" />
              <Typography
                variant="h5"
                className={clsx(classes.fontWeightNormal, classes.mlHalf)}
                color="text.secondary"
              >
                Your email has been successfully verified.
              </Typography>
            </div>
            <div
              className={clsx(classes.mt20, classes.dFlex, classes.flexCenter, classes.alignCenter)}
            >
              <Typography variant="body2" color="text.secondary" sx={{ mr: 2 }}>
                Login to continue
              </Typography>
              <BtnLight component={RouterLink} to="/login">
                Login
              </BtnLight>
            </div>
            {/* <div className={clsx(classes.authCardFooter, classes.resendCardFooter)}>
              <Typography variant="body2">
                Don't have an AssetLabs account?&nbsp;
                <Link href="#" className={clsx(classes.footerLinks, classes.fontWeightNormal)}>
                  See a Demo
                </Link>
              </Typography>
            </div> */}
          </div>
          {/* <img src="./static/shayan-hefzi.jpg" alt="" height="520" className={classes.authImage} /> */}
        </Box>
        <Box className={clsx(classes.mt20, classes.textCenter, classes.authFooter)}>
          <Typography color="secondary" variant="body2" className={classes.authFooterText}>
            &copy; 2021 AssetLabs Inc. All rights reserved.&nbsp;
            <Link href="#" className={clsx(classes.footerLinks, classes.fontWeightNormal)}>
              Contact Us
            </Link>
          </Typography>
        </Box>
      </Box>
    </div>
  );
}
