import React, { useState, useEffect } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { formatDate } from '../../helpers';

const deltaIndicator = (params) => {
  const element = document.createElement('span');
  const imageElement = document.createElement('img');
  const dataSpan = document.createElement('span');
  const data = document.createTextNode(params.value);
  if (params.data.osType === 'Desktop OS') {
    imageElement.src = '/assets/client_icon_grid.png';
  }
  if (params.data.osType === 'Server OS') {
    imageElement.src = '/assets/server_icon_grid.png';
  }
  dataSpan.appendChild(data);
  element.appendChild(imageElement);
  element.appendChild(dataSpan);
  imageElement.classList.add('gridIcon');
  dataSpan.classList.add('gridSpan');
  return element;
};

export default function GridExample(props) {
  const [gridApi, setGridApi] = useState(null);

  const onSelectionChanged = () => {
    const selectedRows = gridApi.getSelectedRows();
    props.getSelectedRows(selectedRows);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  return (
    <>
      <div
        style={{
          height: 'calc(100vh - 301px)',
          width: '100%'
        }}
        className="ag-theme-alpine"
      >
        <AgGridReact
          components={{
            deltaIndicator
          }}
          defaultColDef={{
            flex: 1,
            minWidth: 80,
            sortable: true,
            filter: true,
            floatingFilter: true,
            resizable: true
          }}
          // autoGroupColumnDef={{ minWidth: 200 }}
          headerHeight="25"
          floatingFiltersHeight="31"
          rowHeight="25"
          animateRows
          rowSelection="multiple"
          onGridReady={onGridReady}
          rowData={props.rowData}
          onSelectionChanged={onSelectionChanged}
          statusBar={{
            statusPanels: [
              {
                statusPanel: 'agTotalAndFilteredRowCountComponent',
                align: 'left'
              }
            ]
          }}
        >
          <AgGridColumn
            cellClass="deviceName"
            field="deviceName"
            headerName="Device Name"
            filter="agTextColumnFilter"
            // headerCheckboxSelection
            // checkboxSelection
            minWidth={150}
            cellRenderer="deltaIndicator"
          />
          <AgGridColumn
            field="softwareA"
            headerName="Software A"
            filter="agSetColumnFilter"
            headerCheckboxSelection
            checkboxSelection={(params) => {
              if (params.data.softwareA === null) {
                return false;
              }
              return true;
            }}
          />
          <AgGridColumn
            field="softwareB"
            headerName="Software B"
            filter="agSetColumnFilter"
            headerCheckboxSelection
            checkboxSelection={(params) => {
              if (params.data.softwareB === null) {
                return false;
              }
              return true;
            }}
          />
          {/* <AgGridColumn field="productLine" filter="agSetColumnFilter" /> */}
          {/* <AgGridColumn
            field="statusId"
            headerName="Production State"
            filter="agSetColumnFilter"
            valueGetter={(params) => {
              if (params.data && props.dStatuses) {
                return props.dStatuses[params.data?.statusId];
              }
            }}
          /> */}
          <AgGridColumn
            field="osType"
            headerName="OS Type"
            filter="agSetColumnFilter"
            // valueGetter={(params) => {
            //   if (params.data && props.dSubCategories) {
            //     return props.dSubCategories[params.data?.subCategoryId];
            //   }
            // }}
          />
          <AgGridColumn
            field="operatingSystem"
            headerName="Operating System"
            filter="agSetColumnFilter"
          />
          <AgGridColumn field="manufacturer" filter="agSetColumnFilter" minWidth={70} />
          <AgGridColumn field="model" filter="agTextColumnFilter" />
          <AgGridColumn
            field="isVirtualG"
            headerName="Machine Type"
            filter="agTextColumnFilter"
            valueGetter={() => 'Physical'}
          />
          <AgGridColumn field="serialNumber" headerName="Serial" filter="agTextColumnFilter" />
          {/* <AgGridColumn
            field="cpuCount"
            headerName="CPU"
            filter="agNumberColumnFilter"
            enableValue
            hide
          />
          <AgGridColumn
            field="coresPerCPU"
            headerName="Cores"
            filter="agNumberColumnFilter"
            enableValue
            minWidth={70}
          />
          <AgGridColumn
            field="cpuReleaseDate"
            headerName="First Seen"
            filter="agDateColumnFilter"
            valueGetter={(params) => params.data?.cpuReleaseDate}
          />
          <AgGridColumn field="deviceUserName" headerName="Username" filter="agTextColumnFilter" /> */}
        </AgGridReact>
      </div>
    </>
  );
}
