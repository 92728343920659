import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { Box, Stack, AppBar, Toolbar, IconButton } from '@material-ui/core';
import MuiTypography from '@material-ui/core/Typography';
import MuiButton from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import { styled } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
// components
import AccountPopover from './AccountPopover';
import { GetClientDevicesCount, GetServerDevicesCount, GetProviderLogoPath } from '../../server';
import { formatDate } from '../../helpers';
import useStyles from '../../styles';

// ----------------------------------------------------------------------

const Button = withStyles({
  root: {
    // display: 'none'
  }
})(MuiButton);

const Typography = withStyles({
  root: {
    color: '#B5B5B5',
    marginLeft: 10,
    marginRight: 10,
    height: '100%'
  }
})(MuiTypography);

const APPBAR_DESKTOP = 40;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: theme.palette.primary.main,
  width: '100%'
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    minHeight: APPBAR_DESKTOP,
    height: APPBAR_DESKTOP,
    paddingLeft: 0
  }
}));
// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar, status, isOpenSidebar }) {
  const { pathname } = useLocation();
  const [clientDevicesCount, setClientDevicesCount] = useState(0);
  const [serverDevicesCount, setServerDevicesCount] = useState(0);
  const [inventorySnapshot, setInventorySnapshot] = useState(0);
  const [filesList, setFilesList] = useState(null);
  const [imgData, setImgData] = useState(null);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const activeTab = {
    borderRadius: '6px 6px 0 0',
    height: 40,
    ml: 1,
    mt: isOpenSidebar ? 1.25 : 0.25,
    color: '#A0A0A0',
    fontWeight: 500,
    fontSize: 13,
    // minHeight: 30,
    background: '#FFFFFF',
    textTransform: 'uppercase',
    lineHeight: 1,
    '&:hover': {
      background: '#FFFFFF',
      color: '#A0A0A0',
      cursor: 'pointer'
    }
  };

  const inactiveTab = {
    borderRadius: '6px 6px 0 0',
    height: 40,
    ml: 1,
    mt: isOpenSidebar ? 1.25 : 0.25,
    color: '#B5B5B5',
    fontWeight: 500,
    fontSize: 13,
    // minHeight: 30,
    textTransform: 'uppercase',
    lineHeight: 1,
    '&:hover': {
      color: '#B5B5B5',
      cursor: 'pointer'
    }
  };

  useEffect(() => {
    GetClientDevicesCount().then((res) => {
      if (res.data) {
        setClientDevicesCount(res.data.desktopsCount);
        setInventorySnapshot(res.data.latestPulseInventoryDate[0]);
      }
    });

    GetServerDevicesCount().then((res) => {
      if (res.data) {
        setServerDevicesCount(res.data.serversCount);
      }
    });
    GetProviderLogoPath().then((res) => {
      if (res?.data?.fileData) {
        setFilesList(res.data.fileData);
        setImgData(res?.data?.fileData?.filePath);
      }
    });
  }, [status]);

  return (
    <RootStyle>
      <ToolbarStyle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ display: 'flex', flexItems: 'center', justifyContent: 'center' }}>
            <Box
              sx={{
                height: isOpenSidebar ? 45 : 33,
                width: isOpenSidebar ? 200 : 180,
                mt: isOpenSidebar ? 2 : 0.7,
                ml: isOpenSidebar ? 1.25 : 0.5,
                // ml: 1.25,
                zIndex: 200,
                backgroundColor: imgData !== null ? 'white' : 'inherit',
                transition: 'all 0.1s ease-in-out',
                borderRadius: 0.5
              }}
              component={RouterLink}
              to="/app"
            >
              <Box
                sx={{
                  display: 'inline-block',
                  backgroundImage:
                    imgData !== null
                      ? `url(${imgData})`
                      : 'url(/static/logo/assetlabs_logo_dark.png)',
                  backgroundRepeat: 'no-repeat',
                  // backgroundSize: 150,
                  backgroundSize: isOpenSidebar ? 120 : 90,
                  backgroundPosition: 'center center',
                  // width: 200,
                  width: '100%',
                  height: '100%',
                  transition: 'all 0.2s ease-in-out'
                }}
              />
            </Box>
            <IconButton onClick={onOpenSidebar} sx={{ color: '#DFE3E8', ml: 1 }}>
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: 'flex', height: 40, mt: 0.25 }}>
              <Button
                component={RouterLink}
                to={params?.report ? params.report : '/report/microsoft-licensable'}
                disableRipple
                sx={
                  pathname.includes('/report') ||
                  pathname.includes('/publishers') ||
                  pathname.includes('/devices') ||
                  pathname.includes('/m365')
                    ? activeTab
                    : inactiveTab
                }
              >
                analyze
              </Button>
              <Button
                component={RouterLink}
                to={`/upload?report=${
                  params?.report ? encodeURIComponent(params?.report) : encodeURIComponent(pathname)
                }`}
                disableRipple
                sx={pathname.includes('/upload') ? activeTab : inactiveTab}
              >
                upload
              </Button>
              <Button
                component={RouterLink}
                to={`/download?report=${
                  params?.report ? encodeURIComponent(params?.report) : encodeURIComponent(pathname)
                }`}
                disableRipple
                sx={pathname.includes('/download') ? activeTab : inactiveTab}
              >
                download
              </Button>
            </Box>
          </Box>
          {/* <Box sx={{ flexGrow: 1 }} /> */}
          <Box
            sx={{
              mx: 2,
              color: '#B5B5B5',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
              Inventory Snapshot:&nbsp;
              <span>{inventorySnapshot ? formatDate(inventorySnapshot) : '\u00A0...'}</span>
            </Typography>
            <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
              <img
                alt=""
                src="./static/icons/TopMenuIcon_ClientDevices.gif"
                style={{ paddingRight: 10, height: 20 }}
              />
              Client Devices:&nbsp;<span>{clientDevicesCount || '\u00A0...'}</span>
            </Typography>
            <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
              <img
                alt=""
                src="./static/icons/TopMenuIcon_ServerDevices.gif"
                style={{ paddingRight: 10, height: 20 }}
              />
              Server Devices:&nbsp;<span>{serverDevicesCount || '\u00A0...'}</span>
            </Typography>
          </Box>
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }} sx={{ ml: 1 }}>
            <AccountPopover />
          </Stack>
        </Box>
      </ToolbarStyle>
    </RootStyle>
  );
}
