import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
// material
import { Stack, Container, Card, Typography } from '@material-ui/core';
import useStyles from '../styles';
// components
import Page from '../components/Page';
// modals
import AddGuestUser from './admin/manage-account/components/AddUserDialog';
import CategorizationModal from '../components/modals/CategorizationModal';
import ChangePassword from '../components/modals/ChangePassword';
import CreateNewAccount from '../components/modals/CreateNewAccount';
import Dialog from '../components/modals/Dialog';
import DownloadReport from '../components/modals/DownloadReport';
import EmailReport from '../components/modals/EmailReport';
import MemberInfo from '../components/modals/MemberInfo';
import ModalProviderManagement from './admin/provider-mgmt/components/AddProviderDialog';
import ModalSubManager from '../components/modals/ModalSubManager';
import ManageUser from '../components/modals/ManageUser/index';
import NewAndPastDevices from '../components/modals/NewAndPastDevices';
import AddSubscriptionDialog from './admin/subscription-mgmt/components/AddSubscriptionDialog';

// ----------------------------------------------------------------------

export default function Modals() {
  const classes = useStyles();
  return (
    <Page title="Modals | AssetLabs" className={classes.bodyContainer}>
      <Container className={classes.fullWidth}>
        <Stack
          direction="row"
          className={clsx(classes.mb40, classes.alignCenter, classes.flexBetween)}
        >
          <Typography variant="h4" gutterBottom>
            Modals
          </Typography>
        </Stack>

        <Typography
          color="text.primary"
          variant="h6"
          component="p"
          className={clsx(classes.fontWeightNormal)}
        >
          Check out these modals.
        </Typography>

        <Stack className={clsx(classes.widthFull, classes.mt40)}>
          {/* <NewAndPastDevices /> */}
          {/* <Dialog /> */}
          {/* <AddGuestUser /> */}
          {/* <CreateNewAccount /> */}
          {/* <ChangePassword /> */}
          {/* <EmailReport /> */}
          {/* <DownloadReport /> */}
          {/* <MemberInfo /> */}
          {/* <ModalProviderManagement /> */}
          {/* <ModalSubManager /> */}
          <ManageUser />
          {/* <NewAndPastDevices /> */}
          {/* <NewSubscriptionEntry /> */}
          {/* <CategorizationModal /> */}
        </Stack>
      </Container>
    </Page>
  );
}
