import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Treemap } from '@ant-design/charts';
import { numberformatter } from '../../../helpers';

export default function DemoTreemap(props) {
  const [fetchData, setData] = useState([]);

  useEffect(() => {
    if (props.tData?.length) {
      const formattedData = [];
      props.tData.forEach((i) => {
        const temp = [];
        const t = {
          name: i.key,
          brand: i.key,
          value: i.val
        };

        i.subModel.forEach((j) => {
          temp.push({
            name: j.key,
            value: j.val
          });
        });

        t.children = temp;
        formattedData.push(t);
      });

      setData(formattedData);
    }
  }, [props.tData]);

  const data = {
    name: 'root',
    children: fetchData
  };
  const config = {
    data,
    autoFit: true,
    colorField: 'brand',
    tooltip: {
      follow: true,
      enterable: true,
      offset: 5,
      customContent: (value, items) => {
        if (!items || items.length <= 0) return;
        const { data: itemData } = items[0];
        const parent = itemData.path[1];
        const root = itemData.path[itemData.path.length - 1];
        return (
          `<div style="padding: 8px 2px">` +
          `<div class='title'><b>${itemData.name}</b></div>` +
          `<div class='tooltip-item'><span>Count: </span><b>${numberformatter(
            itemData.value
          )}</b></div>` +
          `<div class='tooltip-item'><span>Category: </span><b>${itemData.brand}</b></div>` +
          `<div class='tooltip-item'><span>Category %: </span><b>${(
            (itemData.value / parent.value) *
            100
          ).toFixed(2)}%</b></div>`
        );
      }
    }
  };

  return <Treemap {...config} />;
}
