import React, { useState, useEffect, useContext } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import { Stack, Typography, IconButton, Grid, Box } from '@material-ui/core';
import ViewListIcon from '@material-ui/icons/ViewList';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { ReportContext } from '../../context/ReportContext';
import useStyles from '../../styles';
import { getNetNewDevicesWithProduct } from '../../server';

const checkLevel = ['commonName', 'licenseType', 'subCategoryName', 'productLine', 'productFamily'];

export default function GetNewDevicesGrid() {
  const [rowData, setRowData] = useState(null);
  const contextData = useContext(ReportContext);
  const [gridApi, setGridApi] = React.useState();
  const classes = useStyles();
  const array = [];

  useEffect(() => {
    if (contextData?.level[0] === 0) {
      for (let i = 0; i < contextData?.data.length; i += 1) {
        if (!array.includes(contextData?.data[i]?.productId)) {
          array.push(contextData?.data[i]?.productId);
        }
      }
    }
    if (checkLevel.includes(contextData?.level[0].field)) {
      for (let i = 0; i < contextData?.level[0].allLeafChildren.length; i += 1) {
        array.push(contextData?.level[0].allLeafChildren[i].data.productId);
      }
    } else {
      array.push(contextData?.level[0].productId);
    }
    getNetNewDevicesWithProduct(array).then((data) => updateData(data));
  }, [contextData]);

  const exportToExcel = () => {
    if (gridApi) {
      gridApi.exportDataAsExcel({
        fileName: `New Instances.xlsx`
      });
    }
  };

  const updateData = (data) => {
    if (data?.data?.model_list) {
      setRowData(data.data.model_list);
    }
  };

  return (
    <>
      <div
        style={{
          height: 'calc(100vh - 300px)',
          width: '100%'
        }}
        className="ag-theme-alpine"
      >
        <Grid>
          <IconButton
            onClick={exportToExcel}
            aria-label="delete"
            className={classes.iconBtn}
            disableRipple
          >
            <img alt="export" src="/assets/icons/excel_export.png" />
            <Typography variant="p" className={classes.mlHalf}>
              Export
            </Typography>
          </IconButton>
        </Grid>
        <AgGridReact
          onGridReady={(params) => setGridApi(params.api)}
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
            floatingFilter: true
          }}
          headerHeight="25"
          animateRows="true"
          rowData={rowData}
        >
          <AgGridColumn
            field="deviceIdentifier"
            headerName="New Instances"
            filter="agTextColumnFilter"
          />
        </AgGridReact>
      </div>
    </>
  );
}
