function styles(theme) {
  return {
    reportsBody: {
      minHeight: 'calc(100vh - 40px)',
      background: '#364150'
    },
    reportsContainer: {
      minHeight: 'calc(100vh - 95px)',
      width: '100%',
      marginTop: '-8px'
    },
    reportsBorder: {
      border: '1px solid #ECECEC'
    },
    categorizationLower: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '3px'
    },
    thickDivider: {
      borderTop: '10px solid grey'
    }
  };
}

export default styles;
