import clsx from 'clsx';
import React, { useState } from 'react';
// material
import { Container, Typography, Box, Link } from '@material-ui/core';
import MuiAlert from '@material-ui/core/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { getReportELPMicrosoft, getReportCMDB, getReportAllPublishers } from '../server';
// components
import Page from '../components/Page';
import useStyles from '../styles';
// import Overlay from '../components/Overlay';

const Alert = React.forwardRef((contextData, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...contextData} />
));

const linkDisabled = {
  fontSize: 16,
  fontWeight: 500,
  pointerEvents: 'none',
  cursor: 'default',
  textDecoration: 'none',
  color: 'grey !important'
};

const linkEnabled = {
  fontSize: 16,
  fontWeight: 500,
  textDecoration: 'underline',
  cursor: 'pointer',
  color: 'inherit'
};

export default function Download() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickMicrosoft = () => {
    setLoading(true);
    getReportELPMicrosoft()
      .then((data) => {
        if (data.status === true) {
          setOpen(true);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleClickNormalization = () => {
    setLoading(true);
    getReportCMDB()
      .then((data) => {
        if (data.status === true) {
          setOpen(true);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleClickELP = () => {
    setLoading(true);
    getReportAllPublishers()
      .then((data) => {
        if (data.status === true) {
          setOpen(true);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <Page
        title="Download | AssetLabs"
        className={clsx(classes.bodyContainer, classes.downloadContainer)}
      >
        <Container className={classes.fullWidth}>
          <Box>
            <Box sx={{ width: '49%', mb: 3, float: 'left' }}>
              <Box sx={{ mb: 3 }}>
                <Typography
                  variant="h6"
                  component="p"
                  sx={{ fontWeight: 400, fontSize: 19, my: 1 }}
                >
                  Inventory Tools
                </Typography>
                <Box sx={{ border: '2px solid #ccc', px: 5, pt: 4, pb: 2 }}>
                  <Box className={classes.dFlex} sx={{ mb: 2 }}>
                    <Box sx={{ mr: 4 }}>
                      <img alt="" src="/assets/downloads/echo.gif" />
                    </Box>
                    <Box>
                      <Link
                        href="https://www.assetlabs.net/echoclient/publish.htm"
                        target="_blank"
                        sx={{ fontSize: 16, fontWeight: 500, textDecoration: 'underline' }}
                      >
                        AssetLabs Echo
                      </Link>
                      <Box>
                        <Typography sx={{ mb: 0.5, fontSize: 16 }}>Inventory Connector</Typography>
                        <Typography sx={{ fontSize: 14 }}>
                          Use Echo to pull inventory from the following sources and upload to your
                          Assetlabs account:
                        </Typography>
                        <Box sx={{ ml: 5 }}>
                          <Typography sx={{ fontSize: 14 }}>- Microsoft SCCM</Typography>
                          <Typography sx={{ fontSize: 14 }}>- Microsoft MAP Toolkit</Typography>
                          <Typography sx={{ fontSize: 14 }}>- Lansweeper</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.dFlex} sx={{ mb: 2 }}>
                    <Box sx={{ mr: 4 }}>
                      <img alt="" src="/assets/downloads/map_icon.png" />
                    </Box>
                    <Box>
                      <Link
                        href="https://www.microsoft.com/en-ca/download/details.aspx?id=7826"
                        target="_blank"
                        sx={{ fontSize: 16, fontWeight: 500, textDecoration: 'underline' }}
                      >
                        Microsoft MAP Toolkit
                      </Link>
                      <Box>
                        <Typography sx={{ my: 0.5, fontSize: 16 }}>
                          Free Agentless Inventory Tool
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.dFlex} sx={{ mb: 2 }}>
                    <Box sx={{ mr: 4 }}>
                      <img alt="" src="/assets/downloads/excel.gif" />
                    </Box>
                    <Box>
                      <Link
                        href="/assets/files/Generic-Inventory-with-Instructions.xlsx"
                        sx={{ fontSize: 16, fontWeight: 500, textDecoration: 'underline' }}
                      >
                        Generic Inventory Template
                      </Link>
                      <Box>
                        <Typography sx={{ my: 0.5, fontSize: 14 }}>
                          Template for uploading generic inventory datasets. Use this when you are
                          unable to use one of the above tools to collect inventory for example in
                          DMZs, or to provide inventory from an unsupported tool.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* hide/hidden box */}
              <Box sx={{ mb: 3 }}>
                <Typography
                  variant="h6"
                  component="p"
                  sx={{ fontWeight: 400, fontSize: 19, my: 1 }}
                >
                  ELP - Effective License Position Reports
                </Typography>
                <Box sx={{ border: '2px solid #ccc', px: 5, pt: 4, pb: 2 }}>
                  <Box className={classes.dFlex} sx={{ mb: 2 }}>
                    <Box sx={{ mr: 4 }}>
                      <img alt="" src="/assets/downloads/excel.gif" />
                    </Box>
                    <Box>
                      <Link
                        // href="#"
                        sx={loading ? linkDisabled : linkEnabled}
                        onClick={handleClickMicrosoft}
                      >
                        Microsoft ELP
                      </Link>
                      <Box>
                        <Typography sx={{ mb: 0.5, fontSize: 16 }}>
                          Official Microsoft Audit Report
                        </Typography>
                        <Typography sx={{ fontSize: 14 }}>
                          This spreadsheet will be emailed to you.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.dFlex} sx={{ mb: 2 }}>
                    <Box sx={{ mr: 4 }}>
                      <img alt="" src="/assets/downloads/excel.gif" />
                    </Box>
                    <Box>
                      <Link sx={loading ? linkDisabled : linkEnabled} onClick={handleClickELP}>
                        ELP - All Licensable Vendors
                      </Link>
                      <Box>
                        <Typography sx={{ mb: 0.5, fontSize: 16 }}>
                          Audit Report for all licensable applications
                        </Typography>
                        <Typography sx={{ fontSize: 14 }}>
                          This spreadsheet is available for download.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* hide/hidden box */}
              <Box className={clsx(classes.dFlex, classes.alignCenter)} sx={{ ml: 5 }}>
                <Box sx={{ mr: 4 }}>
                  <img alt="" src="/assets/downloads/excel.gif" />
                </Box>
                <Box>
                  <Link
                    // href="#"
                    sx={loading ? linkDisabled : linkEnabled}
                    onClick={handleClickNormalization}
                  >
                    Normalization Lookup table (AssetLabs Admin Only)
                  </Link>
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: '49%', mb: 3, float: 'right' }}>
              <Typography variant="h6" component="p" sx={{ fontWeight: 400, fontSize: 19, my: 1 }}>
                Help Guides
              </Typography>
              <Box sx={{ border: '2px solid #ccc', px: 5, pt: 4, pb: 2 }}>
                <Box className={classes.dFlex} sx={{ mb: 2 }}>
                  <Box sx={{ mr: 5 }}>
                    <Link
                      target="_blank"
                      href="https://www.assetlabs.com/wp-content/uploads/2020/10/AssetLabs-ECHO-Walkthrough.pdf"
                    >
                      <img alt="" src="/assets/downloads/guide_echo.png" />
                    </Link>
                  </Box>
                  <Box sx={{ width: '75%' }}>
                    <Typography sx={{ fontSize: 16, mb: 2 }}>How to use AssetLabs Echo</Typography>
                    <Typography sx={{ fontSize: 14 }}>
                      Full guide on using our inventory connector tool to pull inventory and
                      securely upload into your AssetLabs account.
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.dFlex} sx={{ mb: 2 }}>
                  <Box sx={{ mr: 5 }}>
                    <Link
                      target="_blank"
                      href="https://www.assetlabs.com/wp-content/uploads/2020/10/AssetLabs-How-to-use-Microsoft-MAP-Toolkit.pdf"
                    >
                      <img alt="" src="/assets/downloads/guide_use_map.png" />
                    </Link>
                  </Box>
                  <Box sx={{ width: '75%' }}>
                    <Typography sx={{ fontSize: 16, mb: 2 }}>How to use Microsoft MAP</Typography>
                    <Typography sx={{ fontSize: 14 }}>
                      Step by step guide on how to configure Microsoft's free inventory tool to scan
                      your network environment.
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.dFlex} sx={{ mb: 2 }}>
                  <Box sx={{ mr: 5 }}>
                    <Link
                      target="_blank"
                      href="https://www.assetlabs.com/wp-content/uploads/2020/10/AssetLabs-Resolving-Microsoft-MAP-WMI-Error-Messages.pdf"
                    >
                      <img alt="" src="/assets/downloads/guide_map_errors.png" />
                    </Link>
                  </Box>
                  <Box sx={{ width: '75%' }}>
                    <Typography sx={{ fontSize: 16, mb: 2 }}>Resolving MAP Errors</Typography>
                    <Typography sx={{ fontSize: 14 }}>
                      Troubleshooting guide on how to navigate MAP errors.
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.dFlex} sx={{ mb: 2 }}>
                  <Box sx={{ mr: 5 }}>
                    <Link
                      target="_blank"
                      href="https://www.assetlabs.com/wp-content/uploads/2020/10/AssetLabs-Security-Overview.pdf"
                    >
                      <img alt="" src="/assets/downloads/guide_echo.png" />
                    </Link>
                  </Box>
                  <Box sx={{ width: '75%' }}>
                    <Typography sx={{ fontSize: 16, mb: 2 }}>
                      Security & Privacy Overview
                    </Typography>
                    <Typography sx={{ fontSize: 14 }}>
                      Security document detailing the protocols and security surrounding uploading
                      your data to your AssetLabs account.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Page>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="success"
          sx={{ width: '100%', background: 'green' }}
        >
          Email sent successfully
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={loading}
        autoHideDuration={0}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity="info" sx={{ width: '100%' }}>
          Sending report...
        </Alert>
      </Snackbar>
    </>
  );
}
