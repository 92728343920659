import React, { useState, useEffect, useContext } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import clsx from 'clsx';
import productsGridFolderNode from './productsGridFolderNode';
import PublisherFloatingFilter from '../../pages/reports/components/PublisherFloatingFilter';
import LicenseFilter from '../../pages/reports/components/LicenseFilter';
import CategoryFilter from '../../pages/reports/components/CategoryFilter';
import { publishers, softwares, REPORTS } from '../../constants/reports';
import '../../styles/css/Grid.css';

// const publishers = [12, '12a', '12b'];

// const REPORTS = {
//   AllMicrosoft: 5,
//   MicrosoftAuditable: 6,
//   AdobeAuditable: 7,
//   PublishersAtoZ: 12,
//   PublishersByInstallCount: '12a',
//   PublishersByFinancialFootprint: '12b',
//   SoftwareByCategory: '12c',
//   SoftwareByLicenseType: '12d'
// };

export default function ReportsGrid(props) {
  return (
    <AgGridReact
      className={clsx('softwareGrid', {
        softwareByCategoryGrid: props.report === REPORTS.SoftwareByCategory
      })}
      components={{
        productsGridFolderNode
      }}
      frameworkComponents={{
        customPublisherFloatingFilter: PublisherFloatingFilter,
        customLicenseFilter: LicenseFilter,
        customCategoryFilter: CategoryFilter
      }}
      headerHeight="25"
      floatingFiltersHeight="31"
      groupHeaderHeight="25"
      rowHeight="25"
      suppressAggFuncInHeader="true"
      // rowMultiSelectWithClick={false}
      // rowSelection="single"
      icons={{
        groupExpanded: '<img src="/assets/minus.gif"/>',
        groupContracted: '<img src="/assets/plus.gif"/>'
      }}
      defaultColDef={{
        flex: 1,
        sortable: true,
        resizable: true,
        filter: true,
        floatingFilter: true,
        menuTabs: ['filterMenuTab', 'generalMenuTab'],
        cellStyle: (params) => {
          const styles = {};
          if (
            ['subCategoryName', 'productFamily', 'licenseType', 'productLine'].includes(
              params.node?.field
            )
          ) {
            styles['font-size'] = '12px';
            if (params.node?.field !== 'productLine') {
              styles['font-weight'] = '500 !important';
            }
          }

          return styles;
        }
      }}
      autoGroupColumnDef={{
        flex: 1,
        minWidth: 300,
        headerName: `${
          props.report === REPORTS.SoftwareByLicenseType ? 'License-Type' : 'Category'
        }`,
        floatingFilterComponent: 'customPublisherFloatingFilter',
        floatingFilterComponentParams: { suppressFilterButton: true },
        filter: `${
          props.report === REPORTS.SoftwareByLicenseType
            ? 'customLicenseFilter'
            : 'customCategoryFilter'
        }`,
        valueGetter: (params) => {
          const model = params.data.model ? `(${params.data.model})` : '';
          const displayVal = `${params.data.productLine} ${model} ${params.data.majorVersion}`;
          return displayVal;
        },
        field: 'productName',
        cellRendererParams: {
          suppressCount: true
        }
      }}
      skipHeaderOnAutoSize="true"
      autoSizePadding="0"
      // groupDefaultExpanded={null}
      groupDisplayType="singleColumn"
      animateRows
      onGridReady={props.onGridReady}
      rowData={props.rowData}
      // rowGroupPanelShow={'always'}
      onRowClicked={props.onSelectionChanged}
      showOpenedGroup
    >
      {props.report === REPORTS.SoftwareByLicenseType ? (
        <AgGridColumn field="licenseType" cellRenderer="productsGridFolderNode" rowGroup hide />
      ) : (
        <AgGridColumn field="categoryName" cellRenderer="productsGridFolderNode" rowGroup hide />
      )}

      {props.report === REPORTS.SoftwareByCategory && (
        <AgGridColumn field="subCategoryName" rowGroup hide />
      )}
      <AgGridColumn field="productLine" rowGroup hide />
      <AgGridColumn field="productName" hide filter="agSetColumnFilter" />
      <AgGridColumn
        field="commonName"
        headerName="Publisher"
        filter="agSetColumnFilter"
        aggFunc={(params) => params.values[0]}
        cellStyle={(params) => {
          let val = {};
          if (params.node?.field === 'productLine') {
            val = { opacity: '1' };
          } else {
            val = { opacity: '0' };
          }

          return val;
        }}
        minWidth={120}
      />
      <AgGridColumn
        field="quantity"
        minWidth={50}
        headerName="Count"
        headerClass="gridMenuHidden"
        headerTooltip="Count is the raw count of installs from your inventory tool which may may include duplication of installs"
        cellStyle={() => ({ textAlign: 'right', color: '#9c9b9b' })}
        filter={false}
        // filter="agSetColumnFilter"
        aggFunc={(params) => {
          let val = '';
          let sum = 0;
          params.values.forEach((value) => (sum += parseInt(value, 10)));
          val = sum;

          return val;
        }}
      />
      <AgGridColumn
        minWidth={60}
        field="prevQuantity"
        headerClass="gridMenuHidden"
        headerName="Delta"
        filter={false}
        // filter="agSetColumnFilter"
        cellRenderer={(params) => {
          let val = params.value;
          const img = '<img width="23" alt="export" src="/assets/new.gif" />';
          if (val === 'NEW') {
            val = `<span>${img}</span>`;
          } else {
            val = `<span>${val}</span>`;
          }
          return val;
        }}
        cellStyle={(params) => {
          let delta;
          if (params.data) {
            if (!params.data.prevQuantity) {
              delta = null;
            } else {
              delta = params.data.quantity - params.data.prevQuantity;
            }
          } else {
            delta = params.node?.aggData?.prevQuantity;
          }

          let styleObj;
          if (delta > 0) {
            styleObj = { color: 'green' };
          } else if (delta === 'NEW' || delta === 0 || delta === null) {
            styleObj = { color: 'red', fontSize: '9px' };
          } else if (delta < 0) {
            styleObj = { color: 'red' };
          }

          return styleObj;
        }}
        valueGetter={(params) => getDeltaValue(params.data?.quantity, params.data?.prevQuantity)}
        aggFunc={(params) => {
          let value = '';
          if (params.rowNode.field === 'productLine') {
            let sumPrevQuantity = 0;
            let sumQuantity = 0;

            params.rowNode.allLeafChildren?.forEach((node) => {
              if (node?.data) {
                sumPrevQuantity += node.data.prevQuantity;
                sumQuantity += node.data.quantity;
              }
            });

            value = getDeltaValue(sumQuantity, sumPrevQuantity);
          }

          return value;
        }}
      />
      <AgGridColumn
        field="deviceLicenseCount"
        minWidth={55}
        headerClass="gridMenuHidden"
        headerName="Effective Count"
        headerTooltip="Effective count is the total count removing duplication and non-production instances. It is the count used for determining licensable quantities."
        filter={false}
        aggFunc={(params) => {
          let val = '';
          if (params.rowNode.field === 'productLine') {
            let sum = 0;
            params.values.forEach((value) => (sum += parseInt(value, 10)));
            val = sum;
          }

          return val;
        }}
      />
      <AgGridColumn
        field="licenseType"
        minWidth={100}
        filter="agSetColumnFilter"
        cellStyle={(params) => {
          let val = null;
          if (params.node?.field !== 'productLine') {
            val = { opacity: '0' };
          }

          return val;
        }}
        aggFunc={(params) => {
          let val = '';
          if (params.rowNode.field === 'productLine') {
            [val] = params.values;
          }

          return val;
        }}
      />
      {props.report !== REPORTS.SoftwareByCategory && (
        <AgGridColumn headerName="Category" headerClass="category-header">
          <AgGridColumn
            field="subCategoryName"
            width={200}
            headerName="SubCategory"
            minWidth="120"
            filter="agSetColumnFilter"
            cellStyle={(params) => {
              let val = null;
              if (params.node?.field !== 'productLine') {
                val = { opacity: '0' };
              }

              return val;
            }}
            aggFunc={(params) => {
              let val = '';
              if (params.rowNode.field === 'productLine') {
                [val] = params.values;
              }

              return val;
            }}
          />
          <AgGridColumn
            field="categoryName"
            headerName="Category"
            columnGroupShow="open"
            minWidth="120"
            filter="agSetColumnFilter"
            cellStyle={(params) => {
              let val = null;
              if (params.node?.field !== 'productLine') {
                val = { opacity: '0' };
              }

              return val;
            }}
            aggFunc={(params) => {
              let val = '';
              if (params.rowNode.field === 'productLine') {
                [val] = params.values;
              }

              return val;
            }}
          />
        </AgGridColumn>
      )}
    </AgGridReact>
  );
}

function getDeltaValue(quantity, prevQuantity) {
  let value;
  if (prevQuantity === null || prevQuantity === undefined) {
    value = 'NEW';
  } else {
    const diff = quantity - prevQuantity;
    if (diff > 0) {
      value = `+${diff}`;
    } else if (diff === 0) {
      value = '-';
    } else {
      value = diff;
    }
  }

  return value;
}
