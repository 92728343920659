import * as React from 'react';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  styled,
  Paper,
  Stack,
  Button,
  Divider,
  InputBase,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
  OutlinedInput
} from '@material-ui/core';
// import index from '../../components/Admin/AnalyzeTab/MiniBtns';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles, makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Page from '../../components/Page';
import BtnLight from '../../components/buttons/ButtonLight';
import useStyles from '../../styles';

const DialogActions = withStyles({
  root: {
    backgroundColor: '#364150',
    color: '#F4F6F8'
  }
})(MuiDialogActions);

const DialogContent = withStyles({
  root: {
    backgroundColor: '#364150',
    color: '#F4F6F8'
  }
})(MuiDialogContent);

const DialogContentText = withStyles({
  root: {
    backgroundColor: '#364150',
    color: '#F4F6F8'
  }
})(MuiDialogContentText);

const DialogTitle = withStyles({
  root: {
    backgroundColor: '#364150',
    color: '#F4F6F8'
  }
})(MuiDialogTitle);

function createData(account, subcription, status, duration, start, expiry, poNo, type) {
  return { account, subcription, status, duration, poNo, start, expiry, type };
}

const rows = [createData('Trial 30', 'Trial', '30 days', '$0', 'Active', '', '')];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));
export default function Tabpanel(props) {
  const classes = useStyles();
  const [age, setAge] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <Page title="Subscriptions & Service Manager | AssetLabs" className={classes.bodyContainer}>
      <Container maxWidth="lg">
        <Box className={classes.widthFull}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ backgroundColor: '#364150', px: 2, pb: 1, ml: 2, mb: 2 }}
          >
            <Grid item xs={4} sx={{ color: '#fff' }}>
              <h3 className="heading-sub fontSlim">Subscriptions & service manager</h3>
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mb: 3 }}>
            <Grid item xs={4}>
              <h3 className="fontSlim">Subscription</h3>
            </Grid>
            <Grid item xs={8}>
              <Stack direction="row" spacing={2} sx={{ float: 'right', pr: 2, pb: 1 }}>
                <BtnLight variant="contained" size="small" onClick={handleOpen}>
                  Add new subscription
                </BtnLight>
              </Stack>
            </Grid>
          </Grid>

          <Divider />

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Subcription name</TableCell>
                  <TableCell align="right">Display name</TableCell>
                  <TableCell align="right">Duration in days </TableCell>
                  <TableCell align="right">Price</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Type</TableCell>
                  <TableCell align="right">Xero item code</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.account}
                    </TableCell>
                    <TableCell align="right">{row.subcription}</TableCell>
                    <TableCell align="right">{row.status}</TableCell>
                    <TableCell align="right">{row.duration}</TableCell>
                    <TableCell align="right">{row.start}</TableCell>
                    <TableCell align="right">{row.expiry}</TableCell>
                    <TableCell align="right">{row.po_no}</TableCell>
                    <TableCell align="right">{row.type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Dialog open={open} onClose={handleClose} className={classes.root}>
          <DialogTitle>
            Add new subscription
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              To subscribe to this website, please enter your email address here.
            </DialogContentText>
            <TextField
              margin="dense"
              id="name"
              label="Subscription name"
              type="text"
              fullWidth
              variant="standard"
              color="secondary"
              className={classes.textInput}
            />
            <TextField
              margin="dense"
              id="name"
              label="Display name"
              type="text"
              fullWidth
              variant="standard"
              color="secondary"
              className={classes.textInput}
            />
            <TextField
              margin="dense"
              id="name"
              label="Duration"
              type="text"
              fullWidth
              variant="standard"
              color="secondary"
              className={classes.textInput}
            />
            <TextField
              margin="dense"
              id="name"
              label="Price"
              type="text"
              fullWidth
              variant="standard"
              color="secondary"
              className={clsx(classes.textInput, classes.halfInput)}
            />
            <TextField
              margin="dense"
              id="name"
              label="Status"
              type="text"
              fullWidth
              variant="standard"
              color="secondary"
              className={clsx(classes.textInput, classes.halfInput)}
            />
            <TextField
              margin="dense"
              id="name"
              label="Type"
              type="text"
              fullWidth
              variant="standard"
              color="secondary"
              className={clsx(classes.textInput, classes.halfInput)}
            />
            <TextField
              margin="dense"
              id="name"
              label="Xero item code"
              type="text"
              fullWidth
              variant="standard"
              color="secondary"
              className={clsx(classes.textInput, classes.halfInput)}
            />
          </DialogContent>
          <DialogActions>
            <BtnLight variant="contained" size="small" onClick={handleClose}>
              Save
            </BtnLight>
            <BtnLight variant="contained" size="small" onClick={handleClose}>
              Close
            </BtnLight>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
}
