// ----------------------------------------------------------------------

export default function Card(theme) {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up(300)]: {
            paddingLeft: 0,
            paddingRight: 0
          }
        }
      }
    }
  };
}
