import React, { useContext } from 'react';
import clsx from 'clsx';
// material
import { Stack, Typography } from '@material-ui/core';
// components
import BreadcrumbsDef from '../../../../components/BreadcrumbsDef';
import Tabs from './tabs';
import useStyles from '../../../../styles';
import { ReportContext } from '../../../../context/ReportContext';

export default function PageFunc(props) {
  const classes = useStyles();
  const contextData = useContext(ReportContext);

  return (
    <div className={classes.reportsContainer}>
      {contextData?.level[0]?.level === 0 ? (
        <img
          src="./static/icons/software_icon.png"
          alt=""
          height="20"
          style={{ marginRight: '5px' }}
        />
      ) : null}
      <Typography variant="p" sx={{ fontSize: 16, fontWeight: 500 }}>
        {contextData?.level[0]?.level === 0 ||
        contextData?.level[0]?.level === 1 ||
        contextData?.level[0]?.level === 2
          ? contextData?.level[0]?.key
          : contextData?.level[0]?.parent?.key}
      </Typography>
      <Stack spacing={2} className={classes.mbHalf}>
        <BreadcrumbsDef />
      </Stack>
      <Tabs />
    </div>
  );
}
