import React, { useState, useEffect } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { getAllSubscription } from '../../server';

export default function GridExample(props) {
  const [rowData, setRowData] = useState(null);
  useEffect(() => {
    getAllSubscription().then((data) => updateData(data));
  }, []);

  const updateData = (data) => {
    if (data?.data) setRowData(data.data);
  };

  return (
    <div
      style={{
        // height: props.fullScreen ? 'calc(100vh - 180px)' : 'calc(100vh - 245px)',
        height: 'calc(100vh - 100px)',
        // height: 430,
        width: '100%'
      }}
      className="ag-theme-alpine"
    >
      <AgGridReact
        defaultColDef={{
          flex: 1,
          minWidth: 140,
          sortable: true,
          filter: true,
          floatingFilter: true
        }}
        headerHeight="25"
        animateRows="true"
        suppressContextMenu
        rowData={rowData}
      >
        <AgGridColumn field="accountName" headerName="Account" filter="agTextColumnFilter" />
        <AgGridColumn
          field="subscriptionName"
          headerName="Subscription"
          filter="agTextColumnFilter"
          minWidth={100}
        />
        <AgGridColumn
          field="statusName"
          headerName="Status"
          filter="agTextColumnFilter"
          minWidth={100}
        />
        <AgGridColumn
          field="numberOfDaysInSubscription"
          headerName="Duration in Days"
          filter="agSetColumnFilter"
        />
        <AgGridColumn field="effectiveDate" headerName="Start Date" filter="agSetColumnFilter" />
        <AgGridColumn field="expiryDate" headerName="Expiry Date" filter="agSetColumnFilter" />
        {/* <AgGridColumn field="updateDate" headerName="Update" filter="agSetColumnFilter" /> */}
        <AgGridColumn
          field="poNumber"
          headerName="PO Number"
          filter="agSetColumnFilter"
          minWidth={100}
        />
        <AgGridColumn
          field="subscriptionDisplayName"
          headerName="Subscription Type"
          filter="agSetColumnFilter"
          minWidth={100}
        />
        <AgGridColumn
          field="updateBy"
          headerName="Update By"
          filter="agSetColumnFilter"
          minWidth={100}
        />
        <AgGridColumn
          field="createBy"
          headerName="Create By"
          filter="agSetColumnFilter"
          minWidth={100}
        />
        <AgGridColumn
          field="createDate"
          headerName="Create Date"
          filter="agSetColumnFilter"
          minWidth={100}
        />
      </AgGridReact>
    </div>
  );
}
