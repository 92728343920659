import * as React from 'react';
// material
import MuiTypography from '@material-ui/core/Typography';
import { Card } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
// components
import InventoryChart from '../../../../../../components/charts/InventoryChart';
import InventoryGrid from '../../../../../../components/grids/inventoryGrid';

const Typography = withStyles({
  root: {
    fontSize: 14,
    fontWeight: 600,
    textAlign: 'center'
  }
})(MuiTypography);

export default function Inventory() {
  return (
    <>
      <Card sx={{ mt: 2 }}>
        <Typography sx={{ py: 0.75 }}>Versions</Typography>
        <InventoryChart />
      </Card>
      <Card sx={{ mt: 2, pb: 2 }}>
        <InventoryGrid />
      </Card>
    </>
  );
}
