import { format as FNSFormat, formatDistanceToNow } from 'date-fns';

// Create our number formatter.
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0
});

function deFormat(value) {
  if (typeof value === 'string') {
    value = Number(value.replace(/[^0-9.-]+/g, ''));
  }

  return value;
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const billion = 1.0e9;
const million = 1.0e6;
const thousand = 1.0e3;

function numberformatter(value, formatK) {
  if (Number.isNaN(Number(value))) return value;
  if (Number(value) >= billion) {
    value = `${(Number(value) / billion).toFixed(2)}B`;
  } else if (Number(value) >= million) {
    value = `${(Number(value) / million).toFixed(2)}M`;
  } else if (formatK && Number(value) >= thousand) {
    value = `${(Number(value) / thousand).toFixed(2)}K`;
  } else {
    value = numberWithCommas(Number(value));
  }

  return value;
}

function formatDate(date) {
  // const d = new Date(date);
  // let month = `${d.getMonth() + 1}`;
  // let day = `${d.getDate()}`;
  // const year = d.getFullYear();

  // if (month.length < 2) month = `0${month}`;
  // if (day.length < 2) day = `0${day}`;
  // return FNSFormat(new Date(date), 'dd MMMM yyyy');
  // const formattedDate = [year, month, day].join('-');
  const formattedDate = FNSFormat(new Date(date), 'yyyy-MMM-dd');
  return formattedDate === '01-Jan-1970' || formattedDate === '31-Dec-1969' ? '' : formattedDate;
}

function setCookie(name, value, minutes) {
  let expires = '';
  if (minutes) {
    const date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

function getCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(name) {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

function getFileName(response) {
  let filename = '';
  if (response?.headers && Object.keys(response.headers).length > 0) {
    filename = response.headers
      .get('content-disposition')
      .split(';')
      .find((n) => n.includes('filename='))
      .replace('filename=', '')
      .trim();
  }

  return filename;
}

function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`;
}

export {
  formatter,
  deFormat,
  numberformatter,
  setCookie,
  getCookie,
  eraseCookie,
  formatDate,
  getFileName,
  bytesToSize
};
