import { Navigate } from 'react-router-dom';
import { getCookie } from '../helpers';

function PrivateRoute({ element, path }) {
  const authed = getCookie('authed');
  const ele = authed === 'true' ? element : <Navigate to="/login" state={{ path }} replace />;

  return { path, element: ele };
}

export default PrivateRoute;
