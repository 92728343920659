export default function PageFunc(props) {
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        position: 'absolute',
        // background: '#FFFFFFAA',
        top: 0,
        left: 0
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'space-around',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: '#FFFFFF',
          border: '1px solid #babfc7',
          boxShadow: '0 1px 4px 1px rgb(186 191 199 / 40%)',
          paddingLeft: 5,
          paddingRight: 10,
          borderRadius: 3
        }}
      >
        <img
          alt="overlay spinner"
          src="/assets/spin.gif"
          height="30"
          style={{ paddingTop: 1, paddingBottom: 1 }}
        />
        <p style={{ fontSize: 11, marginRight: 4, textAlign: 'center', lineHeight: 'initial' }}>
          {props.children}
        </p>
      </div>
    </div>
  );
}
