// material
import {
  Stack,
  Container,
  Card,
  Typography,
  Grid,
  Paper,
  Box,
  Checkbox,
  styled
} from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';
// components
import Page from '../../components/Page';
import BtnLight from '../../components/buttons/ButtonLight';
import { postCreateAccount } from '../../server';
import useStyles from '../../styles';

const TextField = withStyles({
  root: {
    width: 300
  }
})(MuiTextField);

export default function PageFunc() {
  const classes = useStyles();

  const handleSubmit = (event) => {
    const name = document.getElementById('account-name').value;
    const email = document.getElementById('email-address').value;
    event.preventDefault();
    postCreateAccount(name, email).then((res) => {});
  };
  return (
    <Page title="Create New Account | AssetLabs" className={classes.bodyContainer}>
      <Container className={classes.fullWidth}>
        <Stack mb={1}>
          <Typography variant="h4" gutterBottom>
            Create New Account
          </Typography>
        </Stack>
        <Stack>
          <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 2.5 }}>
              <TextField
                fullWidth
                required
                type="text"
                id="account-name"
                minLength="3"
                name="account-name"
                label="Account Name"
                variant="outlined"
                className={classes.adminInput}
              />
            </Box>
            <Box sx={{ mb: 2.5 }}>
              <TextField
                fullWidth
                required
                type="email"
                name="email-address"
                id="email-address"
                label="Account Administrator Email"
                variant="outlined"
                className={classes.adminInput}
              />
            </Box>
            <BtnLight type="submit">create account</BtnLight>
          </form>
        </Stack>
      </Container>
    </Page>
  );
}
