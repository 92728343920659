import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import MuiDialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import MuiAutocomplete from '@material-ui/core/Autocomplete';
import { withStyles } from '@material-ui/styles';
import MuiIconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/core/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import { getUserAccounts, changeUserAccount } from '../../server';
import useStyles from '../../styles';
import Overlay from '../Overlay';

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const IconButton = withStyles({
  root: {
    padding: 0
  }
})(MuiIconButton);

const Dialog = withStyles({
  paper: {
    minWidth: '30%'
  }
})(MuiDialog);

const Autocomplete = withStyles({
  popper: {
    // position: 'relative',
    // display: 'inline-block',
    // '&::before': {
    //   position: 'absolute',
    //   content: '▼',
    //   height: 20,
    //   width: 20
    // }
    fontSize: 12
  }
})(MuiAutocomplete);

const DialogTitle = withStyles({
  root: {
    background: '#3B4652',
    color: '#FFF',
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 20,
    padding: '8px 12px'
  }
})(MuiDialogTitle);

export default function ChangeUserAccount(props) {
  // const [accountList, setAccountList] = React.useState();
  // const [providerList, setProviderList] = React.useState();
  // const [provider, setProvider] = React.useState();
  const [account, setAccount] = React.useState();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (props.account) {
      setAccount(props.account);
    }
  }, []);

  const handleChange = (event, newValue) => {
    // setAccount(newValue);

    if (newValue && newValue.accountId) {
      setLoading(true);
      changeUserAccount(newValue.accountId)
        .then((res) => {
          if (res && res.status) {
            window.location.reload();
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Box
        className="selectAccount"
        sx={{
          position: 'absolute',
          top: 57,
          zIndex: 200
        }}
      >
        {props.accountList && (
          <Autocomplete
            disablePortal
            id="accountId"
            open="true"
            options={props.accountList.sort(
              (a, b) => -b.providerName.localeCompare(a.providerName)
            )}
            groupBy={(option) => option.providerName}
            getOptionLabel={(option) => option?.accountName || ''}
            defaultValue={props.account}
            onChange={handleChange}
            renderInput={(params) => (
              <div ref={params.InputProps.ref} className={classes.caretSign}>
                <input
                  name="accountId"
                  type="text"
                  style={{
                    width: '182px',
                    marginLeft: '7px',
                    borderRadius: '2px',
                    padding: '3px',
                    backgroudColor: 'white',
                    height: '36px',
                    backgroundImage: 'url(assets/caret_down.png) right 4px center no-repeat scroll'
                  }}
                  {...params.inputProps}
                />
              </div>
            )}
          />
        )}
      </Box>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} invisible>
        {/* <Grow easing={{ enter: 'cubic-bezier(0, 2.5, .8, 1)' }} in={loading} timeout={800}>
            <Box sx={{ backgroundColor: 'white !important' }} className={classes.overlay}>
              <Typography sx={{ color: '#364150 !important' }} className={classes.overlayText}>
                <CircularProgress size={16} sx={{ mr: 1 }} /> Loading...
              </Typography>
            </Box>
          </Grow> */}
        <Overlay>Loading...</Overlay>
      </Backdrop>
    </>
  );
}
