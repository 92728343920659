import React, { useContext } from 'react';
import { Breadcrumbs, Link } from '@material-ui/core';
import useStyles from '../styles';

import { ReportContext } from '../context/ReportContext';

export default function PageFunc() {
  const value = useContext(ReportContext);
  return (
    <>
      {value?.level[0]?.level === 0 ? (
        <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: 13, mt: 0.5 }}>
          <Link underline="none" color="inherit">
            {value?.level[0]?.key}
          </Link>
        </Breadcrumbs>
      ) : (
        ''
      )}
      {value?.level[0]?.level === 1 ? (
        <Breadcrumbs aria-label="breadcrumb" separator=">" sx={{ fontSize: 13, mt: 0.5 }}>
          <Link underline="none" color="inherit">
            {value?.level[0]?.parent?.key}
          </Link>
          <Link underline="none" color="inherit">
            {value?.level[0]?.key}
          </Link>
        </Breadcrumbs>
      ) : (
        ''
      )}
      {value?.level[0]?.level === 2 ? (
        <Breadcrumbs aria-label="breadcrumb" separator=">" sx={{ fontSize: 13, mt: 0.5 }}>
          <Link underline="none" color="inherit">
            {value?.level[0]?.parent?.parent?.key}
          </Link>
          {value?.level[0]?.parent?.key ? (
            <Link underline="none" color="inherit">
              {value?.level[0]?.parent?.key}
            </Link>
          ) : null}
          <Link underline="none" color="inherit">
            {value?.level[0]?.key}
          </Link>
        </Breadcrumbs>
      ) : null}
      {value?.level[0]?.level === 3 ? (
        <Breadcrumbs aria-label="breadcrumb" separator=">" sx={{ fontSize: 13, mt: 0.5 }}>
          <Link underline="none" color="inherit">
            {value?.level[0]?.parent?.parent?.parent?.key}
          </Link>

          {value?.level[0]?.parent?.parent?.key ? (
            <Link underline="none" color="inherit">
              {value?.level[0]?.parent?.parent?.key}
            </Link>
          ) : null}

          <Link underline="none" color="inherit">
            {value?.level[0]?.parent?.key}
          </Link>
        </Breadcrumbs>
      ) : (
        ''
      )}
    </>
  );
}
