import * as React from 'react';
import clsx from 'clsx';
import MuiTypography from '@material-ui/core/Typography';
import MuiDialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Box from '@material-ui/core/Box';
import MuiTab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import MuiTabPanel from '@material-ui/lab/TabPanel';
import { styled } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import MuiIconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from '../../styles';
import BtnLight from '../buttons/ButtonLight';
import GetNewDevicesGrid from '../grids/GetNewDevicesGrid';
import GetMissingDevicesGrid from '../grids/GetMissingDevicesGrid';

const IconButton = withStyles({
  root: {
    padding: 0
  }
})(MuiIconButton);

const Dialog = withStyles({
  paper: {
    minWidth: 650,
    height: 'calc(100% - 200px)'
  }
})(MuiDialog);

const DialogTitle = withStyles({
  root: {
    background: '#3B4652',
    color: '#FFF',
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 10,
    padding: '8px 12px'
  }
})(MuiDialogTitle);

export default function NewAndPastDevices(props) {
  const handleClose = () => {
    props.setOpen(false);
  };
  const classes = useStyles();
  return (
    <div>
      {/* <BtnLight onClick={handleClickOpen}>New & Past Devices</BtnLight> */}
      <Dialog open={props.open} onClose={handleClose} className={classes.root}>
        <DialogTitle>
          New & Past Devices
          <IconButton aria-label="close" onClick={handleClose} className={classes.iconStyle}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className={clsx(classes.dFlex, classes.alignCenter, classes.flexBetween, classes.mb20)}
        >
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
          </DialogContentText> */}
          <GetNewDevicesGrid />
          <GetMissingDevicesGrid />
        </DialogContent>
      </Dialog>
    </div>
  );
}
