import { Button } from '@material-ui/core';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const activeMenu = { borderLeft: '2px solid #4395d0', background: '#ffffff1a', color: '#ffffff' };
const activeReportItems = {
  paddingLeft: '10px !important',
  borderLeft: '2px solid #4395d0',
  background: '#ffffff1a',
  color: '#ffffff'
};
const inactiveReportItems = {
  paddingLeft: '12px !important'
};

const activeNestedMenu = {
  paddingLeft: '34px !important',
  borderLeft: '2px solid #4395d0',
  background: '#ffffff1a',
  color: '#ffffff',
  textDecoration: 'underline'
};
const inactiveNestedMenu = { paddingLeft: '36px !important', textDecoration: 'underline' };

export default (props) => {
  let component = <></>;
  if (props.img) {
    component = (
      <Button
        color="inherit"
        fullWidth
        component={RouterLink}
        to={props.route}
        sx={props.pathname.includes(props.route) ? activeReportItems : inactiveReportItems}
      >
        <span
          style={{
            display: 'inlineBlock',
            float: 'left',
            marginRight: 5
          }}
        >
          {props.pathname.includes('/operating-systems') ? (
            <img src={`./static/icons/${props.imgActive}`} alt="" />
          ) : (
            <img src={`./static/icons/${props.img}`} alt="" />
          )}
        </span>
        {props.name}
      </Button>
    );
  } else if (props.subMenu) {
    component = (
      <Button
        color="inherit"
        fullWidth
        component={RouterLink}
        to={props.route}
        sx={props.pathname.includes(props.route) ? activeNestedMenu : inactiveNestedMenu}
      >
        {props.name}
      </Button>
    );
  } else {
    component = (
      <Button
        color="inherit"
        fullWidth
        component={RouterLink}
        to={props.route}
        sx={props.pathname.includes(props.route) ? activeMenu : null}
      >
        {props.name}
      </Button>
    );
  }
  return <>{component}</>;
};
