export default function productFamilyComponent(params) {
  const element = document.createElement('span');
  const imageElement = document.createElement('img');
  const dataSpan = document.createElement('span');
  const data = document.createTextNode(params.value);
  imageElement.src = '/assets/publisher_grid.gif';

  dataSpan.appendChild(data);
  element.appendChild(imageElement);
  element.appendChild(dataSpan);
  imageElement.style.verticalAlign = 'text-bottom';
  dataSpan.style.marginLeft = '4px';
  return element;
}
