import React, { useState, useRef, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Summary from './tabs/Summary';
import PublisherSummary from './tabs/publisherTabs/PublisherSummary';
import EntitlementExposure from './tabs/publisherTabs/EntitlementExposure';
import Licences from './tabs/productLineTabs/Licenses';
import LicenseState from './tabs/publisherTabs/LicenseState';
import CategoryState from './tabs/publisherTabs/CategoryState';
import Inventory from './tabs/productLineTabs/Inventory';
import Devices from './tabs/Devices';
import History from './tabs/productLineTabs/History';
import Categorization from './tabs/productLineTabs/Categorization';
import Controls from './controls';
import DeviceGrid from '../../../../components/grids/deviceGrid';
import useStyles from '../../../../styles';
import { ReportContext } from '../../../../context/ReportContext';
import { ReportGridContext } from '../../../../context/ReportGridContext';
import { REPORTS } from '../../../../constants/reports';

export default function LabTabs(props) {
  const classes = useStyles();
  const [value, setValue] = useState('1');
  const [status, setStatus] = React.useState('');
  const renderCount = useRef(true);
  const contextData = useContext(ReportContext);
  const gridContextData = useContext(ReportGridContext);
  const [gridApi, setGridApi] = React.useState();
  const { pathname } = useLocation();
  const levelArr = ['commonName', 'licenseType', 'subCategoryName'];

  if (contextData?.level[0] !== 0) {
    renderCount.current = false;
  }

  useEffect(() => {
    // if (!levelArr.includes(contextData?.level[0]?.parent?.field)) {
    //   setValue('1');
    // }
    if (
      (contextData?.level[0]?.field === 'productFamily' ||
        levelArr.includes(contextData?.level[0]?.field)) &&
      parseInt(value, 10) > 3
    ) {
      setValue('1');
    }
    if (contextData?.level[0]?.parent?.field === 'productLine') {
      setValue('1');
    }
  }, [props]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setReportParams = (params) => {
    setGridApi(params.api);
    // setReportName(name);
  };

  const exportToExcel = () => {
    if (gridApi) {
      gridApi.exportDataAsExcel({
        fileName: `Devices.xlsx`
      });
    }
  };

  return (
    <Box className={classes.tabsContainer}>
      <TabContext value={value}>
        <Box>
          {renderCount.current ? (
            <>
              {gridContextData.report === REPORTS.MicrosoftAuditable ||
              gridContextData.report === REPORTS.AdobeAuditable ? (
                <>
                  <TabList onChange={handleChange} className={classes.tabsPanel}>
                    {!pathname.includes('/by-category') &&
                    !pathname.includes('/by-license-type') ? (
                      <Tab className={classes.tab} value="1" label="Summary" disableRipple />
                    ) : null}
                    {!pathname.includes('/microsoft-licensable') ? (
                      <Tab
                        className={classes.tab}
                        value={
                          pathname.includes('/by-category') || pathname.includes('/by-license-type')
                            ? 1
                            : 2
                        }
                        label="Devices"
                        disableRipple
                      />
                    ) : null}
                    {!pathname.includes('/by-category') &&
                    !pathname.includes('/by-license-type') ? (
                      <Tab
                        className={classes.tab}
                        value="3"
                        label="Entitlement Exposure"
                        disableRipple
                      />
                    ) : null}
                  </TabList>
                  <TabPanel value="1" className={classes.px0}>
                    <PublisherSummary />
                  </TabPanel>
                  <TabPanel
                    value={
                      pathname.includes('/by-category') || pathname.includes('/by-license-type')
                        ? 1
                        : 2
                    }
                    className={classes.pa0}
                  >
                    {contextData.level[0] || contextData.data ? (
                      <Controls
                        {...{
                          exportToExcel,
                          getStatus: (status) => setStatus(status),
                          gridApi
                        }}
                      />
                    ) : null}
                    {contextData.level[0] || contextData.data ? (
                      <DeviceGrid
                        gridHeight={157}
                        setReportParams={setReportParams}
                        status={status}
                      />
                    ) : null}
                  </TabPanel>
                  <TabPanel value="3" className={classes.pa0}>
                    <EntitlementExposure />
                  </TabPanel>
                </>
              ) : (
                // <div></div>
                <div
                  className={clsx(classes.dFlex, classes.alignCenter)}
                  style={{
                    position: 'absolute',
                    // left: '25%',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    // marginLeft: 20,
                    marginRight: 20
                  }}
                >
                  <img src="/assets/chart_placeholder.jpg" alt="publisher placeholder" />
                  <Typography component="p" sx={{ textAlign: 'center', minWidth: 180 }}>
                    Select a Publisher or Product Line on the left to see details
                  </Typography>
                </div>
              )}
            </>
          ) : (
            ''
          )}
          {contextData?.level[0]?.field === 'commonName' ||
          contextData?.level[0]?.field === 'licenseType' ||
          contextData?.level[0]?.field === 'subCategoryName' ||
          contextData?.level[0]?.field === 'productFamily' ? (
            <>
              <TabList onChange={handleChange} className={classes.tabsPanel}>
                {!pathname.includes('/by-category') && !pathname.includes('/by-license-type') ? (
                  <Tab className={classes.tab} value="1" label="Summary" disableRipple />
                ) : null}
                {!pathname.includes('/microsoft-licensable') ? (
                  <Tab className={classes.tab} value="2" label="Devices" disableRipple />
                ) : null}
                {!pathname.includes('/by-category') && !pathname.includes('/by-license-type') ? (
                  <Tab
                    className={classes.tab}
                    value="3"
                    label="Entitlement Exposure"
                    disableRipple
                  />
                ) : null}
              </TabList>
              <TabPanel value="1" className={classes.px0}>
                <PublisherSummary />
              </TabPanel>
              <TabPanel
                value={
                  pathname.includes('/by-category') || pathname.includes('/by-license-type') ? 1 : 2
                }
                className={classes.pa0}
              >
                {contextData.level[0] ? (
                  <Controls
                    {...{
                      exportToExcel,
                      getStatus: (status) => setStatus(status),
                      gridApi
                    }}
                  />
                ) : null}
                {contextData.level[0] ? (
                  <DeviceGrid
                    {...{
                      gridHeight: 203,
                      setReportParams,
                      status
                    }}
                  />
                ) : null}
              </TabPanel>
              <TabPanel value="3" className={classes.pa0}>
                <EntitlementExposure />
              </TabPanel>
            </>
          ) : (
            ''
          )}
          {contextData?.level[0]?.field === 'productLine' ? (
            <>
              <TabList onChange={handleChange} className={classes.tabsPanel}>
                <Tab className={classes.tab} value="1" label="Version & Support" disableRipple />
                <Tab className={classes.tab} value="2" label="Licenses" disableRipple />
                <Tab className={classes.tab} value="3" label="Devices" disableRipple />
                <Tab className={classes.tab} value="4" label="History" disableRipple />
                <Tab className={classes.tab} value="5" label="Categorization" disableRipple />
              </TabList>
              <TabPanel value="1" className={classes.pa0}>
                <Inventory />
              </TabPanel>
              <TabPanel value="2" className={classes.pa0}>
                <Licences />
              </TabPanel>
              <TabPanel value="3" className={classes.pa0}>
                <Devices />
              </TabPanel>
              <TabPanel value="4">
                <History />
              </TabPanel>
              <TabPanel value="5" className={classes.px0}>
                <Categorization />
              </TabPanel>
            </>
          ) : (
            ''
          )}
          {contextData?.level[0]?.parent?.field === 'productLine' ? (
            <>
              <TabList onChange={handleChange} className={classes.tabsPanel}>
                <Tab className={classes.tab} value="1" label="Devices" disableRipple />
              </TabList>
              <TabPanel value="1" className={classes.pa0}>
                <Devices />
              </TabPanel>
            </>
          ) : (
            ''
          )}
        </Box>
      </TabContext>
    </Box>
  );
}
