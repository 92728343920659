import React, { useReducer } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/core/Alert';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import useStyles from '../../styles/index';
import BtnLight from '../../components/buttons/ButtonLight';
import { postForgotPassword } from '../../server';

const currentYear = new Date().getFullYear();
const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

export default function Reset() {
  const classes = useStyles();

  const [formInput, setFormInput] = useReducer((state, newState) => ({ ...state, ...newState }), {
    emailAddress: ''
  });

  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccess(false);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    setLoading(true);
    postForgotPassword(formInput)
      // .then((response) => response.json())
      .then((response) => {
        setLoading(false);
        if (response.status) {
          setOpen(true);
        }
        if (response.status) {
          setMessage(response.message);
          setSuccess(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        setOpen(true);
      });
  };

  const handleInput = (evt) => {
    const {
      target: { name }
    } = evt;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };

  return (
    <Box className={classes.authBody}>
      <Box className={classes.authSection}>
        <Box className={classes.authCard}>
          <form onSubmit={handleSubmit}>
            <Box>
              <Box
                className={clsx(
                  classes.dFlex,
                  classes.alignCenter,
                  classes.flexBetween,
                  classes.mb40
                )}
              >
                <Box item xs={6} className={clsx(classes.mt20, classes.pr20, classes.authBtn)}>
                  <BtnLight href="/login">login</BtnLight>
                </Box>
              </Box>
              <Box item xs={6} className={clsx(classes.mt20, classes.pl10Perc)}>
                <Box className={classes.authLogo}>
                  <img
                    src="./static/logo/AssetLabs-White-Blue-Text.jpg"
                    alt=""
                    width="250px"
                    height="auto"
                  />
                  <Typography className={clsx(classes.authLogoCaption, classes.textCenter)}>
                    Software Asset Management: Client Portal
                  </Typography>
                </Box>
              </Box>
              <Box container className={clsx(classes.dFlex, classes.alignCenter)}>
                <Box item xs={6} className={clsx(classes.authLeft, classes.pl10Perc)}>
                  <Box className={classes.mb20}>
                    <Typography
                      className={clsx(classes.mb20, classes.authHeading)}
                      variant="h2"
                      color="text.secondary"
                      sx={{ fontWeight: '500' }}
                    >
                      Reset Password
                    </Typography>
                    <Typography variant="p" color="text.secondary" className={classes.authText}>
                      Please enter your email address used to log-in to the AssetLabs Portal. An
                      email will be sent to you allowing you to reset your password.
                    </Typography>
                  </Box>
                  <Box className={classes.mb20}>
                    <TextField
                      fullWidth
                      required
                      type="email"
                      name="emailAddress"
                      id="emailAddress"
                      label="Email"
                      variant="outlined"
                      onChange={handleInput}
                      className={classes.authInput}
                    />
                  </Box>
                  <Box container className={clsx(classes.mb20)}>
                    <Box item xs={6} className={classes.mb20}>
                      <BtnLight disabled={loading} type="submit">
                        reset
                      </BtnLight>
                    </Box>
                    <Box item xs={6}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ ml: '0 !important' }}
                        className={clsx(classes.authRemember)}
                      >
                        Already have an account? &nbsp;
                        <Link
                          href="/login"
                          variant="body2"
                          className={classes.footerLinks}
                          sx={{ fontSize: 12 }}
                        >
                          Login
                        </Link>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box item xs={6} className={clsx(classes.mxAuto, classes.authImage)}>
                  <img
                    src="./static/reset.svg"
                    alt=""
                    height="300"
                    className={classes.textCenter}
                  />
                </Box>
              </Box>
            </Box>
          </form>
          {/* <Box className={clsx(classes.cardFooter, classes.authFooterSettings)}>
            <Typography className={clsx(classes.cardFooterText, classes.pl10Perc)}>
              Don't have an AssetLabs account?&nbsp;
              <Link href="#" className={classes.footerLinks}>
                See a Demo
              </Link>
            </Typography>
          </Box> */}
        </Box>
        <Box className={clsx(classes.textCenter, classes.mt20, classes.authFooter)}>
          <Typography className={classes.authFooterText}>
            Copyright &copy; {currentYear} AssetLabs Inc. All rights reserved.&nbsp;
            <Link href="#" className={clsx(classes.footerLinks, classes.fontWeightNormal)}>
              Contact Us
            </Link>
          </Typography>
        </Box>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Invalid email.
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={success}
          autoHideDuration={3000}
          onClose={handleCloseSuccess}
        >
          <Alert
            onClose={handleCloseSuccess}
            severity="success"
            sx={{ width: '100%', background: '#2196f3' }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}
