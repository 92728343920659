import { LicenseManager } from 'ag-grid-enterprise';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

LicenseManager.setLicenseKey(
  'CompanyName=AssetLabs,LicensedApplication=AssetLabs Web Portal,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-021744,ExpiryDate=29_October_2022_[v2]_MTY2Njk5ODAwMDAwMA==ba6c6d64749215d980306e0dc954e3f1'
);

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeConfig>
      <ScrollToTop />
      <Router />
    </ThemeConfig>
  );
}
