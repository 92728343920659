import { makeStyles } from '@material-ui/styles';
import authStyles from './Auth';
import adminStyles from './Admin';
import dialogStyles from './Dialog';
import splitStyles from './Split';
import buttonStyles from './Button';
import tabStyles from './Tabs';
import reportStyles from './Reports';
import tableStyles from './Table';
import commonStyles from './common';

export default makeStyles((theme) => {
  const common = commonStyles(theme);
  const split = splitStyles(theme);
  const button = buttonStyles(theme);
  const tabs = tabStyles(theme);
  const table = tableStyles(theme);
  const reports = reportStyles(theme);
  const dialog = dialogStyles(theme);
  const auth = authStyles(theme);
  const admin = adminStyles(theme);

  return {
    ...auth,
    ...admin,
    ...split,
    ...button,
    ...tabs,
    ...table,
    ...reports,
    ...dialog,
    ...common
  };
});
