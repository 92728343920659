import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { withStyles, makeStyles } from '@material-ui/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { Box, Button, Drawer, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowDownIcon from '@material-ui/icons//KeyboardArrowDown';
import CloseIcon from '@material-ui/icons/Cancel';
import { uniqBy } from 'lodash';
// components
import { MHidden } from '../../components/@material-extend';
import ChangeUserAccount from '../../components/modals/ChangeUserAccount';
import DashboardSidebarMenuItem from './DashboardSidebarMenuItem';
import DashboardSidebarMenuHeading from './DashboardSidebarMenuHeading';
import { getUserAccounts, GetReportNames } from '../../server';
import {
  reportSubMenuLine,
  reportSubMenuEndUser,
  reportMenusOS,
  subMenusDevices,
  subMenusPublishers,
  mainMenusPublishers,
  mainMenusDevices,
  mainMenusReports,
  subMenusEndUser,
  subMenusLine
} from '../../constants/menus';
//
import '../../theme/palette';
import '../../styles/css/Scrollbar.css';
// ----------------------------------------------------------------------

let DRAWER_WIDTH = 220;
const useStyles = makeStyles({
  searchField: {
    margin: '0 auto',
    marginTop: '1rem',
    marginBottom: '2rem',
    background: '#EAEBEC',
    width: 180,
    height: 32,
    borderRadius: '15px',
    '& div input': {
      width: 120
    }
  },
  textBlack: {
    color: '#000000',
    paddingBottom: 2,
    '& > svg': {
      color: '#b4b2b2'
    }
  }
});

const Accordion = withStyles({
  root: {
    backgroundColor: '#364150',
    boxShadow: 'none',
    color: '#9a9ea4',
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      borderRadius: 0,
      margin: 0
    },
    '&:first-of-type': {
      borderRadius: 0
    },
    '&:last-of-type': {
      // paddingBottom: 100,
      marginBottom: 0
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
    marginTop: 8,
    marginBottom: 8,
    '& .MuiButton-root': {
      lineHeight: 1.5,
      display: 'block',
      height: 18,
      fontWeight: 300,
      fontSize: 12,
      // color: '#9a9ea4',
      padding: theme.spacing(0, 1.5),
      marginTop: 0,
      marginBottom: 0,
      borderRadius: 0,
      paddingLeft: 48,
      justifyContent: 'left',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      // '&:hover': {
      //   background: '#4c5968'
      // },
      '& > span': {
        padding: 0,
        marginTop: 0,
        marginBottom: 0
      }
    }
  }
}))(MuiAccordionDetails);

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: 15,
  padding: theme.spacing(0.75, 2.25),
  width: 180,
  backgroundColor: theme.palette.primary,
  justifyContent: 'center',
  border: '2px solid #9a9ea4',
  [theme.breakpoints.up('lg')]: {
    marginTop: 0
  }
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [accountList, setAccountList] = React.useState();
  const [show, setShow] = React.useState(false);
  // const [providerList, setProviderList] = React.useState();
  // const [provider, setProvider] = React.useState();
  const [account, setAccount] = React.useState();
  const [reportsToShow, setReportsToShow] = useState();

  const { pathname } = useLocation();
  const firstUpdate = useRef(false);

  useEffect(() => {
    getUserAccounts()
      .then((res) => {
        if (res && res.data) {
          const uniq = uniqBy(res.data.providers, 'accountName');
          setAccountList(uniq);
          // setProviderList(res.data.providerGroup);
          const selectedAccount = res.data.providers.find(
            (x) => x.accountId === res.data.accountId
          );
          // const selectedProvider = res.data.providerGroup.find((x) => x.selected);

          if (selectedAccount) setAccount(selectedAccount);
          // if (selectedProvider) setProvider(selectedProvider);
        }
      })
      .catch(() => {});

    GetReportNames()
      .then((res) => {
        if (res?.data?.modelList) {
          const reportIds = res.data.modelList.map((x) => x.reportId);
          setReportsToShow(reportIds);
        }
      })
      .catch(() => {});
  }, []);

  const openChangeAccount = () => {
    setShow(!show);
  };

  if (firstUpdate.current) {
    DRAWER_WIDTH = isOpenSidebar ? 220 : 0;
    firstUpdate.current = true;
  }
  firstUpdate.current = true;
  const renderContent = (
    <Box>
      <Box sx={{ mb: 2.5, mt: 1.5, mx: 1.5 }}>
        <Button underline="none" onClick={openChangeAccount}>
          <AccountStyle>
            <Box sx={{ display: 'flex' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#9a9ea4',
                  fontWeight: 400,
                  fontSize: 12,
                  fontFamily: 'Roboto',
                  pt: '3px'
                }}
              >
                {show ? 'Select Account' : account?.accountName}
              </Typography>
              {show ? (
                <CloseIcon sx={{ ml: '8px' }} color="action" />
              ) : (
                <KeyboardArrowDownIcon sx={{ ml: '8px' }} color="action" />
              )}
            </Box>
          </AccountStyle>
        </Button>
        {accountList && show && <ChangeUserAccount accountList={accountList} account={account} />}
      </Box>
      {/* <Paper component="form" className={classes.searchField}>
        <InputBase
          sx={{ fontSize: 12, ml: 2 }}
          placeholder="Search"
          inputProps={{ 'aria-label': 'search' }}
          className={classes.textBlack}
        />
        <IconButton
          disableRipple
          disableFocusRipple
          type="submit"
          aria-label="search"
          className={classes.textBlack}
        >
          <SearchIcon />
        </IconButton>
      </Paper> */}
      <Box sx={{ zIndex: 100 }}>
        {/* <Accordion>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            component={RouterLink}
            to="/dashboard2"
            sx={pathname.includes('/dashboard') ? activeMenu : null}
          >
            <Typography color="inherit">
              <div style={{ height: 31, paddingTop: 5 }}>
                <span
                  style={{
                    display: 'inlineBlock',
                    float: 'left',
                    marginTop: -5
                  }}
                >
                  {pathname.includes('/dashboard') ? (
                    <img src="./static/icons/MenuIcon_Dashboards-Active.gif" alt="" />
                  ) : (
                    <img src="./static/icons/MenuIcon_Dashboards-Inactive.gif" alt="" />
                  )}
                </span>
                <span
                  style={{
                    display: 'inlineBlock',
                    color: pathname.includes('/dashboard') ? activeColor.color : 'inherit'
                  }}
                >
                  &nbsp; Dashboard
                </span>
              </div>
            </Typography>
          </AccordionSummary>
        </Accordion> */}
        {/* <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={pathname.includes('/admin') ? activeMenu : null}
          >
            <Typography color="inherit">
              <div style={{ height: 31, paddingTop: 5 }}>
                <span
                  style={{
                    display: 'inlineBlock',
                    float: 'left',
                    marginTop: -5
                  }}
                >
                  {pathname.includes('/admin') ? (
                    <img src="./static/icons/MenuIcon_Software_Publishers-Active.gif" alt="" />
                  ) : (
                    <img src="./static/icons/MenuIcon_Software_Publishers-Inactive.gif" alt="" />
                  )}
                </span>
                <span
                  style={{
                    display: 'inlineBlock',
                    color: pathname.includes('/admin') ? activeColor.color : 'inherit'
                  }}
                >
                  &nbsp; Admin
                </span>
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Button
              color="inherit"
              fullWidth
              component={RouterLink}
              to="/admin/manage-account"
              sx={pathname.includes('/manage-account') ? activeFullMenu : inactiveFullMenu}
            >
              {pathname.includes('/manage-account') ? (
                <img
                  src="./static/icons/MenuIcon_Publishers-Active.gif"
                  alt=""
                  style={{ paddingRight: 10 }}
                />
              ) : (
                <img
                  src="./static/icons/MenuIcon_Publishers-Inactive.gif"
                  alt=""
                  style={{ paddingRight: 10 }}
                />
              )}
              <span
                style={{
                  color: pathname.includes('/manage-account') ? activeColor.color : 'inherit'
                }}
              >
                Manage Account
              </span>
            </Button>
            <Button
              color="inherit"
              fullWidth
              component={RouterLink}
              to="/admin/create-account"
              sx={pathname.includes('/create-account') ? activeFullMenu : inactiveFullMenu}
            >
              {pathname.includes('/create-account') ? (
                <img
                  src="./static/icons/MenuIcon_Publishers-Active.gif"
                  alt=""
                  style={{ paddingRight: 10 }}
                />
              ) : (
                <img
                  src="./static/icons/MenuIcon_Publishers-Inactive.gif"
                  alt=""
                  style={{ paddingRight: 10 }}
                />
              )}
              <span
                style={{
                  color: pathname.includes('/create-account') ? activeColor.color : 'inherit'
                }}
              >
                Create Account
              </span>
            </Button>
            <Button
              color="inherit"
              fullWidth
              component={RouterLink}
              to="/admin/provider-management"
              sx={pathname.includes('/provider-management') ? activeFullMenu : inactiveFullMenu}
            >
              {pathname.includes('/provider-management') ? (
                <img
                  src="./static/icons/MenuIcon_Publishers-Active.gif"
                  alt=""
                  style={{ paddingRight: 10 }}
                />
              ) : (
                <img
                  src="./static/icons/MenuIcon_Publishers-Inactive.gif"
                  alt=""
                  style={{ paddingRight: 10 }}
                />
              )}
              <span
                style={{
                  color: pathname.includes('/provider-management') ? activeColor.color : 'inherit'
                }}
              >
                Provider Management
              </span>
            </Button>
            <Button
              color="inherit"
              fullWidth
              component={RouterLink}
              to="/admin/subscription-management"
              sx={pathname.includes('/subscription-management') ? activeFullMenu : inactiveFullMenu}
            >
              {pathname.includes('/subscription-management') ? (
                <img
                  src="./static/icons/MenuIcon_Publishers-Active.gif"
                  alt=""
                  style={{ paddingRight: 10 }}
                />
              ) : (
                <img
                  src="./static/icons/MenuIcon_Publishers-Inactive.gif"
                  alt=""
                  style={{ paddingRight: 10 }}
                />
              )}
              <span
                style={{
                  color: pathname.includes('/subscription-management')
                    ? activeColor.color
                    : 'inherit'
                }}
              >
                Subscription Management
              </span>
            </Button>
          </AccordionDetails>
        </Accordion> */}
        <Accordion defaultExpanded>
          <DashboardSidebarMenuHeading pathname={pathname} {...mainMenusPublishers} />
          <AccordionDetails>
            {subMenusPublishers.map((reportItem) => (
              <DashboardSidebarMenuItem pathname={pathname} {...reportItem} />
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <DashboardSidebarMenuHeading pathname={pathname} {...mainMenusReports} />
          {reportsToShow && (
            <AccordionDetails>
              <DashboardSidebarMenuItem pathname={pathname} {...subMenusLine} />
              <Box>
                {reportSubMenuLine.map(
                  (reportItem) =>
                    reportsToShow.includes(reportItem.code) && (
                      <DashboardSidebarMenuItem pathname={pathname} {...reportItem} />
                    )
                )}
              </Box>
              <DashboardSidebarMenuItem pathname={pathname} {...subMenusEndUser} />
              <Box>
                {reportSubMenuEndUser.map(
                  (reportItem) =>
                    reportsToShow.includes(reportItem.code) && (
                      <DashboardSidebarMenuItem pathname={pathname} {...reportItem} />
                    )
                )}
              </Box>
              {reportMenusOS.map(
                (reportItem) =>
                  reportsToShow.includes(reportItem.code) && (
                    <DashboardSidebarMenuItem pathname={pathname} {...reportItem} />
                  )
              )}
              {/* <Button
                color="inherit"
                fullWidth
                component={RouterLink}
                to="/report/cross-reference"
                sx={pathname.includes('/cross-reference') ? activeReportItems : inactiveReportItems}
              >
                <span
                  style={{
                    display: 'inlineBlock',
                    float: 'left',
                    marginRight: 5
                  }}
                >
                  {pathname.includes('/cross-reference') ? (
                    <img src="./static/icons/MenuIcon_Category_Active.gif" alt="" />
                  ) : (
                    <img src="./static/icons/MenuIcon_Category_Inctive.gif" alt="" />
                  )}
                </span>
                Cross Reference Report
              </Button> */}
            </AccordionDetails>
          )}
        </Accordion>
        <Accordion>
          <DashboardSidebarMenuHeading pathname={pathname} {...mainMenusDevices} />
          <AccordionDetails>
            {subMenusDevices.map((reportItem) => (
              <DashboardSidebarMenuItem pathname={pathname} {...reportItem} />
            ))}
          </AccordionDetails>
        </Accordion>
        {/* <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography color="inherit">
              <div style={{ height: 31, paddingTop: 5 }}>
                <span
                  style={{
                    display: 'inlineBlock',
                    float: 'left',
                    marginTop: -5
                  }}
                >
                  {pathname.includes('/users') ? (
                    <img src="./static/icons/MenuIcon_Users-Active.gif" alt="" />
                  ) : (
                    <img src="./static/icons/MenuIcon_Users-Inactive.gif" alt="" />
                  )}
                </span>
                <span
                  style={{
                    display: 'inlineBlock',
                    color: pathname.includes('/users') ? activeColor.color : 'inherit'
                  }}
                >
                  &nbsp; Users
                </span>
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Button
              color="inherit"
              fullWidth
              component={RouterLink}
              to="watchlist"
              sx={pathname.includes('/watchlist') ? activeMenu : null}
            >
              WatchList
            </Button>
            <Button
              color="inherit"
              fullWidth
              component={RouterLink}
              to="blacklist"
              sx={pathname.includes('/blacklist') ? activeMenu : null}
            >
              BlackList
            </Button>
          </AccordionDetails>
        </Accordion> */}
        {/* <Accordion>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            component={RouterLink}
            to="m365"
            sx={pathname.includes('/m365') ? activeMenu : null}
          >
            <Typography color="inherit">
              <div style={{ height: 31, paddingTop: 5 }}>
                <span
                  style={{
                    display: 'inlineBlock',
                    float: 'left',
                    marginTop: -5
                  }}
                >
                  {pathname.includes('/m365') ? (
                    <img src="./static/icons/MenuIcon_O365-Active.gif" alt="" />
                  ) : (
                    <img src="./static/icons/MenuIcon_O365-Inactive.gif" alt="" />
                  )}
                </span>
                <span
                  style={{
                    display: 'inlineBlock',
                    color: pathname.includes('/m365') ? activeColor.color : 'inherit'
                  }}
                >
                  &nbsp; M365
                </span>
              </div>
            </Typography>
          </AccordionSummary>
        </Accordion> */}
        {/* <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography color="inherit">
              <div style={{ height: 31, paddingTop: 5 }}>
                <span
                  style={{
                    display: 'inlineBlock',
                    float: 'left',
                    marginTop: -5
                  }}
                >
                  {pathname.includes('/contracts') ? (
                    <img src="./static/icons/MenuIcon_Contracts-Active.gif" alt="" />
                  ) : (
                    <img src="./static/icons/MenuIcon_Contracts-Inactive.gif" alt="" />
                  )}
                </span>
                <span
                  style={{
                    display: 'inlineBlock',
                    color: pathname.includes('/contracts') ? activeColor.color : 'inherit'
                  }}
                >
                  &nbsp; Contracts
                </span>
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Button
              color="inherit"
              fullWidth
              component={RouterLink}
              to="watchlist"
              sx={pathname.includes('/watchlist') ? activeMenu : null}
            >
              WatchList
            </Button>
          </AccordionDetails>
        </Accordion> */}
      </Box>
    </Box>
  );

  return (
    <>
      <RootStyle>
        <MHidden width="lgUp">
          <Drawer
            open={isOpenSidebar}
            onClose={onCloseSidebar}
            variant="persistent"
            PaperProps={{
              sx: {
                width: DRAWER_WIDTH,
                bgcolor: '#364150',
                mt: 5,
                border: 'none',
                zIndex: 100
              }
            }}
          >
            {renderContent}
          </Drawer>
        </MHidden>

        <MHidden width="lgDown">
          <Drawer
            open={isOpenSidebar}
            onClose={onCloseSidebar}
            variant="persistent"
            PaperProps={{
              sx: {
                width: DRAWER_WIDTH,
                bgcolor: '#364150',
                mt: 7,
                border: 'none'
              }
            }}
          >
            {renderContent}
          </Drawer>
        </MHidden>
      </RootStyle>
    </>
  );
}
