import React, { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import useStyles from '../../../../../../styles';
import Modal from '../../../../../../components/modals/CategorizationModal';
import { formatter } from '../../../../../../helpers';
import { ReportContext } from '../../../../../../context/ReportContext';
import {
  getCategorizationByProductLineId,
  getSubCategories,
  getLicenseTypes
} from '../../../../../../server';

const MODAL_TYPE = {
  categorization: 0,
  license: 1,
  msrp: 2
};

export default function Tab(props) {
  const classes = useStyles();
  const contextData = useContext(ReportContext);
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState(0);
  const [subCategories, setSubCategories] = useState();
  const [licenseTypes, setLicenseTypes] = useState();
  const [categorizationData, setCategorizationData] = useState();

  useEffect(() => {
    updateData();

    if (!subCategories || !licenseTypes) {
      const promises = [];
      promises.push(getSubCategories());
      promises.push(getLicenseTypes());
      Promise.all(promises).then(([categories, licenses]) => {
        setSubCategories(categories.data.subCategory);
        setLicenseTypes(licenses.data.modelList);
      });
    }
  }, [contextData]);

  const updateData = () => {
    const lineId = contextData?.level[0]?.allLeafChildren[0].data?.productLineId;
    if (lineId) {
      getCategorizationByProductLineId(lineId)
        .then((res) => {
          const data = res?.data?.reportList[0];
          if (data.data !== null || data.data !== undefined) {
            setCategorizationData(data);
          }
        })
        .catch(() => {});
    }
  };

  const openEditModal = (type) => {
    setModalType(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {categorizationData && (
        <Box sx={{ p: '20px' }}>
          <Box>
            <Typography variant="h6">Categorization</Typography>
            <Divider className={classes.thickDivider} variant="fullWidth" />
            <Box className={classes.categorizationLower}>
              <Typography>{`${categorizationData.categoryTypeName} > ${categorizationData.categoryName} > ${categorizationData.subCategoryName}`}</Typography>
              <Button
                onClick={() => openEditModal(MODAL_TYPE.categorization)}
                className={classes.tinyButton}
                size="small"
              >
                Edit
              </Button>
            </Box>
          </Box>
          <Box sx={{ mt: '40px' }}>
            <Typography variant="h6">License Type</Typography>
            <Divider className={classes.thickDivider} variant="fullWidth" />
            <Box className={classes.categorizationLower}>
              <Typography>{categorizationData.licenseType}</Typography>
              <Button
                onClick={() => openEditModal(MODAL_TYPE.license)}
                className={classes.tinyButton}
                size="small"
              >
                Edit
              </Button>
            </Box>
          </Box>
          <Box sx={{ mt: '40px' }}>
            <Typography variant="h6">MSRP</Typography>
            <Divider className={classes.thickDivider} variant="fullWidth" />
            <Box className={classes.categorizationLower}>
              <Typography>{formatter.format(categorizationData.msrp)}</Typography>
              <Button
                onClick={() => openEditModal(MODAL_TYPE.msrp)}
                className={classes.tinyButton}
                size="small"
              >
                Edit
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      <Modal
        modalData={getModalProps(modalType)}
        categorizationData={categorizationData}
        modalType={modalType}
        lists={{ subCategories, licenseTypes }}
        close={handleClose}
        update={updateData}
        open={open}
      />
    </>
  );
}

function getModalProps(type) {
  const props = {};
  switch (type) {
    case MODAL_TYPE.categorization:
      props.title = 'Categorization';
      props.selectLabel = 'Sub Category Type:';
      props.btnLabel = 'Save';
      break;
    case MODAL_TYPE.license:
      props.title = 'License Type';
      props.selectLabel = 'License Type:';
      props.btnLabel = 'Save';
      break;
    case MODAL_TYPE.msrp:
      props.title = 'MSRP';
      props.selectLabel = 'MSRP:';
      props.btnLabel = 'Save';
      break;
    default:
      break;
  }

  return props;
}
