import { useState, useContext } from 'react';
import clsx from 'clsx';
// material
import { Stack, Container, Card, Typography } from '@material-ui/core';
import Split from 'react-split';
// components
import Page from '../../../components/Page';
import ProductsGrid from './products';
import DevicesGrid from './devices';
import '../../../styles/css/Split.css';
import useStyles from '../../../styles';
import PublisherDashboard from '../../publishers/Dashboard';
import { ReportContext } from '../../../context/ReportContext';
import { ReportGridContext } from '../../../context/ReportGridContext';
import { publishers, softwares, REPORTS } from '../../../constants/reports';
import Overlay from '../../../components/Overlay';

// const publishers = [12, '12a', '12b'];
// const softwares = ['12c', '12d'];

export default function Reports(props) {
  const classes = useStyles();
  const contextReportData = useContext(ReportGridContext);
  const [data, setData] = useState({});
  const [level, setLevel] = useState([0]);
  const [gridApi, setGridApi] = useState();
  const [reportName, setReportName] = useState('');

  const exportToExcel = () => {
    if (gridApi) {
      gridApi.exportDataAsExcel({
        fileName: `${reportName}.xlsx`,
        skipRowGroups: true,
        skipColumnGroupHeaders: true,
        allColumns: true
      });
    }
  };

  const setReportParams = (name, api) => {
    setGridApi(api);
    setReportName(name);
  };

  return (
    <Page title="Reports | AssetLabs" sx={{ background: '#364150' }}>
      <Container className={classes.fullWidth}>
        <Split className={classes.split} minSize={250}>
          <div className={classes.cardContainer}>
            <ProductsGrid
              exportToExcel={exportToExcel}
              getLevel={(level) => setLevel(level)}
              getData={(data) => setData(data)}
              setReportParams={setReportParams}
            />
          </div>
          <div className={classes.cardContainer} style={{ position: 'relative' }}>
            {level[0] === 0 && publishers.includes(contextReportData.report) ? (
              <PublisherDashboard />
            ) : (
              <ReportContext.Provider value={{ level, data }}>
                <DevicesGrid />
              </ReportContext.Provider>
            )}
            {/* <Overlay timeout={800} in>
              Loading...
            </Overlay> */}
          </div>
        </Split>
      </Container>
    </Page>
  );
}
