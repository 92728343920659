import * as React from 'react';
import {
  Box,
  Container,
  Grid,
  styled,
  Paper,
  Stack,
  Button,
  Divider,
  InputBase,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
  OutlinedInput
} from '@material-ui/core';
// import index from '../../components/Admin/AnalyzeTab/MiniBtns';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Page from '../../components/Page';
import ModalSM from '../../components/modals/ModalSubManager';
import BtnLight from '../../components/buttons/ButtonLight';
import useStyles from '../../styles';

function createData(account, subcription, status, duration, start, expiry, poNo, type) {
  return { account, subcription, status, duration, poNo, start, expiry, type };
}

const rows = [
  createData(
    'test@gmail.com',
    'contium600',
    'active',
    '30days',
    '03-07-2020',
    '03-07-2020',
    '',
    'trail'
  ),
  createData(
    'test@gmail.com',
    'contium600',
    'active',
    '30days',
    '03-07-2020',
    '03-07-2020',
    '',
    'trail'
  )
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));
export default function Tabpanel(props) {
  const classes = useStyles();
  const [age, setAge] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <Page title="Manage Accounts & Subscriptions | AssetLabs" className={classes.bodyContainer}>
      <Container maxWidth="lg">
        <Box className={classes.widthFull}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ backgroundColor: '#364150', px: 2, pb: 1, ml: 2, mb: 2 }}
          >
            <Grid item xs={4} sx={{ color: '#fff' }}>
              <h3 className="heading-sub fontSlim">Manage account subscriptions</h3>
            </Grid>
            {/* <Grid item xs={8} >
              <Stack direction="row" spacing={2} >
                <Button variant="contained" size="small" className="primary-btn btn mlAuto">
                  Members
                </Button>
                <Button variant="contained" size="small" className="primary-btn btn" >
                  Account Information
                </Button> 
              </Stack>
            </Grid> */}
          </Grid>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mb: 3 }}>
            <Grid item xs={4}>
              <h3 className="fontSlim">Account Members</h3>
            </Grid>
            <Grid item xs={8}>
              <Stack direction="row" spacing={2} sx={{ float: 'right', pr: 2, pb: 1 }}>
                <BtnLight variant="contained" size="small" onClick={handleOpen}>
                  Add new subscription
                </BtnLight>
              </Stack>
            </Grid>
          </Grid>

          <Divider />

          <Grid
            container
            className="inverseHeading searchBar"
            spacing={2}
            sx={{ backgroundColor: '#364150', pr: 2, pb: 2 }}
          >
            <Grid item xs={12}>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 12 }}
                  placeholder="Search by member"
                  inputProps={{ 'aria-label': 'search by member' }}
                />
              </Paper>
            </Grid>
          </Grid>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Account</TableCell>
                  <TableCell align="right">Subcription</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Duration in days </TableCell>
                  <TableCell align="right">Start date</TableCell>
                  <TableCell align="right">Expiry date</TableCell>
                  <TableCell align="right">PO no.</TableCell>
                  <TableCell align="right">Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.account}
                    </TableCell>
                    <TableCell align="right">{row.subcription}</TableCell>
                    <TableCell align="right">{row.status}</TableCell>
                    <TableCell align="right">{row.duration}</TableCell>
                    <TableCell align="right">{row.start}</TableCell>
                    <TableCell align="right">{row.expiry}</TableCell>
                    <TableCell align="right">{row.po_no}</TableCell>
                    <TableCell align="right">{row.type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalSM handleClose={() => setOpen(false)} />
        </Modal>
      </Container>
    </Page>
  );
}
