import React, { useState, useEffect } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { getAccountAssignmentList } from '../../server';

export default function GridExample(props) {
  const [rowData, setRowData] = useState(null);
  useEffect(() => {
    getAccountAssignmentList().then((data) => updateData(data));
  }, []);

  const updateData = (data) => {
    if (data?.data) setRowData(data?.data?.accountAssignmentList?.detail);
  };

  return (
    <div
      style={{
        height: 'calc(100vh - 224px)',
        width: '100%'
      }}
      className="ag-theme-alpine"
    >
      <AgGridReact
        defaultColDef={{
          flex: 1,
          minWidth: 140,
          sortable: true,
          filter: true,
          floatingFilter: true
        }}
        headerHeight="25"
        animateRows="true"
        suppressContextMenu
        rowData={rowData}
      >
        <AgGridColumn field="userAssigned" headerName="Member" filter="agTextColumnFilter" />
        <AgGridColumn field="highestRole" headerName="Highest Role" filter="agTextColumnFilter" />
        <AgGridColumn
          field="emailValidated"
          headerName="Email Validated"
          filter="agTextColumnFilter"
        />
        <AgGridColumn field="lockOut" headerName="Locked Out" filter="agTextColumnFilter" />
        <AgGridColumn field="lastLogin" headerName="Last Login" filter="agTextColumnFilter" />
      </AgGridReact>
    </div>
  );
}
