import React, { useState, useEffect, useContext } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import { orderBy } from 'lodash';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../../styles/css/Grid.css';
import { ReportGridContext } from '../../context/ReportGridContext';
import ReportsGrid from './productsGridReports';
import SofwaresGrid from './productsGridSofwares';
import { getGridReport, getPreviousInventoryUpdateDate } from '../../server';
import { publishers, softwares, REPORTS } from '../../constants/reports';

// const publishers = [12, '12a', '12b'];
// const softwares = ['12c', '12d'];

// const REPORTS = {
//   PublishersAtoZ: 12,
//   PublishersByInstallCount: '12a',
//   PublishersByFinancialFootprint: '12b',
//   SoftwareByCategory: '12c',
//   SoftwareByLicenseType: '12d'
// };

const productFamilyComponent = (params) => {
  const element = document.createElement('span');
  const imageElement = document.createElement('img');
  const dataSpan = document.createElement('span');
  const data = document.createTextNode(params.value);
  imageElement.src = '/assets/publisher_grid.gif';

  dataSpan.appendChild(data);
  element.appendChild(imageElement);
  element.appendChild(dataSpan);
  imageElement.style.verticalAlign = 'text-bottom';
  dataSpan.style.marginLeft = '4px';
  return element;
};

export default function ProductGrid(props) {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [inverntoryDates, setInverntoryDates] = useState(null);
  const contextReportData = useContext(ReportGridContext);

  useEffect(() => {
    if (gridColumnApi) {
      if (contextReportData.report === REPORTS.PublishersByFinancialFootprint) {
        gridColumnApi.applyColumnState({
          state: [
            {
              colId: 'msrp',
              sort: 'desc'
            }
          ],
          defaultState: { sort: null }
        });
      }
      if (contextReportData.report === REPORTS.PublishersByInstallCount) {
        gridColumnApi.applyColumnState({
          state: [
            {
              colId: 'quantity',
              sort: 'desc'
            }
          ],
          defaultState: { sort: null }
        });
      }
    }
  }, [rowData]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    // we need to export to excel from parent comp
    props.setReportParams(contextReportData.name, params.api);

    const updateData = (response) => {
      if (response?.data?.reportList) {
        let reportData = response.data.reportList;
        if (contextReportData.report === REPORTS.SoftwareByCategory) {
          reportData = orderBy(reportData, ['subCategoryName'], ['asc']);
        }
        setRowData(reportData);
        props.getData(reportData);
      }
    };

    getGridReport(contextReportData, REPORTS).then((data) => {
      updateData(data, params);
    });

    getPreviousInventoryUpdateDate().then((res) => {
      if (res.status) {
        setInverntoryDates(res.data);
      }
    });
  };

  const onSelectionChanged = (params) => {
    props.getLevel([params.node]);
    gridApi.deselectAll();
    setTimeout(() => {
      params.node.setSelected(true);
    }, 30);
    // params.node.setSelected(true);
  };

  const gridProps = {
    report: contextReportData.report,
    onGridReady,
    onSelectionChanged,
    rowData,
    inverntoryDates
  };

  return (
    <div
      style={{ width: '100%', height: 'calc(100vh - 125px)' }}
      className="ag-theme-alpine product-grid"
    >
      {softwares.includes(contextReportData.report) ? (
        <SofwaresGrid {...gridProps} />
      ) : (
        <ReportsGrid {...gridProps} />
      )}
    </div>
  );
}
