import { REPORTS } from './reports';

export const mainMenusPublishers = {
  name: 'Software Publishers',
  route: '/publishers',
  code: null,
  img: 'MenuIcon_Software_Publishers-Inactive.gif',
  imgActive: 'MenuIcon_Software_Publishers-Active.gif',
  defaultExpanded: false
};

export const subMenusPublishers = [
  {
    name: 'Publishers A-Z',
    route: '/publishers/a-to-z',
    code: REPORTS.PublishersAtoZ,
    img: 'MenuIcon_Publishers-Inactive.gif',
    imgActive: 'MenuIcon_Publishers-Active.gif',
    defaultExpanded: false
  },
  {
    name: 'Publishers by Install Count',
    route: '/publishers/by-install-count',
    code: REPORTS.PublishersByInstallCount,
    img: 'MenuIcon_Publishers-Inactive.gif',
    imgActive: 'MenuIcon_Publishers-Active.gif',
    defaultExpanded: false
  },
  {
    name: 'Publisher by $ Cost Footprint',
    route: '/publishers/by-financial-footprint',
    code: REPORTS.PublishersByFinancialFootprint,
    img: 'MenuIcon_Publishers-Inactive.gif',
    imgActive: 'MenuIcon_Publishers-Active.gif',
    defaultExpanded: false
  }
];

export const mainMenusReports = {
  name: 'Reports',
  route: '/report',
  code: null,
  img: 'MenuIcon_Reports-Inactive.gif',
  imgActive: 'MenuIcon_Reports-Active.gif',
  defaultExpanded: false
};

export const subMenusLine = {
  name: 'Line of Business',
  route: '/report/line-of-business',
  code: REPORTS.LineOfBusiness,
  subMenu: true,
  img: '',
  imgActive: '',
  defaultExpanded: false
};

export const reportSubMenuLine = [
  {
    name: ' Microsoft Licensable',
    route: '/report/microsoft-licensable',
    code: REPORTS.MicrosoftAuditable,
    img: '',
    imgActive: '',
    defaultExpanded: false
  },
  {
    name: ' Business Licensable',
    route: '/report/business-app-nonAuditable',
    code: REPORTS.BusinessApp_NonAuditable,
    img: '',
    imgActive: '',
    defaultExpanded: false
  },
  {
    name: 'All Licensable',
    route: '/report/all-auditable',
    code: REPORTS.AllAuditable,
    img: '',
    imgActive: '',
    defaultExpanded: false
  },
  {
    name: 'Adobe Licensable',
    route: '/report/adobe-auditable',
    code: REPORTS.AdobeAuditable,
    img: '',
    imgActive: '',
    defaultExpanded: false
  }
];

export const subMenusEndUser = {
  name: 'End User Distractions',
  route: '/report/end-user-distractions',
  code: REPORTS.EndUserDistraction,
  subMenu: true,
  img: '',
  imgActive: '',
  defaultExpanded: false
};

export const reportSubMenuEndUser = [
  {
    name: ' Peer-To-Peer',
    route: '/report/peer-top-peer',
    code: REPORTS.PeerToPeer,
    img: '',
    imgActive: '',
    defaultExpanded: false
  },
  {
    name: 'Games',
    route: '/report/games',
    code: REPORTS.Games,
    img: '',
    imgActive: '',
    defaultExpanded: false
  },
  {
    name: 'Malware',
    route: '/report/malware',
    code: REPORTS.Malware,
    img: '',
    imgActive: '',
    defaultExpanded: false
  }
];

export const reportMenusOS = [
  {
    name: ' Operating Systems',
    route: '/report/operating-systems',
    code: REPORTS.OperatingSystems,
    img: 'MenuIcon_OS_Inactive.gif',
    imgActive: 'MenuIcon_OS_Active.gif',
    defaultExpanded: false
  },
  {
    name: 'Software by Category',
    route: '/report/by-category',
    code: REPORTS.PublishersAtoZ,
    img: 'MenuIcon_Category_Inctive.gif',
    imgActive: 'MenuIcon_Category_Active.gif',
    defaultExpanded: false
  },
  {
    name: 'Software by License Type',
    route: '/report/by-license-type',
    code: REPORTS.PublishersAtoZ,
    img: 'MenuIcon_LicenseType-Inactive.gif',
    imgActive: 'MenuIcon_LicenseType-Active.gif',
    defaultExpanded: false
  }
];

export const mainMenusDevices = {
  name: 'Devices',
  route: '/devices',
  code: null,
  img: 'MenuIcon_Devices-Inactive.gif',
  imgActive: 'MenuIcon_Devices-Active.gif',
  defaultExpanded: false
};

export const subMenusDevices = [
  {
    name: 'Clients',
    route: '/devices/clients',
    code: null,
    img: '',
    imgActive: '',
    defaultExpanded: false
  },
  {
    name: 'Servers',
    route: '/devices/servers',
    code: null,
    img: '',
    imgActive: '',
    defaultExpanded: false
  },
  {
    name: ' All Devices',
    route: '/devices/all',
    code: null,
    img: '',
    imgActive: '',
    defaultExpanded: false
  }
];
