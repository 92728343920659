import React, { Component } from 'react';
import { AgChartsReact } from 'ag-charts-react';

const data = [
  { title: 'Component', frequency: 1159000 },
  { title: 'Free', frequency: 97000 },
  { title: 'OEM', frequency: 456000 },
  { title: 'Single License', frequency: 168000 },
  { title: 'Developer', frequency: 1215000 },
  { title: 'Beta', frequency: 123000 },
  { title: 'Server', frequency: 174000 },
  { title: 'Volume License', frequency: 2274000 }
];

export default class PieChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        // data: this.props.data,
        autoSize: true,
        title: {
          text: '100% unique titles categorized',
          fontSize: 18
        },
        subtitle: { text: '720 out of 720 unique titles categorized' },
        series: [
          {
            data,
            type: 'pie',
            labelKey: 'title',
            angleKey: 'frequency',
            label: { minAngle: 0 },
            callout: { strokeWidth: 2 },
            fills: [
              '#E1ECF9',
              '#609CE1',
              '#2369B8',
              '#133863',
              '#091D34',
              '#eb4c4b',
              '#6ab04c',
              '#7ed6df'
            ],
            strokes: [
              '#E1ECF9',
              '#609CE1',
              '#2369B8',
              '#133863',
              '#091D34',
              '#a43535',
              '#4a7b35',
              '#58969c'
            ]
          }
        ],
        legend: { enabled: false }
      }
    };
  }

  componentDidMount() {}

  render() {
    return <AgChartsReact options={this.state.options} />;
  }
}
