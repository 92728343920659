import React, { useEffect, useState, useContext } from 'react';
import Chart from 'react-apexcharts';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ReportContext } from '../../context/ReportContext';
import { getProductLineVersionStatistics } from '../../server';

export default function VerticalBar() {
  const contextData = useContext(ReportContext);
  const [barWidth, setBarWidth] = useState([]);
  const [arrXaxis, setArrXaxis] = useState([]);
  const [arrYaxis, setArrYaxis] = useState([]);
  const data = {
    series: [
      {
        data: arrXaxis
      }
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        },
        dropShadow: {
          enabled: true,
          top: 2,
          left: 1,
          blur: 2,
          color: '#acacac',
          opacity: 0.75
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: false,
          columnWidth: barWidth <= 10 ? barWidth * 10 : barWidth * 5,
          dataLabels: {
            position: 'top'
          }
        }
      },
      stroke: {
        show: true,
        curve: 'straight',
        lineCap: 'butt',
        colors: ['#fff'],
        width: 2,
        dashArray: 0
      },
      dataLabels: {
        enabled: true,
        offsetX: 0,
        offsetY: -25,
        style: {
          colors: ['#000'],
          fontWeight: 600
        },
        dropShadow: {
          enabled: true,
          top: 0,
          left: 0,
          blur: 2,
          color: '#acacac',
          opacity: 0.25
        }
      },
      xaxis: {
        categories: arrYaxis
      },
      yaxis: {
        show: false
      }
    }
  };

  useEffect(() => {
    getProductLineVersionStatistics(
      contextData.level[0].allLeafChildren[0].data.productLineId
    ).then((data) => {
      updateData(data);
    });
  }, [contextData]);

  const updateData = (data) => {
    if (data.data !== null || data.data !== undefined) {
      const xAxisArr = [];
      const yAxisArr = [];
      for (let i = 0; i < data.data.reportList.length; i += 1) {
        xAxisArr.push(data.data.reportList[i].model);
        yAxisArr.push(data.data.reportList[i].quantity);
      }
      setBarWidth(data.data.reportList.length);
      setArrXaxis(yAxisArr);
      setArrYaxis(xAxisArr);
    }
  };

  return (
    <>
      <div id="chart">
        <Chart options={data.options} series={data.series} type="bar" height={350} />
      </div>
    </>
  );
}
