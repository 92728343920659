import React, { useState, useEffect } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { getProvidersList } from '../../server';

export default function GridExample(props) {
  const [rowData, setRowData] = useState(null);
  useEffect(() => {
    getProvidersList().then((data) => updateData(data));
  }, []);

  const updateData = (data) => {
    if (data?.data) setRowData(data.data);
  };

  return (
    <div
      style={{
        // height: props.fullScreen ? 'calc(100vh - 180px)' : 'calc(100vh - 245px)',
        height: 'calc(100vh - 100px)',
        // height: 430,
        width: '100%'
      }}
      className="ag-theme-alpine"
    >
      <AgGridReact
        defaultColDef={{
          flex: 1,
          minWidth: 140,
          sortable: true,
          filter: true
          // floatingFilter: true
        }}
        headerHeight="25"
        animateRows="true"
        // rowClassRules={{
        //   selectedDeviceRow: (params) => params.data.softwareDeviceId === props.arpId
        // }}
        suppressContextMenu
        rowData={rowData}
        // statusBar={{
        //   statusPanels: [
        //     {
        //       statusPanel: 'agTotalAndFilteredRowCountComponent',
        //       align: 'left'
        //     }
        //   ]
        // }}
      >
        <AgGridColumn field="providerName" headerName="Provider" filter="agTextColumnFilter" />
        <AgGridColumn
          field="providerCode"
          headerName="Code"
          filter="agTextColumnFilter"
          minWidth={100}
        />
        <AgGridColumn
          field="statusId"
          headerName="Status"
          filter="agTextColumnFilter"
          minWidth={100}
        />
        <AgGridColumn
          field="currencyType"
          headerName="Xero Currency Type"
          filter="agSetColumnFilter"
        />
        <AgGridColumn
          field="xeroAccountCode"
          headerName="Xero Currency Code"
          filter="agSetColumnFilter"
        />
        <AgGridColumn
          field="xeroContactName"
          headerName="Xero Contact"
          filter="agSetColumnFilter"
        />
        <AgGridColumn field="updateDate" headerName="Update" filter="agSetColumnFilter" />
        <AgGridColumn
          field="updateBy"
          headerName="UpdateBy"
          filter="agSetColumnFilter"
          minWidth={100}
        />
        <AgGridColumn
          field="providerId"
          headerName="Provider Id"
          filter="agSetColumnFilter"
          minWidth={100}
        />
      </AgGridReact>
    </div>
  );
}
