import * as React from 'react';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  styled,
  Paper,
  Stack,
  Button,
  Divider,
  InputBase,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import Page from '../../components/Page';
import BtnLight from '../../components/buttons/ButtonLight';
import useStyles from '../../styles';

function createData(members, roles, emailValidate, lockedOut, lastLogin, email) {
  return { members, roles, emailValidate, lockedOut, lastLogin, email };
}

const rows = [
  createData('test@gmail.com', 'guest', 'yes', 'no', 'date', 'email'),
  createData('test2@gmail.com', 'admin', 'yes', 'no', 'date', 'email')
];

const ItemPaper = styled(Paper)(({ theme }) => ({
  padding: 0,
  borderRadius: 0,
  display: 'flex',
  alignItems: 'center',
  width: '100%'
}));
export default function Tabpanel(props) {
  const classes = useStyles();
  const [age, setAge] = React.useState('');
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <Page title="Manage Accounts | AssetLabs" className={classes.bodyContainer}>
      <Container maxWidth="lg">
        <Box className={classes.widthFull}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className={classes.gridStyle}
          >
            <Grid item xs={4} className={classes.fontWhite}>
              <h3 className={classes.fontSlim}>Manage Account</h3>
            </Grid>
            <Grid item xs={8}>
              <Stack direction="row" spacing={2} className={classes.headingStyle}>
                <BtnLight variant="contained" size="small">
                  Members
                </BtnLight>
                <BtnLight variant="contained" size="small">
                  Account Information
                </BtnLight>
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ mt: 1, mb: 4 }}
          >
            <Grid item xs={4}>
              <h3 className="heading-sub fontSlim">Account Members</h3>
            </Grid>
            <Grid item xs={8}>
              <Stack spacing={2} sx={{ float: 'right', paddingRight: 2 }}>
                <BtnLight variant="contained" size="small">
                  Add New Member
                </BtnLight>
              </Stack>
            </Grid>
          </Grid>

          <Divider />

          <Grid
            container
            spacing={2}
            style={{ paddingTop: 0 }}
            sx={{ backgroundColor: '#364150', paddingBottom: 2 }}
          >
            <Grid item xs={2}>
              <ItemPaper component="form">
                <InputBase
                  sx={{ ml: 1, flex: 12 }}
                  placeholder="Search by member"
                  inputProps={{ 'aria-label': 'search by member' }}
                  className={classes.removePadding}
                />
              </ItemPaper>
            </Grid>
            <Grid item xs={2}>
              <ItemPaper component="form">
                <FormControl fullWidth>
                  {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                  <Select
                    // labelId="demo-simple-select-label"
                    // id="demo-simple-select"
                    value={age}
                    label="Age"
                    onChange={handleChange}
                    style={{ height: 30 }}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </ItemPaper>
            </Grid>
            <Grid item xs={2}>
              <ItemPaper component="form">
                <InputBase
                  sx={{ ml: 1, flex: 12 }}
                  placeholder="Email validation"
                  inputProps={{ 'aria-label': 'search by member' }}
                  className={classes.removePadding}
                />
              </ItemPaper>
            </Grid>
            <Grid item xs={2}>
              <ItemPaper component="form">
                <InputBase
                  sx={{ ml: 1, flex: 12 }}
                  placeholder="Locked Out"
                  inputProps={{ 'aria-label': 'search by member' }}
                  className={classes.removePadding}
                />
              </ItemPaper>
            </Grid>
            <Grid item xs={2}>
              <ItemPaper component="form">
                <InputBase
                  sx={{ ml: 1, flex: 12 }}
                  placeholder="Last Login"
                  inputProps={{ 'aria-label': 'search by member' }}
                  className={classes.removePadding}
                />
              </ItemPaper>
            </Grid>
            <Grid item xs={2}>
              <Stack direction="row" spacing={2} sx={{ paddingRight: 2 }}>
                <BtnLight variant="contained" size="small" className={classes.widthFull}>
                  Filter
                </BtnLight>
              </Stack>
            </Grid>
          </Grid>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Members</TableCell>
                  <TableCell align="right">Highest Roles</TableCell>
                  <TableCell align="right">Email Validation</TableCell>
                  <TableCell align="right">Locked Out </TableCell>
                  <TableCell align="right">Last Login</TableCell>
                  <TableCell align="right">Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.members}
                    </TableCell>
                    <TableCell align="right">{row.roles}</TableCell>
                    <TableCell align="right">{row.email_validate}</TableCell>
                    <TableCell align="right">{row.locked_out}</TableCell>
                    <TableCell align="right">{row.last_login}</TableCell>
                    <TableCell align="right">{row.email}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
    </Page>
  );
}
