function styles(theme) {
  return {
    tab: {
      borderRadius: '10px 10px 0 0',
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(2.25),
      color: '#000000',
      fontWeight: 400,
      fontSize: 14,
      minHeight: 15,
      maxHeight: 30,
      lineHeight: 1,
      background: '#9CB9D0',
      '&.Mui-selected': {
        paddingTop: theme.spacing(1.75),
        boxShadow: '2px -7px 8px -7px rgba(99, 99, 99, 0.3)',
        background: '#F8F8F8',
        color: '#000000'
      }
    },
    accountTab: {
      marginTop: theme.spacing(2.25),
      marginLeft: theme.spacing(1),
      marginRight: 0.75,
      marginBottom: 0,
      borderRadius: '5px 5px 0 0',
      color: '#000000',
      fontWeight: 400,
      fontSize: 14,
      minHeight: 10,
      maxHeight: 30,
      lineHeight: 1,
      background: '#9CB9D0',
      boxShadow: '2px -7px 8px -7px rgba(99, 99, 99, 0.3)',
      '&.Mui-selected': {
        background: '#F8F8F8'
      }
    },
    dialogTab: {
      borderRadius: '10px 10px 0 0',
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1.6),
      color: '#ffffff',
      fontWeight: 400,
      fontSize: 11,
      minHeight: 20,
      lineHeight: 1,
      background: '#808080',
      '&.Mui-selected': {
        boxShadow: '2px -7px 8px -7px rgba(99, 99, 99, 0.3)',
        background: '#333'
      }
    },
    nestedTabPanel: {
      borderBottom: '1px solid #eee',
      minHeight: 0
    },
    nestedTab: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      color: '#000000',
      fontWeight: 400,
      fontSize: 11,
      minHeight: 20,
      lineHeight: 1,
      '&.Mui-selected': {
        color: '#000000',
        borderBottom: '2px solid #9CB9D0'
      }
    },
    tabsContainer: {
      width: '100%',
      typography: 'body1',
      '& .MuiTabs-indicator': {
        display: 'none'
      }
    },
    removeIndicator: {
      '& .MuiTabs-indicator': {
        display: 'none'
      }
    },
    tabBody: {
      border: '2px solid #9CB9D0',
      padding: 5,
      overflowY: 'scroll'
    },
    tabsPanel: {
      borderBottom: 1,
      background: '#EDEEF0',
      boxShadow: 'inset 0 20px 20px -20px #ccc',
      borderColor: 'divider'
    }
  };
}

export default styles;
