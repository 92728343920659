import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import Overlay from '../../../components/Overlay';
import { numberformatter } from '../../../helpers';

export default function VerticalBar(props) {
  const [arrXaxis, setArrXaxis] = useState([]);
  const [arrYaxis, setArrYaxis] = useState([]);
  const chartData = {
    series: [
      {
        data: arrXaxis
      }
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        },
        dropShadow: {
          enabled: true,
          top: 2,
          left: 1,
          blur: 2,
          color: '#acacac',
          opacity: 0.75
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          type: 'vertical',
          shadeIntensity: 0.5,
          gradientFromColors: ['#5885AF'],
          gradientToColors: ['#41729F'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: []
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: false,
          columnWidth: '50%',
          dataLabels: {
            position: 'top'
          }
        }
      },
      stroke: {
        show: true,
        curve: 'straight',
        lineCap: 'butt',
        colors: ['#fff'],
        width: 2,
        dashArray: 0
      },
      dataLabels: {
        enabled: true,
        offsetX: 0,
        offsetY: -25,
        style: {
          colors: ['#000'],
          fontWeight: 600
        },
        yaxis: {
          show: true,
          labels: {
            formatter: (value) => numberformatter(value, true)
          }
        },
        dropShadow: {
          enabled: true,
          top: 0,
          left: 0,
          blur: 2,
          color: '#acacac',
          opacity: 0.25
        }
      },
      xaxis: {
        categories: arrYaxis
      },
      yaxis: {
        show: false,
        labels: {
          formatter: (value) => numberformatter(value, true)
        }
      }
    }
  };

  useEffect(() => {
    if (props?.data?.length) {
      updateData(props.data);
    }
  }, [props]);

  const updateData = (data) => {
    const xAxisArr = [];
    const yAxisArr = [];
    for (let i = 0; i < data.length; i += 1) {
      xAxisArr.push(data[i].publisher);
      yAxisArr.push(parseFloat(data[i].cost / 100000).toFixed(2));
    }
    setArrXaxis(yAxisArr);
    setArrYaxis(xAxisArr);
  };

  return (
    <>
      {props?.data !== null ? (
        <Chart options={chartData.options} series={chartData.series} type="bar" height={350} />
      ) : (
        <Overlay>Loading...</Overlay>
      )}
    </>
  );
}
