function styles(theme) {
  return {
    specialRow: {
      background: '#434F5E',
      color: '#F4F4F4'
    },
    negativeCol: {
      color: 'red'
    },
    positiveCol: {
      color: 'green'
    },
    dashboardCard: {
      boxShadow: '0 0 6px 2px rgb(145 158 171 / 35%)',
      borderRadius: '5px'
    },
    redCell: { background: '#945855' },
    greenCell: { background: '#267F5C' },
    yellowCell: { background: '#BBB366' }
  };
}

export default styles;
