import { useEffect, useState } from 'react';
import { Stack, Container, Typography, Button } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import clsx from 'clsx';
// components
import Page from '../../components/Page';
import UploadTable from './components/UploadTable';
import BtnDanger from '../../components/buttons/ButtonDanger';
import BtnLight from '../../components/buttons/ButtonLight';
import Overlay from './components/Overlay';
import { GetUploadedFiles, UploadFiles } from '../../server';
import useStyles from '../../styles';

// ----------------------------------------------------------------------

export default function Upload() {
  const [filesList, setFilesList] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedFilesList, setSelectedFilesList] = useState([]);

  useEffect(() => {
    GetUploadedFiles()
      .then((res) => {
        if (res?.data?.filesList) {
          setFilesList(res.data.filesList);
        }
      })
      .catch(() => {});
  }, []);

  const classes = useStyles();
  const handleFileSelected = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFilesList([...selectedFilesList, ...files]);
  };

  const uploadFilesSelected = (e) => {
    if (selectedFilesList?.length) {
      setLoading(true);
      UploadFiles(selectedFilesList)
        .then((res) => {
          if (res && res.status) {
            setSelectedFilesList([]);
            GetUploadedFiles()
              .then((res) => {
                if (res?.data?.filesList) {
                  setFilesList(res.data.filesList);
                }
              })
              .catch(() => {});
          }
        })
        .catch(() => {})
        .finally(() => setLoading(false));
    }
  };

  const removeFile = (fileName) => {
    const files = selectedFilesList.filter((x) => x.name !== fileName);
    setSelectedFilesList(files);
  };

  const removeAllFiles = () => {
    setSelectedFilesList([]);
  };

  return (
    <Page
      title="Upload | AssetLabs"
      className={classes.bodyContainer}
      style={{ position: 'relative' }}
    >
      <Container className={classes.fullWidth} sx={{ pt: 1, px: 1.5 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Upload data file
          </Typography>
        </Stack>

        <Typography variant="h6" component="p" sx={{ fontWeight: 400 }}>
          Data file must be less than 2gb. Contact support@assetlabs.com for files greater than the
          upload limit.
        </Typography>

        <Stack direction="row" justifyContent="right" sx={{ mb: 2 }}>
          {selectedFilesList?.length > 0 && (
            <BtnDanger onClick={removeAllFiles} sx={{ mt: 1 }}>
              remove all
            </BtnDanger>
          )}
          <BtnLight
            disabled={selectedFilesList?.length === 0}
            onClick={uploadFilesSelected}
            sx={{ mt: 1 }}
          >
            upload all
          </BtnLight>
          <label htmlFor="contained-button-file">
            <input
              style={{ display: 'none' }}
              onChange={handleFileSelected}
              accept="file/*"
              id="contained-button-file"
              multiple
              type="file"
            />
            <Button
              variant="contained"
              component="span"
              className={classes.btnLight}
              sx={{ mt: 1 }}
            >
              Choose Files
            </Button>
          </label>
        </Stack>
        <UploadTable {...{ filesList, selectedFilesList, removeFile }} />
      </Container>
      {loading && <Overlay>Uploading files... Please wait</Overlay>}
    </Page>
  );
}
