import React, { useEffect, useState, useContext } from 'react';
import Chart from 'react-apexcharts';
import Overlay from '../../../components/Overlay';
import { numberformatter } from '../../../helpers';

export default function VerticalBar(props) {
  const [arrData, setArrData] = useState([]);

  const chartData = {
    series: [
      {
        data: arrData
      }
    ],
    options: {
      legend: {
        show: false
      },
      chart: {
        height: 350,
        type: 'treemap',
        toolbar: {
          show: false
        }
      },
      title: {
        text: '',
        align: 'center'
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px'
        },
        formatter: (text, op) => [text, op.value],
        offsetY: -4
      },
      colors: [
        '#4FADC4',
        '#C35352',
        '#9ABA63',
        '#8268A0',
        '#4FADC4',
        '#C35352',
        '#9ABA63',
        '#8268A0',
        '#4FADC4',
        '#C35352',
        '#9ABA63',
        '#8268A0'
      ],
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false
        }
      }
    }
  };

  useEffect(() => {
    if (props?.data?.length) {
      updateData(props.data);
    }
  }, [props]);

  const updateData = (data) => {
    const axisArr = [];
    for (let i = 0; i < data.length; i += 1) {
      axisArr.push({
        x: data[i].publisherName,
        y: data[i].qauntity
      });
    }
    setArrData(axisArr);
  };

  return (
    <>
      {props?.data ? (
        <Chart options={chartData.options} series={chartData.series} type="treemap" height={350} />
      ) : (
        <Overlay>Loading...</Overlay>
      )}
    </>
  );
}
