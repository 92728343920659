import React, { forwardRef, Fragment, useImperativeHandle, useRef, useState } from 'react';
import useStyles from '../../../styles';

export default forwardRef((props, ref) => {
  const classes = useStyles();
  const inputRef = useRef(null);

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    const obj = {
      onParentModelChanged: (parentModel) => {
        // When the filter is empty we will receive a null value here
        if (parentModel) {
          inputRef.current.value = parentModel;
        }
      }
    };
    return obj;
  });

  const onInputBoxChanged = (input) => {
    const { value } = input.target;
    if (value === '') {
      // Remove the filter
      props.parentFilterInstance((instance) => {
        instance.getFrameworkComponentInstance().myMethodForTakingValueFromFloatingFilter(null);
      });
      return;
    }

    props.parentFilterInstance((instance) => {
      instance.getFrameworkComponentInstance().myMethodForTakingValueFromFloatingFilter(value);
    });
  };

  return (
    <>
      <input
        ref={inputRef}
        className={classes.floatingFilterInput}
        // style={{ width: '30px' }}
        type="text"
        min="0"
        onInput={onInputBoxChanged}
      />
    </>
  );
});
