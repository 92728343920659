import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from '../../styles';
import BtnLight from '../buttons/ButtonLight';

const DialogActions = withStyles({
  root: {
    backgroundColor: '#364150',
    color: '#F4F6F8'
  }
})(MuiDialogActions);

const DialogContent = withStyles({
  root: {
    backgroundColor: '#364150',
    color: '#F4F6F8'
  }
})(MuiDialogContent);

const DialogContentText = withStyles({
  root: {
    backgroundColor: '#364150',
    color: '#F4F6F8'
  }
})(MuiDialogContentText);

const DialogTitle = withStyles({
  root: {
    backgroundColor: '#364150',
    color: '#F4F6F8'
  }
})(MuiDialogTitle);

export default function DialogBox() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <Box>
      <BtnLight onClick={handleClickOpen}>Open dialog</BtnLight>
      <Dialog open={open} onClose={handleClose} className={classes.root}>
        <DialogTitle>
          Dialog
          <IconButton aria-label="close" onClick={handleClose} className={classes.iconStyle}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here.
          </DialogContentText>
          <TextField
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            color="secondary"
            className={classes.textInput}
          />
        </DialogContent>
        <DialogActions>
          <BtnLight variant="contained" size="small" onClick={handleClose}>
            Save
          </BtnLight>
          <BtnLight variant="contained" size="small" onClick={handleClose}>
            Close
          </BtnLight>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
