function styles(theme) {
  return {
    root: {
      '& .MuiDialog-paper': {
        borderRadius: 0
      }
    },
    textInput: {
      '& > label, & > div > input': {
        color: '#fff'
      }
    },
    iconStyle: {
      position: 'absolute',
      right: 8,
      top: 8,
      color: '#919EAB'
    },
    dialogClose: {
      position: 'absolute',
      right: 8,
      top: 8,
      color: '#FFFFFF'
    }
  };
}

export default styles;
