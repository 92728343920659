import React, { useEffect, useState, useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ReactApexChart from 'react-apexcharts';
import 'chartjs-adapter-date-fns';
import useStyles from '../../styles';
import { ReportContext } from '../../context/ReportContext';
import { getDeviceAgeAnalysis } from '../../server';

const checkLevel = ['commonName', 'licenseType', 'subCategoryName', 'productLine', 'productFamily'];

const BodyCell = styled('td')({
  padding: '0.25rem',
  textAlign: 'left',
  border: '1px solid #000'
});

const HeadCell = styled('th')({
  padding: '0.25rem',
  textAlign: 'center',
  border: '1px solid #000',
  data: [],
  labels: [],
  background: '#dbebff',
  // color: '#F4F4F4',
  fontWeight: 400
});

const Table = styled('table')({
  fontSize: 14,
  borderCollapse: 'collapse',
  width: '100%'
});

const Row = styled('tr')({
  data: [],
  labels: [],
  background: '#D3D3D2'
});

export default function VerticalBar() {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [labels, setlabels] = useState([]);
  const [oneSet, setOneSet] = useState([]);
  const [twoSet, setTwoSet] = useState([]);
  const [threeSet, setThreeSet] = useState([]);
  const [fourSet, setFourSet] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const contextData = useContext(ReportContext);
  const array = [];

  useEffect(() => {
    if (checkLevel.includes(contextData?.level[0].field)) {
      for (let i = 0; i < contextData?.level[0]?.allLeafChildren.length; i += 1) {
        array.push(contextData?.level[0]?.allLeafChildren[i].data.productId);
      }
    }
    if (contextData?.level[0]?.parent?.field === 'productLine') {
      array.push(contextData?.level[0]?.data.productId);
    }
    getDeviceAgeAnalysis(array).then((res) => updateData(res.data));
  }, [contextData]);

  const updateData = (data) => {
    const arrXaxis = [];
    const categoryJanToMar = [];
    const categoryAprToJun = [];
    const categoryJulToSep = [];
    const categoryOctToDec = [];

    data?.graphList.map((x) => {
      arrXaxis.push(x.key);
      return true;
    });

    data?.graphList.map((x) => {
      categoryJanToMar.push(x.month.categoryJanToMar.val);
      categoryAprToJun.push(x.month.categoryAprToJun.val);
      categoryJulToSep.push(x.month.categoryJulToSep.val);
      categoryOctToDec.push(x.month.categoryOctToDec.val);
      return true;
    });

    const datasetArray = [];
    const tableObject = {};
    const DATA_DEFAULTS = {
      labels: [],
      barPercentage: 0.5,
      barThickness: 6,
      maxBarThickness: 8
    };

    const dataset02 = {
      ...DATA_DEFAULTS,
      label: '0-2 years',
      data: [],
      backgroundColor: '#133863'
    };
    const dataset24 = {
      ...DATA_DEFAULTS,
      label: '2-4 years',
      data: [],
      backgroundColor: '#2369B8'
    };
    const dataset46 = {
      ...DATA_DEFAULTS,
      label: '4-6 years',
      data: [],
      backgroundColor: '#609CE1'
    };
    const dataset6 = {
      ...DATA_DEFAULTS,
      label: '>6 years',
      data: [],
      backgroundColor: '#E1ECF9'
    };

    for (let i = 0; i < data.graphList.length; i += 1) {
      const item = data.graphList[i];
      switch (item.year) {
        case '0-2':
          dataset02.data.push(item.val);
          break;
        case '2-4':
          dataset24.data.push(item.val);
          break;
        case '4-6':
          dataset46.data.push(item.val);
          break;
        case '>6':
          dataset6.data.push(item.val);
          break;
        default:
          break;
      }

      // arrXaxis.push(item.key);
    }

    if (dataset02.data.length > 0) datasetArray.push(dataset02);
    if (dataset24.data.length > 0) datasetArray.push(dataset24);
    if (dataset46.data.length > 0) datasetArray.push(dataset46);
    if (dataset6.data.length > 0) datasetArray.push(dataset6);
    // console.log(arrXaxis);
    // console.log(datasetArray);
    setlabels(arrXaxis);
    setOneSet(categoryJanToMar);
    setTwoSet(categoryAprToJun);
    setThreeSet(categoryJulToSep);
    setFourSet(categoryOctToDec);
    setDatasets(datasetArray);

    for (let i = 0; i < data.tableList.length; i += 1) {
      const { result } = data.tableList[i];
      tableObject[result.key] = result;
    }
    setTableData(tableObject);
  };

  const data = {
    labels,
    datasets
  };

  const seriesNew = [
    {
      name: 'Jan to Mar',
      data: oneSet
    },
    { name: 'Apr to Jun', data: twoSet },
    { name: 'Jul to Sep', data: threeSet },
    { name: 'Oct to Dec', data: fourSet }
  ];

  const optionsNew = {
    chart: {
      type: 'bar',
      height: 430
    },
    plotOptions: {
      bar: {
        // horizontal: true,
        dataLabels: {
          position: 'top'
        }
      }
    },
    legend: {
      // show: true,
      customLegendItems: ['Jan to Mar', 'Apr to Jun', 'Jul to Sep', 'Oct to Dec']
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff']
    },
    tooltip: {
      shared: true,
      intersect: false
    },
    xaxis: {
      categories: labels
    }
  };

  // const series = datasets;

  // const options = {
  //   chart: {
  //     height: 350,
  //     type: 'line',
  //     zoom: {
  //       enabled: false
  //     }
  //   },
  //   dataLabels: {
  //     enabled: false
  //   },
  //   stroke: {
  //     curve: 'straight',
  //     width: 2
  //   },
  //   title: {
  //     text: 'Product Trends by Month',
  //     align: 'left'
  //   },
  //   grid: {
  //     row: {
  //       colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
  //       opacity: 0.5
  //     }
  //   },
  //   xaxis: {
  //     categories: labels
  //   }
  // };

  return (
    <>
      {data.datasets && data.datasets.length && (
        <ReactApexChart options={optionsNew} series={seriesNew} type="bar" height={350} />

        // <Bar plugins={[ChartDataLabels]} data={data} options={options} />
      )}
      <Box className={classes.dFlex} sx={{ mt: 2 }}>
        <Box className={classes.descBox} sx={{ width: '50%', mr: 2 }}>
          <Box>
            <Typography sx={{ fontWeight: 500 }}>Description:</Typography>
          </Box>
          <Box sx={{ my: 1 }}>
            <Typography sx={{ fontSize: 14 }}>
              Identify age clusters across your device population.
            </Typography>
          </Box>
          {/* <Box>
            <Typography sx={{ fontSize: 14 }}>footer</Typography>
          </Box> */}
        </Box>
        <Box className={classes.detailsBox} sx={{ width: '50%' }}>
          <Box>
            <Typography sx={{ fontWeight: 500 }}>Additional Details:</Typography>
          </Box>
          <Box sx={{ my: 1 }}>
            <Table sx={{ width: '100%', margin: '0 auto' }}>
              <thead>
                <Row sx={{ backgroundColor: '#dbebff' }}>
                  <HeadCell>Age (yrs)</HeadCell>
                  <HeadCell>Population</HeadCell>
                  <HeadCell>% of total</HeadCell>
                </Row>
              </thead>
              <tbody>
                {tableData && (
                  <>
                    <Row sx={{ backgroundColor: '#133863', color: 'white' }}>
                      <BodyCell sx={{ textAlign: 'right' }}>0-2</BodyCell>
                      <BodyCell sx={{ textAlign: 'right' }}>{tableData['0-2']?.val || 0}</BodyCell>
                      <BodyCell sx={{ textAlign: 'right' }}>
                        {tableData['0-2']?.percentage || 0}%
                      </BodyCell>
                    </Row>
                    <Row sx={{ backgroundColor: '#2369B8', color: 'white' }}>
                      <BodyCell sx={{ textAlign: 'right' }}>2-4</BodyCell>
                      <BodyCell sx={{ textAlign: 'right' }}>{tableData['2-4']?.val || 0}</BodyCell>
                      <BodyCell sx={{ textAlign: 'right' }}>
                        {tableData['2-4']?.percentage || 0}%
                      </BodyCell>
                    </Row>
                    <Row sx={{ backgroundColor: '#609CE1', color: 'white' }}>
                      <BodyCell sx={{ textAlign: 'right' }}>4-6</BodyCell>
                      <BodyCell sx={{ textAlign: 'right' }}>{tableData['4-6']?.val || 0}</BodyCell>
                      <BodyCell sx={{ textAlign: 'right' }}>
                        {tableData['4-6']?.percentage || 0}%
                      </BodyCell>
                    </Row>
                    <Row sx={{ backgroundColor: '#E1ECF9' }}>
                      <BodyCell sx={{ textAlign: 'right' }}>{'>6'}</BodyCell>
                      <BodyCell sx={{ textAlign: 'right' }}>{tableData['>6']?.val || 0}</BodyCell>
                      <BodyCell sx={{ textAlign: 'right' }}>
                        {tableData['>6']?.percentage || 0}%
                      </BodyCell>
                    </Row>
                    <Row sx={{ backgroundColor: '#dbebff' }}>
                      <BodyCell sx={{ textAlign: 'right' }}>Total</BodyCell>
                      <BodyCell sx={{ textAlign: 'right' }}>{getTotal(tableData)}</BodyCell>
                      <BodyCell sx={{ textAlign: 'right' }}>
                        {getTotalPercentage(tableData)}%
                      </BodyCell>
                    </Row>
                  </>
                )}
              </tbody>
            </Table>
          </Box>
          <Box>
            <Typography sx={{ fontSize: 14 }}>
              Only physical devices take part in the analysis.
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

function getTotal(data) {
  let sum = 0;
  sum += data['0-2']?.val || 0;
  sum += data['2-4']?.val || 0;
  sum += data['4-6']?.val || 0;
  sum += data['>6']?.val || 0;
  return sum;
}

function getTotalPercentage(data) {
  let sum = 0;
  sum += data['0-2']?.percentage || 0;
  sum += data['2-4']?.percentage || 0;
  sum += data['4-6']?.percentage || 0;
  sum += data['>6']?.percentage || 0;
  return sum > 100 ? 100 : sum;
}
