import * as React from 'react';
// components
// import GroupedBarChart from '../../../../../components/charts/AgeAnalysisChart';
import GroupedBarChart from '../../../../../components/charts/AgeAnalysisChartNew';

export default function Tab() {
  return (
    <>
      <GroupedBarChart />
    </>
  );
}
