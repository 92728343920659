import React, { useState, useEffect } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { getRAWSoftware, getSubCategories } from '../../server';
import RawAndStreamlineStatusDropdown from './RawAndStreamlineStatusDropdown';

export default function GridExample(props) {
  const [rowData, setRowData] = useState(null);
  const [gridApi, setGridApi] = useState(null);

  // useEffect(() => {
  //   getSubCategories().then((data) => setdSubCategories(mapSubCategories(data)));
  // }, []);

  useEffect(() => {
    if (props.RAWSoftware) {
      setRowData(props.RAWSoftware);
    }
  }, [props.RAWSoftware]);

  // const getDeviceList = () =>
  //   new Promise((resolve, reject) => {
  //     getRAWSoftware(props.arpId, props.grid).then((data) => {
  //       updateData(data);
  //       resolve();
  //     });
  //   });

  // const updateData = (data) => {
  //   if (data?.data) setRowData(data.data.reportList);
  // };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  return (
    <>
      <RawAndStreamlineStatusDropdown
        {...{
          gridApi,
          softwareStates: props.softwareStates,
          getSoftwareList: props.getSoftwareList
        }}
      />
      <div
        style={{
          height: props.fullScreen ? 'calc(100vh - 180px)' : 'calc(100vh - 245px)',
          width: '100%'
        }}
        className="ag-theme-alpine"
      >
        <AgGridReact
          onGridReady={onGridReady}
          rowSelection="multiple"
          defaultColDef={{
            flex: 1,
            minWidth: 140,
            sortable: true,
            filter: true,
            floatingFilter: true,
            resizable: true
          }}
          headerHeight="25"
          animateRows="true"
          rowClassRules={{
            selectedDeviceRow: (params) => params.data.softwareDeviceId === props.arpId
          }}
          suppressContextMenu
          rowData={rowData}
          statusBar={{
            statusPanels: [
              {
                statusPanel: 'agTotalAndFilteredRowCountComponent',
                align: 'left'
              }
            ]
          }}
        >
          <AgGridColumn
            field="rawPublisher"
            headerCheckboxSelection="true"
            checkboxSelection
            headerName="Publisher"
            filter="agTextColumnFilter"
          />
          <AgGridColumn
            field="rawSoftwareTitle"
            headerName="Software Title"
            filter="agTextColumnFilter"
          />
          <AgGridColumn field="rawVersion" headerName="Version" filter="agTextColumnFilter" />
          <AgGridColumn field="categoryName" headerName="Category" filter="agSetColumnFilter" />
          <AgGridColumn field="licenseType" headerName="License Type" filter="agSetColumnFilter" />
          <AgGridColumn
            field="statusName"
            headerName="Software State"
            filter="agSetColumnFilter"
            cellStyle={{ backgroundColor: '#ffffcc' }}
          />
        </AgGridReact>
      </div>
    </>
  );
}
