import React, { useEffect, useRef, useState } from 'react';

export default (props) => {
  const [ascSort, setAscSort] = useState('inactive');
  const [descSort, setDescSort] = useState('inactive');
  const [noSort, setNoSort] = useState('inactive');
  const refButton = useRef(null);

  const onMenuClicked = () => {
    props.showColumnMenu(refButton.current);
  };

  const onSortChanged = () => {
    setAscSort(props.column.isSortAscending() ? 'active' : 'inactive');
    setDescSort(props.column.isSortDescending() ? 'active' : 'inactive');
    setNoSort(
      !props.column.isSortAscending() && !props.column.isSortDescending() ? 'active' : 'inactive'
    );
  };

  const onSortRequested = (order, event) => {
    props.setSort(order, event.shiftKey);
  };

  useEffect(() => {
    props.column.addEventListener('sortChanged', onSortChanged);
    onSortChanged();
  }, []);

  let menu = null;
  if (props.enableMenu) {
    menu = (
      <div
        ref={refButton}
        className="customHeaderMenuButton"
        onClick={() => onMenuClicked()}
        onKeyDown={() => onMenuClicked()}
        role="button"
        tabIndex={0}
      >
        <i className={`fa ${props.menuIcon}`} />
      </div>
    );
  }

  let sort = null;
  if (true) {
    sort = (
      <div style={{ display: 'inline-block' }}>
        <div
          onClick={(event) => onSortRequested('asc', event)}
          onTouchEnd={(event) => onSortRequested('asc', event)}
          className={`customSortDownLabel ${ascSort}`}
          onKeyDown={(event) => onSortRequested('asc', event)}
          role="button"
          tabIndex={0}
        >
          {/* <i className="fa fa-long-arrow-alt-down" /> */}
          <span
            className="ag-icon ag-icon-asc"
            unselectable="on"
            role="presentation"
            style={{ paddingTop: '5px', fontSize: '12px' }}
          />
        </div>
        <div
          onClick={(event) => onSortRequested('desc', event)}
          onTouchEnd={(event) => onSortRequested('desc', event)}
          className={`customSortUpLabel ${descSort}`}
          onKeyDown={(event) => onSortRequested('desc', event)}
          role="button"
          tabIndex={0}
        >
          {/* <i className="fa fa-long-arrow-alt-up" /> */}
          <span
            className="ag-icon ag-icon-desc"
            unselectable="on"
            role="presentation"
            style={{ paddingTop: '5px', fontSize: '12px ' }}
          />
        </div>
        <div
          onClick={(event) => onSortRequested('', event)}
          onTouchEnd={(event) => onSortRequested('', event)}
          className={`customSortRemoveLabel ${noSort}`}
          onKeyDown={(event) => onSortRequested('', event)}
          role="button"
          tabIndex={0}
        >
          <i className="fa fa-times" />
        </div>
      </div>
    );
  }

  return (
    <div>
      {menu}
      <div className="customHeaderLabel" style={{ fontSize: 9, textAlign: 'right' }}>
        <div>{props.displayName}</div>
        <div>{props.inventoryDate}</div>
      </div>
      {sort}
    </div>
  );
};
