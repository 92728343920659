import React, { useEffect, useState, useContext } from 'react';
import Chart from 'react-apexcharts';
import Overlay from '../../../components/Overlay';
import { numberformatter } from '../../../helpers';

export default function VerticalBar(props) {
  const [arrXaxis, setArrXaxis] = useState([]);
  const [arrYaxis, setArrYaxis] = useState([]);
  const chartData = {
    series: arrXaxis,
    options: {
      fill: {
        colors: [
          '#133863',
          '#2369B8',
          '#609CE1',
          '#E1ECF9',
          '#41729F',
          '#5885AF',
          '#274472',
          '#C3E0E5'
        ]
      },
      legend: {
        position: 'bottom',
        labels: {
          colors: [
            '#133863',
            '#2369B8',
            '#609CE1',
            '#E1ECF9',
            '#41729F',
            '#5885AF',
            '#274472',
            '#C3E0E5'
          ]
          // useSeriesColors: true
        }
      },
      labels: arrYaxis,
      chart: {
        type: 'donut'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    }
  };

  useEffect(() => {
    if (props?.data?.length) {
      updateData(props.data);
    }
  }, [props]);

  const updateData = (data) => {
    const xAxisArr = [];
    const yAxisArr = [];
    for (let i = 0; i < data.length; i += 1) {
      xAxisArr.push(data[i].publisherName);
      yAxisArr.push(data[i].qauntity);
    }
    setArrXaxis(yAxisArr);
    setArrYaxis(xAxisArr);
  };

  return (
    <>
      {props?.data ? (
        <Chart options={chartData.options} series={chartData.series} type="donut" height={350} />
      ) : (
        <Overlay>Loading...</Overlay>
      )}
    </>
  );
}
