import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import BarChartLicenseType from './components/BarChartLicenseType';
import TreemapAntDChart from './components/TreemapAntDChart';
import StreamlineStats from './components/StreamlineStats';
import { getRawNormalizedStat, getStreamLineStat } from '../../server';
import Overlay from '../../components/Overlay';

export default function Dashboard() {
  const [dashboardData, setDashboardData] = useState(null);
  const [StreamLineStatData, setStreamLineStatData] = useState(null);
  const data = [];

  useEffect(() => {
    getRawNormalizedStat().then((res) => {
      setDashboardData(res.data);
    });

    getStreamLineStat().then((res) => {
      setStreamLineStatData(res.data);
    });
  }, []);

  return (
    <>
      {dashboardData === null && StreamLineStatData === null ? (
        <Overlay>Loading...</Overlay>
      ) : (
        <Box
          sx={{ overflow: 'auto', height: 'calc(100vh - 76px)', p: 2, pb: 1, background: '#ccc' }}
        >
          {/* Top container */}
          <Box>
            {/* Top Left container */}
            <Box sx={{ background: 'white', p: 2 }}>
              <h4 style={{ fontWeight: 500, zIndex: 200, marginBottom: 10 }}> Categorization</h4>
              <Box sx={{ m: 0, mb: '-15px', zIndex: 100, height: 'calc(100vh - 425px)' }}>
                <TreemapAntDChart tData={dashboardData?.category_Graph_KeyValues} />
              </Box>
            </Box>
          </Box>
          {/* Bottom container */}
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Box sx={{ background: 'white', width: '40%', p: 2 }}>
              <StreamlineStats {...{ StreamLineStatData }} />
            </Box>
            <Box
              sx={{
                background: 'white',
                width: '60%',
                ml: 2
                // p: 2
              }}
            >
              <h4 style={{ fontWeight: 500, padding: 16 }}>License-Type Breakdown</h4>
              <Box
                sx={{
                  margin: '0 auto',
                  mt: 0
                }}
              >
                <div>
                  <BarChartLicenseType pData={dashboardData?.license_Graph_KeyValues} />
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

function getData() {
  return {
    message: '',
    status: true,
    errors: null,
    data: {
      modelData: {
        noOfDevices: 0,
        rawPublishers: 6138,
        normalizedPublishers: 4224,
        rawProductLines: 38434,
        normalizedProductLines: 27577,
        rawProducts: 74167,
        normalizedProducts: 34314,
        publisherReduction: 31,
        productLineReduction: 28,
        productReduction: 54,
        installTitledCategorized: 74,
        licenseTypeIdentified: 71
      },
      license_Graph_KeyValues: [
        { key: null, val: 0, year: null },
        { key: 'Free', val: 511971, year: null },
        { key: 'Volume License', val: 781560, year: null },
        { key: 'Single License', val: 31159, year: null },
        { key: 'OEM', val: 133794, year: null },
        { key: 'Trial', val: 1104, year: null },
        { key: 'Component', val: 2707555, year: null },
        { key: 'OpenSource', val: 137816, year: null },
        { key: 'Server', val: 2366, year: null },
        { key: 'Vol. Subscription', val: 9561, year: null },
        { key: 'Subscribe-ware', val: 13062, year: null },
        { key: 'Shareware', val: 4, year: null },
        { key: 'Homeware', val: 27, year: null },
        { key: 'Unassigned', val: 1361, year: null },
        { key: 'Developer', val: 1191, year: null },
        { key: 'Procs and Cores', val: 9644, year: null },
        { key: 'Site License', val: 1028, year: null },
        { key: 'In-house', val: 27933, year: null },
        { key: 'Concurrent User', val: 124, year: null },
        { key: 'Un-readable', val: 34, year: null },
        { key: 'Beta', val: 3034, year: null },
        { key: 'Eval', val: 165, year: null },
        { key: 'IBM PVU', val: 218, year: null },
        { key: 'Retail', val: 3, year: null },
        { key: 'Donateware', val: 1, year: null }
      ],
      category_Graph_KeyValues: [
        {
          key: 'IT Mgmt',
          val: 557496,
          subModel: [
            { key: 'IT Asset Mgmt', val: 32766 },
            { key: 'IT Mgmt Tool/ Other', val: 1944 },
            { key: 'VPN Tools', val: 25383 },
            { key: 'Helpdesk', val: 9556 },
            { key: 'remote control', val: 53457 },
            { key: 'Device Tracking/RFID', val: 420 },
            { key: 'Mainframe Emulation', val: 10943 },
            { key: 'Remote Access/App Publishing', val: 201956 },
            { key: 'Network/Systems Mgmt', val: 71159 },
            { key: 'PC/Device Inventory', val: 102625 },
            { key: 'Cable Mgmt/labelling', val: 19 },
            { key: 'Device Performance/Diagnostics', val: 66 },
            { key: 'Power Mgmt', val: 76 },
            { key: 'Application Performance Mgmt', val: 3 },
            { key: 'RMM -Remote Mangement & Monitoring', val: 36 },
            { key: 'Virtual machine', val: 10993 },
            { key: 'Endpoint Agent', val: 94 },
            { key: 'Migration Tool', val: 275 },
            { key: 'SW Deployment/Patch Mgmt', val: 2522 },
            { key: 'Database Management', val: 69 },
            { key: 'Network Traffic Analysis', val: 1904 },
            { key: 'Barcode Printing/Mgmt', val: 3 },
            { key: 'AD/LDAP Utility', val: 7966 },
            { key: 'Patch Management', val: 11916 },
            { key: 'Virtualization & Hosting Automation', val: 26 },
            { key: 'Wifi mgmt', val: 317 },
            { key: 'Automation/Scripting', val: 10020 },
            { key: 'Call Center', val: 71 },
            { key: 'ServiceDesk', val: 685 },
            { key: 'Healthcare - Speech Assistance', val: 4 },
            { key: 'Drive Imaging', val: 222 }
          ]
        },
        {
          key: 'Development',
          val: 323467,
          subModel: [
            { key: 'QA/Automation', val: 1162 },
            { key: 'SDK', val: 3372 },
            { key: 'Compiler', val: 37 },
            { key: 'SQL Dev Tools', val: 257946 },
            { key: 'Code Libraries', val: 92 },
            { key: 'Dev Tools/ Misc', val: 47303 },
            { key: 'IDE', val: 6174 },
            { key: 'Installer', val: 81 },
            { key: 'Code Management', val: 264 },
            { key: 'Programming Language', val: 4140 },
            { key: 'Debugging', val: 270 },
            { key: 'Charts/Graphs/UI', val: 156 },
            { key: 'MSDN Libraries', val: 2452 },
            { key: 'Control Systems/Engineering', val: 18 }
          ]
        },
        {
          key: 'Industry Specific',
          val: 120273,
          subModel: [
            { key: 'Healthcare - Other', val: 86 },
            { key: 'HealthCare - DICOM (Medical Imaging)', val: 645 },
            { key: 'Medical - Other', val: 1454 },
            { key: 'Healthcare - EMS/Hospital Mgmt', val: 19 },
            { key: 'Healthcare - Informatics', val: 65142 },
            { key: 'HC Care Mgmt-Health Information Mgmt', val: 16424 },
            { key: 'Healthcare-Records Mgmt', val: 21905 },
            { key: 'Research - BioScience', val: 126 },
            { key: 'HC Clinical Servicoes - Audiology', val: 325 },
            { key: 'Healthchare - Cardiology', val: 1745 },
            { key: 'Healthcare - Neurology', val: 179 },
            { key: 'Medical - Surgery Equipment', val: 25 },
            { key: 'Research - Data Management', val: 1 },
            { key: 'Medical - Patient Monitoring', val: 219 },
            { key: 'Spectroscopy/Radiation', val: 8 },
            { key: 'Medical - Transfusion Hematology', val: 135 },
            { key: 'Healthcare- Medical Equipment', val: 871 },
            { key: 'HC Anc.Clinical Svcs - Imaging/Radiology', val: 1940 },
            { key: 'Healthcare - Communications Aid', val: 14 },
            { key: 'Data Logging', val: 271 },
            { key: 'Research - Mass Spectrometry', val: 12 },
            { key: 'Surveillance/Security', val: 249 },
            { key: 'Medical Radio/Chemotherapy', val: 4951 },
            { key: 'BioScience - Genetic Analysis', val: 51 },
            { key: 'Healthcare - Oncology', val: 38 },
            { key: 'Power Transmission/Distribution', val: 53 },
            { key: 'Medical - Surgery Mgmt', val: 31 },
            { key: 'Healthcare - Renal', val: 10 },
            { key: 'Healthcare - PACS Management (Display)', val: 1928 },
            { key: 'Postage/Shipping', val: 3 },
            { key: 'Bank-Equity Mgmt', val: 4 },
            { key: 'Healthcare - Pharmacuetics', val: 10 },
            { key: 'Healthcare - Hearing Testing', val: 34 },
            { key: 'GIS', val: 6 },
            { key: 'Research - Spectroscopy', val: 12 },
            { key: 'Healthcare - Respironics', val: 23 },
            { key: 'Healthcare - Medical Dicatation', val: 1 },
            { key: 'HealthCare - Life Sciences', val: 12 },
            { key: 'HC Clinical Services - Surgery', val: 109 },
            { key: 'HealthCare - Medicine Management', val: 298 },
            { key: 'HealthCare - Clinical Diagnostics', val: 149 },
            { key: 'HealthCare - PAS (Patient Administration Services)', val: 29 },
            { key: 'Healthcare - Transplant Mgmt', val: 24 },
            { key: 'Industrial - Sensors', val: 2 },
            { key: 'Traffic Simulation/Analysis', val: 4 },
            { key: 'GPS/Navigation', val: 1 },
            { key: 'Healthcare - Dental', val: 33 },
            { key: 'Life Sciences - Chemistry', val: 3 },
            { key: 'Research - Microscopy', val: 4 },
            { key: 'Research - Library Mgmt', val: 2 },
            { key: 'HC Anc.Clinical Svcs - Pathology/Lab', val: 343 },
            { key: 'Life Sciences - Genetics', val: 2 },
            { key: 'Mining', val: 1 },
            { key: 'Medical - Pathology', val: 2 },
            { key: 'Healthcare - Neonatal/Maternity Management', val: 112 },
            { key: 'Equipment Calibration', val: 20 },
            { key: 'RFID/Telemetry', val: 21 },
            { key: 'Healthcare - TeleHealth', val: 3 },
            { key: 'Bank-Other', val: 17 },
            { key: 'Manufacturer- Automation', val: 57 },
            { key: 'Healthcare - Psychological Assessment', val: 1 },
            { key: 'Research - Reference Management', val: 14 },
            { key: 'Inventory Management', val: 43 },
            { key: 'Medical - Lab Automation', val: 6 },
            { key: 'Video Hosting And Distribution', val: 4 },
            { key: 'Avionics', val: 1 },
            { key: 'Gas Detection', val: 6 }
          ]
        },
        {
          key: 'Communications',
          val: 53466,
          subModel: [
            { key: 'Unified Communications', val: 6776 },
            { key: 'Speech Text Conversion', val: 22817 },
            { key: 'Web Conferencing', val: 13525 },
            { key: 'Chat', val: 3787 },
            { key: 'SMS Tools', val: 9 },
            { key: 'Fax', val: 2665 },
            { key: 'VOIP/WebPhone', val: 3070 },
            { key: 'Email', val: 763 },
            { key: 'Calender/Scheduling', val: 30 },
            { key: 'Internet/Data Access', val: 7 },
            { key: 'Language Translation', val: 17 }
          ]
        },
        {
          key: 'Corporate',
          val: 50629,
          subModel: [
            { key: 'Call Activity & Reporting', val: 57 },
            { key: 'ECM -_Ent. Content Mgmt', val: 290 },
            { key: 'Video & Premises Security', val: 1008 },
            { key: 'Tax Filing', val: 219 },
            { key: 'BPM/CPM', val: 58 },
            { key: 'CRM / Customer Service', val: 11 },
            { key: 'Document Mgmt /Capture', val: 1438 },
            { key: 'RPA (Robotic Process Autmation)', val: 4045 },
            { key: 'Business Process Mgmt', val: 2105 },
            { key: 'Medical - Pathology', val: 46 },
            { key: 'Accounting', val: 1399 },
            { key: 'Building Mgmt Systems', val: 27 },
            { key: 'Sales Software', val: 1 },
            { key: 'Data Analytics', val: 116 },
            { key: 'Productivity Monitoring', val: 2 },
            { key: 'Banking', val: 39 },
            { key: 'GRC - Gov, Risk & Compliance', val: 38723 },
            { key: 'ERP _Ent Resource Planning', val: 97 },
            { key: 'Order Processing', val: 40 },
            { key: 'Marketing –Event Mgmt', val: 178 },
            { key: 'Internal Misc.', val: 13 },
            { key: 'Stock Market Trading/Analysis', val: 2 },
            { key: 'Shipping', val: 64 },
            { key: 'Workforce (Field) management', val: 14 },
            { key: 'PLM (Prod Lifecycle Mgmt)', val: 2 },
            { key: 'Financial Management', val: 3 },
            { key: 'AV-Presentation Equipment', val: 9 },
            { key: 'Project Mgmt', val: 2 },
            { key: 'Payroll', val: 3 },
            { key: 'Manufacture - 3D Printing', val: 1 },
            { key: 'HR-Staff Scheduling', val: 14 },
            { key: 'Automation - ENT Messaging', val: 544 },
            { key: 'Report Mgmt & Archiving', val: 2 },
            { key: 'HR-TimeSheet', val: 56 },
            { key: 'SOA - Service Oriented Arch.', val: 1 }
          ]
        },
        {
          key: 'Server Apps',
          val: 36035,
          subModel: [
            { key: 'SQL/RDMS Server', val: 11072 },
            { key: 'Data Management/Sync', val: 106 },
            { key: 'Server Mgmt Tools', val: 2150 },
            { key: 'Web Server', val: 3482 },
            { key: 'Storage Server', val: 362 },
            { key: 'BI/OLAP/Report Server', val: 14 },
            { key: 'Client Access (CAL)', val: 5797 },
            { key: 'Data/Content Server', val: 1098 },
            { key: 'SQL Server addons', val: 10183 },
            { key: 'Middleware', val: 7 },
            { key: 'License Server', val: 18 },
            { key: 'ODBC/Database Connectivity', val: 436 },
            { key: 'Data Server', val: 27 },
            { key: 'Email Server', val: 146 },
            { key: 'Sharepoint Server App', val: 909 },
            { key: 'Communications Server', val: 13 },
            { key: 'Application Server', val: 29 },
            { key: 'Cloud Management tools', val: 1 },
            { key: 'Gateway Server', val: 4 },
            { key: 'ESB/ Ent. Service Bus', val: 181 }
          ]
        },
        {
          key: 'IT Security',
          val: 680679,
          subModel: [
            { key: 'Privacy', val: 299 },
            { key: 'Data Audit Management', val: 5 },
            { key: 'AntiMalware', val: 69373 },
            { key: 'Data Backup/Replication/SAN', val: 4044 },
            { key: 'Security Analysis', val: 134141 },
            { key: 'Encryption', val: 208169 },
            { key: 'Biometric Authentication', val: 8137 },
            { key: 'Policy Enforcement/Monitoring', val: 352 },
            { key: 'Password/Access Mgmt', val: 12785 },
            { key: 'AntiVirus', val: 133662 },
            { key: 'Network Access Control', val: 285 },
            { key: 'Password Cracker', val: 1 },
            { key: 'CyberSecurity/ Host Intrusion', val: 942 },
            { key: 'Spyware Removal', val: 4 },
            { key: 'Anti-Theft', val: 6784 },
            { key: 'Firewall', val: 676 },
            { key: 'Data Deletion', val: 7 },
            { key: 'Network Traffic Monitoring', val: 2194 },
            { key: 'Email Security', val: 28229 },
            { key: 'Endpoint Protection', val: 69896 },
            { key: 'Software Rights Mgmt', val: 677 },
            { key: 'AntiSpyware', val: 13 },
            { key: 'Web Filter/Security', val: 4 }
          ]
        },
        {
          key: 'End-User Productivity',
          val: 110791,
          subModel: [
            { key: 'Printing Tools', val: 9393 },
            { key: 'Office Suite', val: 49053 },
            { key: 'BI/Data Analysis', val: 8484 },
            { key: 'Team Workflow', val: 208 },
            { key: 'Content Authoring', val: 16453 },
            { key: 'Graphics/Illustration', val: 1425 },
            { key: 'Project Management', val: 248 },
            { key: 'Presentations', val: 4800 },
            { key: 'Data Preparation', val: 56 },
            { key: 'Training/Elearning', val: 505 },
            { key: 'Video/Multimedia Editing', val: 491 },
            { key: 'Data Entry', val: 3 },
            { key: 'Dictation', val: 171 },
            { key: 'CAD', val: 429 },
            { key: 'Statistical Analysis', val: 37 },
            { key: 'Website Publishing', val: 141 },
            { key: 'Documentation', val: 7335 },
            { key: '3D Printing', val: 8 },
            { key: 'SmartPhone Sync', val: 3 },
            { key: 'Contact Mgmt/Email', val: 248 },
            { key: 'Database', val: 694 },
            { key: 'File Viewer', val: 3652 },
            { key: 'Word Processing', val: 597 },
            { key: 'Spreadsheet', val: 4692 },
            { key: 'Mapping/GIS', val: 63 },
            { key: 'BlackBerry Sync', val: 9 },
            { key: 'Document Cloud Storage', val: 418 },
            { key: 'Sound/Music Authoring', val: 16 },
            { key: 'Reference/Research Mgmt', val: 4 },
            { key: 'FlowCharting', val: 1083 },
            { key: 'CAD - BIM (Building Information Modeling)', val: 10 },
            { key: 'CellPhone Sync', val: 7 },
            { key: 'Forms Management', val: 42 },
            { key: 'Document Cloud Collaboration', val: 13 }
          ]
        }
      ]
    }
  };
}
