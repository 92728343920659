import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
// material
import { Stack, Container, Card, Typography, Link, Paper, Box, styled } from '@material-ui/core';
// components
import Page from '../components/Page';
import BtnDark from '../components/buttons/ButtonDark';
import useStyles from '../styles';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

export default function PageFunc() {
  const classes = useStyles();
  return (
    <Page title="M365 | AssetLabs" className={classes.bodyContainer}>
      <Container className={classes.fullWidth} sx={{ pb: 5 }}>
        <Box my={1}>
          <Typography variant="h4" sx={{ textAlign: 'center' }} gutterBottom>
            Slim360 <br />
            M365 & Azure Reporting and Management Portal
          </Typography>
        </Box>

        <Box className={clsx(classes.dFlex, classes.flexCenter)}>
          <img alt="Slim360" src="/static/JoinersLeaversView.png" width="600" height="auto" />
        </Box>
        <Box>
          <Typography component="p" sx={{ textAlign: 'center', my: 2 }} gutterBottom>
            Manage and Optimize Licenses across Large Enterprise Environments
          </Typography>
        </Box>
        <Box>
          <Typography component="p" sx={{ textAlign: 'center', mb: 2 }} gutterBottom>
            For Enterprise-level organizations, the cost of over-licensing employees can result in
            millions of dollars per month being wasted, which is why SLIM 360 is designed to compare
            your usage to the available licensing tiers and highlight where your employees are
            over-licensed, and how much it is costing.
          </Typography>
        </Box>
        <Box>
          <Typography component="p" sx={{ textAlign: 'center', mb: 2 }} gutterBottom>
            Slim360 creates a streamlined and modern approach to O365 Management by organizing your
            licensing in a way that is both easy to understand and comprehensive. This approach
            makes license optimization more accessible and more efficient so you can make informed
            decisions around your software budget
          </Typography>
        </Box>
        <Box sx={{ p: 3, border: '1px solid #ccc' }}>
          <Typography
            component="p"
            sx={{ textAlign: 'center', fontWeight: 500, fontSize: 18, mb: 2 }}
            gutterBottom
          >
            Slim360 requires a separate subscription and login. If you would like to find out more,
            &nbsp;
            <Link href="login">contact us here</Link>. Otherwise, click the button below to be
            redirected to the Slim360 portal which requires a different login.
          </Typography>
          <Box sx={{ textAlign: 'center' }}>
            <BtnDark href="https://slim360.samcompass.com/">Proceed to Slim360 Portal</BtnDark>
          </Box>
        </Box>
      </Container>
    </Page>
  );
}
