import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { LayoutContext } from '../../context/LayoutContext';

// ----------------------------------------------------------------------

// const APP_BAR_MOBILE = 50;
const APP_BAR_DESKTOP = 40;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  background: '#364150',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  background: '#364150',
  // borderRadius: theme.spacing(2),
  width: '100%',
  [theme.breakpoints.up('xs')]: {
    marginTop: APP_BAR_DESKTOP
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(true);
  const [status, setStatus] = useState(0);
  return (
    <RootStyle>
      <DashboardNavbar
        onOpenSidebar={() => {
          setOpen(!open);
        }}
        status={status}
        isOpenSidebar={open}
      />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(open)} />
      <MainStyle>
        <LayoutContext.Provider value={(status) => setStatus(status)}>
          <Outlet />
        </LayoutContext.Provider>
      </MainStyle>
    </RootStyle>
  );
}
