import React, { useState, useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/styles';
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import MuiTypography from '@material-ui/core/Typography';
// import _ from 'lodash';
import EntitlementGrid from '../../../../../../components/grids/entitlementGrid';
import useStyles from '../../../../../../styles';
import { formatter } from '../../../../../../helpers';
import { ReportContext } from '../../../../../../context/ReportContext';
import { getPublisherEntitlements } from '../../../../../../server';

const checkLevel = ['commonName', 'licenseType', 'subCategoryName', 'productLine', 'productFamily'];

const SUM_VAL = {
  LicensableProductlines: 0,
  TrueUpCost: 1
};
const Counter = styled('div')({
  height: 80,
  minWidth: 195,
  paddingLeft: 10,
  paddingRight: 10,
  // paddingBottom: 2,
  paddingTop: 10,
  paddingBottom: 15,
  background: '#434F5E',
  display: 'inline-block',
  '&:not(:last-child)': {
    marginRight: 10
  }
});

const CounterContent = styled('div')({
  position: 'relative',
  top: '50%',
  transform: 'translateY(-50%)'
});

const CounterHits = styled('p')({
  fontSize: 30,
  color: '#F4F4F4',
  textAlign: 'right',
  overflow: 'hidden',
  whiteSpace: 'break-spaces',
  textOverflow: 'ellipsis'
});

const CounterLabel = styled('p')({
  fontWeight: 400,
  fontSize: 14,
  color: '#F4F4F4',
  textAlign: 'right',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
});

const Typography = withStyles({
  root: {
    fontSize: 12,
    fontWeight: 500,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    minWidth: 100
  }
})(MuiTypography);

export default function Tab() {
  const classes = useStyles();
  const [rowData, setRowData] = useState(null);
  const contextData = useContext(ReportContext);
  const array = [];

  useEffect(() => {
    if (contextData?.level[0] === 0) {
      for (let i = 0; i < contextData?.data.length; i += 1) {
        if (!array.includes(contextData?.data[i]?.publisherId)) {
          array.push(contextData?.data[i]?.publisherId);
        }
      }
    }
    if (checkLevel.includes(contextData?.level[0].field)) {
      for (let i = 0; i < contextData.level[0].allLeafChildren.length; i += 1) {
        if (!array.includes(contextData.level[0].allLeafChildren[i].data.publisherId)) {
          array.push(contextData.level[0].allLeafChildren[i].data.publisherId);
        }
      }
    }
    getPublisherEntitlements(array).then((data) => updateData(data));
  }, [contextData]);

  const updateData = (data) => {
    if (data?.data?.publisherGroup) {
      // const groupedData = _(data.data.model_list)
      //   .groupBy('productLineId')
      //   .map((groupValues, id) => {
      //     const firstVal = groupValues[0];
      //     return {
      //       productLineId: id,
      //       entitlementQuantity: _.sumBy(groupValues, 'entitlementQuantity'),
      //       inventoryQuantity: _.sumBy(groupValues, 'inventoryQuantity'),
      //       licenseQuantity: _.sumBy(groupValues, 'licenseQuantity'),
      //       msrp: _.sumBy(groupValues, 'msrp'),
      //       productId: firstVal?.productId,
      //       productLine: firstVal?.productLine,
      //       productNameFormatted: firstVal?.productNameFormatted,
      //       rowNumber: firstVal?.rowNumber,
      //       versionModelFormatted: firstVal?.versionModelFormatted
      //     };
      //   })
      //   .value();
      setRowData(data.data.publisherGroup);
    }
  };

  return (
    <Box sx={{ overflow: 'scroll' }}>
      <Box className={classes.dFlex} sx={{ my: 1 }}>
        {/* <Box style={{ display: 'none' }}>
            <img style={{ maxHeight: '50px' }} src="/assets/excel.png" alt="" />
            <Typography variant="subtitle1">Download Full ELP</Typography>
          </Box> */}
        <Counter sx={{ background: '#D2484B' }}>
          <CounterContent>
            <CounterHits>{formatter.format(getSum(SUM_VAL.TrueUpCost, rowData))}</CounterHits>
            <CounterLabel gutterBottom>True-Up Cost (Estimate*)</CounterLabel>
          </CounterContent>
        </Counter>
        <Counter>
          <CounterContent>
            <CounterHits>{rowData ? rowData.length : 0}</CounterHits>
            <CounterLabel gutterBottom>Licensable Productlines</CounterLabel>
          </CounterContent>
        </Counter>
        <Typography sx={{ mx: 0.5, textAlign: 'left', whiteSpace: 'normal !important' }}>
          *Subscription and processor based licenses may not be included in totals
        </Typography>
        {/* </Box> */}
      </Box>
      <EntitlementGrid rowData={rowData} />
    </Box>
  );
}

function getSum(key, data) {
  let sum = 0;
  if (!data) return sum;

  switch (key) {
    case SUM_VAL.LicensableProductlines:
      data.forEach((element) => {
        sum += element.licenseQuantity;
      });
      break;
    case SUM_VAL.TrueUpCost:
      data.forEach((element) => {
        sum += element.tuneUp < 0 ? element.tuneUp : 0;
      });
      break;
    default:
      break;
  }

  return sum;
}
