import React, { useState, useEffect } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import CloseIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AddToWatchList, RemoveFromWatchList } from '../../server';

export default function GridExample(props) {
  const [rowData, setRowData] = useState(null);

  useEffect(() => {
    if (props?.data?.length > 0) {
      setRowData(props.data);
    } else {
      setRowData([]);
    }
  }, [props.data]);

  const onGridReady = (params) => {
    props.setGridApi(params.api);
  };

  const removeFromWatchList = (productLineID) => {
    RemoveFromWatchList(productLineID)
      .then((res) => {
        if (res?.status) {
          console.log('success');
        }
      })
      .catch(() => {});
  };

  return (
    <div
      style={{
        width: '100%',
        height: 'calc(100vh - 160px)'
      }}
      className="ag-theme-alpine"
    >
      <AgGridReact
        defaultColDef={{
          flex: 1,
          minWidth: 140,
          sortable: true,
          filter: true
        }}
        frameworkComponents={{
          actionsCell: ActionsCell
        }}
        // autoGroupColumnDef={{ minWidth: 200 }}
        headerHeight="25"
        // rowHeight="25"
        animateRows="true"
        onGridReady={onGridReady}
        rowData={rowData}
      >
        <AgGridColumn field="publisher" headerName="Publisher" filter="agTextColumnFilter" />
        <AgGridColumn field="productline" headerName="Product Line" filter="agTextColumnFilter" />
        <AgGridColumn field="licenseQTY" headerName="License Pool" filter="false" />
        <AgGridColumn field="quantity" headerName="Inventory" filter="false" />
        <AgGridColumn field="prevQuantity" headerName="Old Inv." filter="false" />
        <AgGridColumn
          field="delta"
          headerName="Inv. Delta"
          filter="false"
          valueGetter={(params) => params.data?.quantity - params.data?.prevQuantity}
        />
        <AgGridColumn
          field="trend"
          cellRenderer={(params) => {
            let img = '<img width="12" alt="down" src="/assets/caret_down.png" />';

            if (params.value > 0) {
              img = '<img width="12" alt="export" src="/assets/caret_up.png" />';
            }

            return `<span>${img}</span>`;
          }}
          headerName="Inv. Trend"
          filter="false"
        />
        <AgGridColumn
          field="actions"
          cellRendererParams={{ removeFromWatchList }}
          cellRendererSelector={{
            component: 'actionsCell'
          }}
          headerName="Remove"
          filter="false"
        />
      </AgGridReact>
    </div>
  );
}

function ActionsCell(props) {
  return (
    <IconButton
      onClick={() => props.removeFromWatchList(props?.data?.productlineid)}
      aria-label="remove"
    >
      <CloseIcon sx={{ ml: '8px' }} color="action" />
    </IconButton>
  );
}
