// material
import { Stack, Container, Card, Typography, Grid, Paper, styled } from '@material-ui/core';
// components
import SubscriptionGrid from '../../../components/grids/SubscriptionGrid';
import AddSubscriptionDialog from './components/AddSubscriptionDialog';
import Page from '../../../components/Page';
import BtnLight from '../../../components/buttons/ButtonLight';
import useStyles from '../../../styles';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

export default function PageFunc() {
  const classes = useStyles();
  return (
    <Page title="Manage Account Subscriptions | AssetLabs" className={classes.bodyContainer}>
      <Container className={classes.fullWidth}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4">Manage Account Subscriptions</Typography>
          <AddSubscriptionDialog />
        </Stack>
        <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
          <SubscriptionGrid />
        </Stack>
      </Container>
    </Page>
  );
}
