import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Bar } from '@ant-design/plots';
import { numberformatter } from '../../../helpers';

export default function DemoPie(props) {
  const [pieData, setPieData] = useState([]);
  useEffect(() => {
    if (props.pData?.length) {
      let data = props.pData.map((i) => ({
        type: i.key || '',
        value: i.val || 0
      }));

      data = data.sort((a, b) => b.value - a.value).slice(0, 10);
      setPieData(data);
    }
  }, [props.pData]);

  const config = {
    data: pieData,
    xField: 'value',
    yField: 'type',
    seriesField: 'type',
    legend: {
      position: 'top-left'
    }
  };
  return (
    <div style={{ height: 190, margin: '0 10px 10px 10px' }}>
      <Bar {...config} />
    </div>
  );
}
