import * as React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles, makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from '../../styles';
import BtnLight from '../buttons/ButtonLight';

const DialogActions = withStyles({
  root: {
    backgroundColor: '#364150',
    color: '#F4F6F8'
  }
})(MuiDialogActions);

const DialogContent = withStyles({
  root: {
    backgroundColor: '#364150',
    color: '#F4F6F8'
  }
})(MuiDialogContent);

const DialogContentText = withStyles({
  root: {
    backgroundColor: '#364150',
    color: '#F4F6F8'
  }
})(MuiDialogContentText);

const DialogTitle = withStyles({
  root: {
    backgroundColor: '#364150',
    color: '#F4F6F8'
  }
})(MuiDialogTitle);

export default function FormDialog(props) {
  const classes = useStyles();
  return (
    <Box className={classes.widthHalf}>
      {/* <Dialog open={open} onClose={handleClose} className={classes.root}> */}
      <DialogTitle>
        Dialog
        <IconButton aria-label="close" onClick={props.handleClose} className={classes.iconStyle}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          To subscribe to this website, please enter your email address here.
        </DialogContentText>
        <TextField
          margin="dense"
          id="name"
          label="Provider Name"
          type="email"
          fullWidth
          variant="standard"
          color="secondary"
          className={classes.textInput}
        />
        <TextField
          margin="dense"
          id="name"
          label="Provider Code"
          type="email"
          fullWidth
          variant="standard"
          color="secondary"
          className={classes.textInput}
        />
        <TextField
          margin="dense"
          id="name"
          label="Currency type"
          type="email"
          fullWidth
          variant="standard"
          color="secondary"
          className={classes.textInput}
        />
      </DialogContent>
      <DialogActions>
        <BtnLight variant="contained" size="small" onClick={props.handleClose}>
          Save
        </BtnLight>
        <BtnLight variant="contained" size="small" onClick={props.handleClose}>
          Close
        </BtnLight>
      </DialogActions>
      {/* </Dialog> */}
    </Box>
  );
}
