import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
// material
import { Stack, Container, Card, Box, Paper, styled } from '@material-ui/core';
import MuiTypography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
// components
import Page from '../../components/Page';
import LeftChart from './components/VerticalBarChart';
import RightChart from './components/HorizontalBarChart';
import DonutChart from './components/Doughnut';
import NormalizationChart from './components/NormalizationChart';
import CategorizationChart from './components/CategorizationChart';
import useStyles from '../../styles';
import { numberformatter } from '../../helpers';
import {
  GetClientDevicesCount,
  GetServerDevicesCount,
  TopByCost,
  GetLicenseingWatchList,
  TopLicenseTypes,
  TopCategories,
  getRawNormalizedStat
} from '../../server';

const Counter = styled('div')(({ theme }) => ({
  minWidth: 150,
  width: '25%',
  borderRadius: 5,
  paddingLeft: 10,
  paddingRight: 10,
  paddingBottom: 15,
  paddingTop: 10,
  backgroundPosition: 'right',
  backgroundRepeat: 'no-repeat',
  display: 'inline-block',
  '&:not(:last-child)': {
    marginRight: 5
  }
  // [theme.breakpoints.up('lg')]: {}
}));

const CounterContent = styled('div')({
  position: 'relative',
  top: '50%',
  transform: 'translateY(-50%)'
});

const CounterHits = styled('p')({
  fontSize: 30,
  color: '#F4F4F4',
  fontWeight: 500,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
});

const CounterLabel = styled('p')({
  fontWeight: 400,
  fontSize: 15,
  color: '#F4F4F4'
  // textAlign: 'center'
});

const Typography = withStyles({
  root: {
    fontSize: 15,
    fontWeight: 500
  }
})(MuiTypography);

export default function PageFunc() {
  const classes = useStyles();
  const [clientDevicesCount, setClientDevicesCount] = useState(0);
  const [serverDevicesCount, setServerDevicesCount] = useState(0);
  const [topPublisherByCost, setTopPublisherByCost] = useState(null);
  const [topApplicationByCost, setTopApplicationByCost] = useState(null);
  const [licenseingWatchList, setLicenseingWatchList] = useState(null);
  const [topLicenseTypes, setTopLicenseTypes] = useState(null);
  const [topCategories, setTopCategories] = useState(null);
  const [rawNormalizedStat, setRawNormalizedStat] = useState(null);

  useEffect(() => {
    GetClientDevicesCount().then((res) => {
      if (res.data) {
        setClientDevicesCount(res.data.desktopsCount);
      }
    });

    GetServerDevicesCount().then((res) => {
      if (res.data) {
        setServerDevicesCount(res.data.serversCount);
      }
    });

    TopByCost().then((res) => {
      if (res.data?.topApplication) {
        setTopApplicationByCost(res.data.topApplication);
        setTopPublisherByCost(res.data.topPublisher);
      }
    });

    GetLicenseingWatchList().then((res) => {
      if (res.data) {
        setLicenseingWatchList(res.data.watchList);
      }
    });

    TopLicenseTypes().then((res) => {
      if (res.data) {
        setTopLicenseTypes(res.data.licenseType);
      }
    });

    TopCategories().then((res) => {
      if (res.data) {
        setTopCategories(res.data.category);
      }
    });

    getRawNormalizedStat().then((res) => {
      if (res.data) {
        setRawNormalizedStat(res.data.modelData);
      }
    });
  }, []);

  return (
    <Page title="Dashboard | AssetLabs" className={classes.bodyContainer}>
      <Container className={classes.fullWidth}>
        <Box className={clsx(classes.dFlex, classes.flexBetween, classes.mt10)}>
          <Counter
            style={{
              backgroundColor: '#9F88F2',
              backgroundImage: 'url(/assets/Active_Subcription.gif)'
            }}
          >
            <CounterContent>
              <CounterHits>Streamline</CounterHits>
              <CounterLabel gutterBottom sx={{ mt: 1 }}>
                Active Subscription
              </CounterLabel>
            </CounterContent>
          </Counter>
          <Counter
            style={{
              backgroundColor: '#5DABFE',
              backgroundImage: 'url(/assets/Inventory_Snapshot.gif)'
            }}
          >
            <CounterContent>
              <CounterHits>November 8, 2021</CounterHits>
              <CounterLabel gutterBottom sx={{ mt: 1 }}>
                Inventory Snapshot
              </CounterLabel>
            </CounterContent>
          </Counter>
          <Counter
            style={{
              backgroundColor: '#FE635F',
              backgroundImage: 'url(/assets/Client_Devices.png)'
            }}
          >
            <CounterContent>
              <CounterHits>{numberformatter(clientDevicesCount)}</CounterHits>
              <CounterLabel gutterBottom sx={{ mt: 1 }}>
                Client Devices
              </CounterLabel>
            </CounterContent>
          </Counter>
          <Counter
            style={{
              backgroundColor: '#EEB359',
              backgroundImage: 'url(/assets/Server_Devices.png)'
            }}
          >
            <CounterContent>
              <CounterHits>{numberformatter(serverDevicesCount)}</CounterHits>
              <CounterLabel gutterBottom sx={{ mt: 1 }}>
                Server Devices
              </CounterLabel>
            </CounterContent>
          </Counter>
        </Box>
        <Box className={clsx(classes.dFlex, classes.mt20)}>
          <Card
            className={classes.dashboardCard}
            sx={{ width: '33.3%', minHeight: 350, m: 1.5, p: 1.5 }}
          >
            <Typography variant="h6" gutterBottom>
              Top Publishers by Cost Footprint
            </Typography>
            <LeftChart data={topPublisherByCost} />
          </Card>
          <Card
            className={classes.dashboardCard}
            sx={{ width: '33.3%', minHeight: 350, m: 1.5, p: 1.5 }}
          >
            <Typography variant="h6" gutterBottom>
              Top Applications by Cost
            </Typography>
            <RightChart data={topApplicationByCost} />
          </Card>
          <Card
            className={classes.dashboardCard}
            sx={{ width: '33.3%', minHeight: 350, m: 1.5, p: 1.5 }}
          >
            <Typography variant="h6" gutterBottom>
              Top Categories
            </Typography>
            <CategorizationChart data={topCategories} />
          </Card>
        </Box>
        <Box className={clsx(classes.dFlex, classes.mt10, classes.pb20)}>
          <Card
            className={classes.dashboardCard}
            sx={{ width: '33%', minHeight: 350, m: 1.5, p: 1.5 }}
          >
            <Typography variant="h6" gutterBottom>
              Top License Types
            </Typography>
            <DonutChart data={topLicenseTypes} />
          </Card>
          <Card
            className={classes.dashboardCard}
            sx={{ width: '67%', minHeight: 350, m: 1.5, p: 1.5 }}
          >
            <Typography variant="h6" gutterBottom>
              Normalization Statistics
            </Typography>
            <NormalizationChart data={rawNormalizedStat} />
          </Card>
        </Box>
      </Container>
    </Page>
  );
}
