import React, { useState, useEffect } from 'react';
import { NativeSelect, Stack, Grid, IconButton, Snackbar, Alert } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import clsx from 'clsx';
import { UpdateProductLineDeviceSoftwareState } from '../../server';
import useStyles from '../../styles';

export default function RawAndStreamlineStatusDropdown(props) {
  const classes = useStyles();
  const [defaultValue, setDefaultValue] = useState('default');
  const [showToast, setShowToast] = useState(false);
  const [alertText, setAlertText] = useState('');

  const handleChange = (event) => {
    const selectedRows = props.gridApi.getSelectedRows();
    const deviceARPIdArr = [];
    for (let i = 0; i < selectedRows?.length; i += 1) {
      if (selectedRows[i]?.deviceARPId) {
        deviceARPIdArr.push(selectedRows[i]?.deviceARPId);
      }
    }

    if (deviceARPIdArr.length > 0) {
      setAlertText('Updating...');
      UpdateProductLineDeviceSoftwareState(event.target.value, deviceARPIdArr).then((data) => {
        if (data.status === true) {
          props.getSoftwareList().then(() => {
            setAlertText(' Software state updated');
            setShowToast(true);
          });
        }
      });
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  return (
    <>
      <Stack className={classes.controlsRibbon}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 0.5 }}>
          <Grid>
            <IconButton
              aria-label="delete"
              className={classes.iconBtnCR}
              sx={{ ml: 1 }}
              disableRipple
            >
              <SettingsIcon className={classes.selectIcon} />
              <NativeSelect
                onChange={handleChange}
                name="softwareState"
                inputProps={{ 'aria-label': 'softwareState' }}
                value={defaultValue}
                defaultValue={defaultValue}
                className={clsx(classes.mlHalf, classes.selectDrop)}
              >
                <option disabled selected value="default">
                  Set Software States
                </option>
                {props.softwareStates?.map((x) => (
                  <option value={x.id}>{x.value}</option>
                ))}
              </NativeSelect>
            </IconButton>
          </Grid>
        </Stack>
      </Stack>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={showToast}
        autoHideDuration={1000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="success"
          sx={{ width: '100%', background: '#2196f3' }}
        >
          {alertText}
        </Alert>
      </Snackbar>
    </>
  );
}
