import React, { useEffect, useContext, useState } from 'react';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Controls from '../controls';
import AgeAnalysis from './AgeAnalysis';
import { ReportContext } from '../../../../../context/ReportContext';
import DeviceGrid from '../../../../../components/grids/deviceGrid';
import useStyles from '../../../../../styles';
import { getDeviceSoftwareStates, getStatuses, getSubCategories } from '../../../../../server';

const checkLevel = ['commonName', 'licenseType', 'subCategoryName', 'productLine', 'productFamily'];

export default function DevicesWrapper(props) {
  return <Devices {...props} />;
}

function Devices(props) {
  const classes = useStyles();
  const [value, setValue] = useState('1');
  const [status, setStatus] = useState('');
  const [gridApi, setGridApi] = useState();
  const [dStatuses, setdStatuses] = useState({});
  const [dSubCategories, setdSubCategories] = useState({});
  const [rowData, setRowData] = useState(null);

  const contextData = useContext(ReportContext);
  const array = [];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setReportParams = (params) => {
    setGridApi(params.api);
    // setReportName(name);
  };

  const exportToExcel = () => {
    if (gridApi) {
      gridApi.exportDataAsExcel({
        fileName: `Devices.xlsx`,
        allColumns: true
      });
    }
  };

  const refreshDeviceData = () =>
    new Promise((resolve, reject) => {
      if (contextData?.level[0] === 0) {
        for (let i = 0; i < contextData?.data.length; i += 1) {
          if (!array.includes(contextData?.data[i]?.productId)) {
            array.push(contextData?.data[i]?.productId);
          }
        }
      }
      if (checkLevel.includes(contextData?.level[0].field)) {
        for (let i = 0; i < contextData?.level[0]?.allLeafChildren.length; i += 1) {
          if (!array.includes(contextData?.level[0]?.allLeafChildren[i].data.productId)) {
            array.push(contextData?.level[0]?.allLeafChildren[i].data.productId);
          }
        }
      }
      if (contextData?.level[0]?.parent?.field === 'productLine') {
        array.push(contextData?.level[0]?.data.productId);
      }

      const promises = [getDeviceSoftwareStates(array)];

      if (Object.keys(dStatuses).length === 0) {
        promises.push(getStatuses());
      }
      if (Object.keys(dSubCategories).length === 0) {
        promises.push(getSubCategories());
      }

      Promise.all(promises).then(([data, statuses, subCategories]) => {
        let mapedStatuses;
        if (statuses) {
          mapedStatuses = mapStatuses(statuses);
          setdStatuses(mapedStatuses);
        } else {
          mapedStatuses = dStatuses;
        }

        if (subCategories) {
          setdSubCategories(mapSubCategories(subCategories));
        }

        if (data?.data) {
          setRowData(
            data.data.map((item) => {
              item.statusId = mapedStatuses[item.statusId];
              return item;
            })
          );
        }
        resolve();
      });
    });

  useEffect(() => {
    setValue('1');
  }, [props]);

  useEffect(() => {
    refreshDeviceData();
  }, [contextData]);

  return (
    <Box>
      <TabContext value={value}>
        <Box>
          <TabList onChange={handleChange} className={classes.nestedTabPanel}>
            <Tab className={classes.nestedTab} label="Devices List" value="1" disableRipple />
            <Tab className={classes.nestedTab} label="Age Analysis" value="2" disableRipple />
          </TabList>
        </Box>
        <TabPanel value="1" className={classes.pa0}>
          {contextData.level[0] ? (
            <Controls
              {...{
                exportToExcel,
                getStatus: (status) => setStatus(status),
                refreshDeviceData,
                gridApi
              }}
            />
          ) : null}
          {contextData.level[0] ? (
            <DeviceGrid
              {...{
                gridHeight: 254,
                dStatuses,
                dSubCategories,
                refreshDeviceData,
                setReportParams,
                status,
                rowData
              }}
            />
          ) : null}
        </TabPanel>
        <TabPanel value="2">
          <AgeAnalysis />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

function mapStatuses(statuses) {
  const obj = {};
  statuses?.data?.subCategory.forEach((x) => {
    obj[x.statusId] = x.value;
  });
  return obj;
}

function mapSubCategories(subCategories) {
  const obj = {};
  subCategories?.data?.subCategory.forEach((x) => {
    obj[x.subCategoryId] = x.subCategoryName;
  });
  return obj;
}
