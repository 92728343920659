import React, { useEffect, useState, memo } from 'react';
import clsx from 'clsx';
import MuiTypography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Box from '@material-ui/core/Box';
import MuiTab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import MuiAlert from '@material-ui/core/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import MuiTabPanel from '@material-ui/lab/TabPanel';
import { styled } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import MuiIconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import RawSoftwareGrid from '../grids/rawSoftwareGrid';
import StreamlineSoftwareGrid from '../grids/streamlineSoftwareGrid';
import useStyles from '../../styles';
import {
  getDeviceDetails,
  getStatuses,
  getNormalizedSoftware,
  getRAWSoftware,
  UpdateDevicesSoftwareState
} from '../../server';
import Overlay from '../Overlay';
import { formatDate } from '../../helpers';

const RegFont = styled('span')({
  fontWeight: 400
});

const DialogHeading = styled('h3')({
  fontWeight: 500,
  fontSize: 22,
  color: '#58616B'
});

const Alert = React.forwardRef((contextData, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...contextData} />
));

const Typography = withStyles({
  root: {
    fontWeight: 600,
    fontSize: 12
  }
})(MuiTypography);

const IconButton = withStyles({
  root: {
    padding: 0
  }
})(MuiIconButton);

const Tab = withStyles({
  root: {
    marginTop: 5,
    '&.Mui-selected': {
      marginTop: 6,
      borderTop: '1px solid #AAA',
      borderLeft: '1px solid #AAA',
      borderRight: '1px solid #AAA'
    }
  }
})(MuiTab);

const TabPanel = withStyles({
  root: {
    background: '#F8F8F8',
    borderRadius: 12,
    border: '1px solid #AAA'
  }
})(MuiTabPanel);

const DialogTitle = withStyles({
  root: {
    background: '#3B4652',
    color: '#FFF',
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 20,
    padding: '8px 12px'
  }
})(MuiDialogTitle);

const MemiozedDialogue = memo((props) => {
  const [value, setValue] = React.useState('1');
  const [rowData, setRowData] = useState(null);
  const [nextFlag, setNextFlag] = useState(1);
  const [prevFlag, setPrevFlag] = useState(1);
  const [status, setStatus] = useState([]);
  const [softwareStates, setSoftwareStates] = useState([]);
  const [RAWSoftware, setRAWSoftware] = useState(null);
  const [NormalizedSoftware, setNormalizedSoftware] = useState(null);
  const [currentArpID, setCurrentArpID] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [alertText, setAlertText] = useState('');
  const classes = useStyles();

  const handleNext = () => {
    if (props.devicesData) {
      // props.showToastWithText('Fetching data');
      const indexRow = props.devicesData.findIndex((x) => x.deviceARPId === currentArpID);
      const currentRow = props.devicesData[indexRow];
      let nextRow = null;

      for (let i = indexRow; i < props.devicesData.length; i += 1) {
        if (props.devicesData[i]?.deviceIdentifier !== currentRow.deviceIdentifier) {
          nextRow = props.devicesData[i];
          break;
        }
      }
      if (nextRow) {
        getDeviceData(nextRow?.deviceARPId, props.grid).then(() => {
          // props.setShowToast(false);
        });
        getSoftwareList(nextRow?.deviceARPId);
        setCurrentArpID(nextRow?.deviceARPId);
        showToastWithText('Device data has been updated');
      } else {
        setNextFlag(0);
      }
    }
  };

  const handlePrev = () => {
    if (props.devicesData) {
      // props.showToastWithText('Fetching data');
      const indexRow = props.devicesData.findIndex((x) => x.deviceARPId === currentArpID);
      const currentRow = props.devicesData[indexRow];
      let prevRow = null;

      for (let i = indexRow; i >= 0; i += -1) {
        if (props.devicesData[i]?.deviceIdentifier !== currentRow.deviceIdentifier) {
          prevRow = props.devicesData[i];
          break;
        }
      }
      if (prevRow) {
        getDeviceData(prevRow?.deviceARPId, props.grid).then(() => {
          // props.setShowToast(false);
        });
        getSoftwareList(prevRow?.deviceARPId);
        setCurrentArpID(prevRow?.deviceARPId);
        showToastWithText('Device data has been updated');
      } else {
        setPrevFlag(0);
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (props.arpId && props.open) {
      if (softwareStates.length === 0 || status.length === 0) {
        getStatuses().then((data) => updateStatuses(data));
      }

      getSoftwareList(props.arpId);
      getDeviceData(props.arpId, props.grid);
      setCurrentArpID(props.arpId);
    }
  }, [props.open, props.arpId]);

  const getSoftwareList = (arpID) =>
    new Promise((resolve, reject) => {
      arpID = arpID || currentArpID;
      const promises = [];
      promises.push(getNormalizedSoftware(arpID, props.grid));
      promises.push(getRAWSoftware(arpID, props.grid));

      Promise.all(promises).then(([NormalizedSoftwareData, RAWSoftwareData]) => {
        if (RAWSoftwareData?.data) setRAWSoftware(RAWSoftwareData.data.reportList);
        if (NormalizedSoftwareData?.data)
          setNormalizedSoftware(NormalizedSoftwareData.data.reportList);
        resolve();
      });
    });

  useEffect(() => {
    if (currentArpID) {
      updateNextPrevButtons();
      // getSoftwareList(currentArpID);
    }
  }, [currentArpID]);

  const updateNextPrevButtons = () => {
    const indexRow = props.devicesData.findIndex((x) => x.deviceARPId === currentArpID);
    if (indexRow === 0) {
      setPrevFlag(0);
    } else {
      setPrevFlag(1);
    }
    if (indexRow === props.devicesData.length - 1) {
      setNextFlag(0);
    } else {
      setNextFlag(1);
    }
  };

  const getDeviceData = (arpID, deviceGridType) =>
    new Promise((resolve, reject) => {
      getDeviceDetails(arpID, deviceGridType).then((res) => {
        if (res?.data) {
          setRowData(res.data.reportList[0]);
          resolve();
        }
      });
    });

  const updateStatuses = (data) => {
    const array = [];
    const softwareArray = [];

    for (let i = 0; i < data.data.subCategory.length; i += 1) {
      if (data.data.subCategory[i].section === 'HardwareStates') {
        array.push({
          id: data.data.subCategory[i].statusId.toString(),
          value: data.data.subCategory[i].value
        });
      }

      if (data.data.subCategory[i].section === 'SoftwareDeviceState') {
        softwareArray.push({
          id: data?.data?.subCategory[i].statusId,
          value: data?.data?.subCategory[i].value
        });
      }
    }

    setStatus(array);
    setSoftwareStates(softwareArray);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  const showToastWithText = (text) => {
    if (text) setAlertText(text);
    setTimeout(() => {
      setShowToast(true);
    }, 0);
  };

  return (
    <>
      <DialogTitle>
        Device Summary
        <IconButton aria-label="close" className={classes.dialogClose}>
          {props.fullScreen ? (
            <FullscreenExitIcon onClick={props.handleFullScreenExit} />
          ) : (
            <FullscreenIcon onClick={props.handleFullScreen} />
          )}
          <CloseIcon onClick={props.handleClose} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dFlex}>
        <Box mr={3} className={classes.tabBody} sx={{ width: '30% !important' }}>
          <DialogHeading>Device Summary</DialogHeading>
          {rowData && (
            <>
              <Box
                sx={{ my: 2 }}
                className={clsx(classes.dFlex, classes.flexCenter, classes.alignCenter)}
              >
                <NavigateBeforeIcon
                  onClick={() => handlePrev(props.arpId)}
                  sx={{
                    opacity: prevFlag === 0 ? 0 : 1,
                    mr: 'auto',
                    cursor: 'pointer',
                    fontSize: 36
                  }}
                />
                {rowData?.isDesktop ? (
                  <img src="./static/illustrations/client_device_icon.png" alt="" height="90" />
                ) : (
                  <img src="./static/illustrations/server_icon.png" alt="" height="90" />
                )}

                <NavigateNextIcon
                  onClick={() => handleNext(props.arpId)}
                  sx={{
                    opacity: nextFlag === 0 ? 0 : 1,
                    ml: 'auto',
                    cursor: 'pointer',
                    fontSize: 36
                  }}
                />
              </Box>
              <table>
                <Box>
                  <tr>
                    <td>
                      <Typography sx={{ color: '#3b7ec2', width: 140 }}>Role:</Typography>
                    </td>
                    <td>
                      <Typography>
                        <RegFont>{rowData?.isDesktop ? 'Client Device' : 'Server Device'}</RegFont>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography sx={{ color: '#3b7ec2', width: 140 }}>OS:</Typography>
                    </td>
                    <td>
                      <Typography>
                        <RegFont>{rowData?.os}</RegFont>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography sx={{ color: '#3b7ec2', width: 140 }}>Machine Type:</Typography>
                    </td>
                    <td>
                      <Typography>
                        <RegFont>{rowData?.isVirtual}</RegFont>
                      </Typography>
                    </td>
                  </tr>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <tr>
                    <td>
                      <Typography sx={{ color: '#3b7ec2', width: 140 }}>Device Name:</Typography>
                    </td>
                    <td>
                      <Typography>
                        <RegFont>{rowData?.deviceIdentifier}</RegFont>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography sx={{ color: '#3b7ec2', width: 140 }}>Serial #</Typography>
                    </td>
                    <td>
                      <Typography>
                        <RegFont>{rowData?.serialNumber}</RegFont>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography sx={{ color: '#3b7ec2', width: 140 }}>First Seen:</Typography>
                    </td>
                    <td>
                      <Typography>
                        <RegFont>
                          {rowData?.createDate !== '' ? formatDate(rowData?.createDate) : ''}
                        </RegFont>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography sx={{ color: '#3b7ec2', width: 140 }}>Birth date:</Typography>
                    </td>
                    <td>
                      <Typography>
                        <RegFont>
                          {rowData?.cpuReleaseDate !== ''
                            ? formatDate(rowData?.cpuReleaseDate)
                            : ''}
                        </RegFont>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography sx={{ color: '#3b7ec2', width: 140 }}>Data source:</Typography>
                    </td>
                    <td>
                      <Typography>
                        <RegFont>{rowData?.source}</RegFont>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography sx={{ color: '#3b7ec2', width: 140 }}>User:</Typography>
                    </td>
                    <td>
                      <Typography>
                        <RegFont>{rowData?.deviceUserName}</RegFont>
                      </Typography>
                    </td>
                  </tr>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <tr>
                    <td>
                      <Typography sx={{ color: '#3b7ec2', width: 140 }}>Manufacturer:</Typography>
                    </td>
                    <td>
                      <Typography>
                        <RegFont>{rowData?.manufacturer}</RegFont>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography sx={{ color: '#3b7ec2', width: 140 }}>Model:</Typography>
                    </td>
                    <td>
                      <Typography>
                        <RegFont>{rowData?.model}</RegFont>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography sx={{ color: '#3b7ec2', width: 140 }}>CPU:</Typography>
                    </td>
                    <td>
                      <Typography>
                        <RegFont>{rowData?.hostCPU}</RegFont>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography sx={{ color: '#3b7ec2', width: 140 }}>Processors:</Typography>
                    </td>
                    <td>
                      <Typography>
                        <RegFont>{rowData?.hostProcessors}</RegFont>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography sx={{ color: '#3b7ec2', width: 140 }}>Cores:</Typography>
                    </td>
                    <td>
                      <Typography>
                        <RegFont>{rowData?.hostCores}</RegFont>
                      </Typography>
                    </td>
                  </tr>
                  {rowData?.isVirtual === 'Virtual' ? (
                    <tr>
                      <td>
                        <Typography sx={{ color: '#3b7ec2', width: 140 }}>VM Cores:</Typography>
                      </td>
                      <td>
                        <Typography>
                          <RegFont>{rowData?.vmCores}</RegFont>
                        </Typography>
                      </td>
                    </tr>
                  ) : null}
                </Box>
                <Box sx={{ mt: 1 }}>
                  <tr>
                    <td>
                      <Typography sx={{ color: '#3b7ec2', width: 140 }}>Device State:</Typography>
                    </td>
                    <td>
                      <Typography>
                        <RegFont>
                          <DeviceStateDropdown
                            {...{
                              dStatuses: props?.dStatuses,
                              refreshDeviceData: props.refreshDeviceData,
                              status,
                              classes,
                              rowData,
                              showToastWithText
                            }}
                          />
                        </RegFont>
                      </Typography>
                    </td>
                  </tr>
                </Box>
              </table>
            </>
          )}
        </Box>
        <Box
          className={clsx(classes.removeIndicator, classes.tabBody)}
          sx={{ width: '70% !important' }}
        >
          <TabContext value={value}>
            <DialogContentText>
              <Box sx={{ height: 35 }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab
                    className={clsx(classes.tab, classes.dogtagTab)}
                    label="Raw Software"
                    value="1"
                    disableRipple
                  />
                  <Tab
                    className={clsx(classes.tab, classes.dogtagTab)}
                    label="Streamlined Software Titles"
                    value="2"
                    disableRipple
                  />
                </TabList>
              </Box>
            </DialogContentText>
            <TabPanel value="1">
              <RawSoftwareGrid
                {...{
                  arpId: props.arpId,
                  grid: props.grid,
                  deviceId: props.deviceId,
                  fullScreen: props.fullScreen,
                  softwareStates,
                  RAWSoftware,
                  getSoftwareList
                }}
              />
            </TabPanel>
            <TabPanel value="2">
              <StreamlineSoftwareGrid
                {...{
                  arpId: props.arpId,
                  grid: props.grid,
                  deviceId: props.deviceId,
                  fullScreen: props.fullScreen,
                  softwareStates,
                  NormalizedSoftware,
                  getSoftwareList
                }}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </DialogContent>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={showToast}
        autoHideDuration={0}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="success"
          sx={{ width: '100%', background: '#2196f3' }}
        >
          {alertText}
        </Alert>
      </Snackbar>
    </>
  );
});

export default MemiozedDialogue;

function DeviceStateDropdown(props) {
  const [defaultValue, setDefaultValue] = useState('default');

  const updateState = (event) => {
    props.showToastWithText('Updating...');
    setDefaultValue(event.target.value);
    UpdateDevicesSoftwareState(event.target.value, props.rowData?.deviceId).then((data) => {
      if (data.status === true) {
        props.refreshDeviceData();
        props.showToastWithText('State has been updated');
      }
    });
  };

  useEffect(() => {
    if (props.rowData) {
      const val = props.rowData?.productionStateId;
      if (val) {
        setDefaultValue(val);
      }
    }
  }, [props.rowData]);

  return (
    <>
      <FormControl>
        <NativeSelect
          inputProps={{
            name: 'deviceState',
            id: 'deviceState'
          }}
          value={defaultValue}
          defaultValue={defaultValue}
          className={props.classes.selectDropDialog}
          onChange={updateState}
          sx={{ backgroundColor: '#ffffcc' }}
        >
          {props.status?.map((x) => (
            <option value={x.id}>
              {x.id === props.rowData?.productionStateId ? x.value : x.value}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
    </>
  );
}
