export default function PageFunc(props) {
  return (
    <div
      style={{
        height: 'calc(100vh - 40px)',
        width: '100%',
        borderRadius: 16,
        background: '#FFFFFFAA',
        position: 'absolute',
        top: 0,
        left: 0
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'space-around',
          padding: 10,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: 3,
          marginLeft: 0,
          background: '#FFFFFF',
          border: '1px solid #babfc7',
          boxShadow: '0 1px 4px 1px rgb(186 191 199 / 40%)'
        }}
      >
        <img
          alt="overlay spinner"
          src="/assets/spin.gif"
          height="30"
          style={{ paddingTop: 1, paddingBottom: 1 }}
        />
        <p style={{ fontSize: 11, marginRight: 4, textAlign: 'center' }}>{props.children}</p>
      </div>
    </div>
  );
}
