import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';

export default forwardRef((props, ref) => {
  const [filterText, setFilterText] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = filterText;
    }
    props.filterChangedCallback();
  }, [filterText]);

  const isFilterActive = () => filterText !== null && filterText !== undefined && filterText !== '';

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    const obj = {
      isFilterActive,

      doesFilterPass(params) {
        if (isFilterActive()) {
          let passed = true;
          filterText
            .toLowerCase()
            .split(' ')
            .forEach((filterWord) => {
              const v1 = params?.data?.productLine?.toString().toLowerCase();
              const v2 = params?.data?.categoryName?.toString().toLowerCase();
              if (v1.indexOf(filterWord) < 0 && v2.indexOf(filterWord) < 0) {
                passed = false;
              }
            });

          return passed;
        }
      },

      getModel() {
        return isFilterActive() ? Number(filterText) : null;
      },

      setModel(model) {
        setFilterText(model);
      },

      myMethodForTakingValueFromFloatingFilter(value) {
        setFilterText(value);
      }
    };
    return obj;
  });

  const onInputBoxChanged = (event) => {
    setFilterText(event.target.value);
  };

  return (
    <div style={{ padding: '8px' }}>
      <div style={{ fontWeight: 'bold' }}>Search category and product lines</div>
      <div>
        <input
          ref={inputRef}
          style={{ margin: '4px 0 4px 0' }}
          type="text"
          onInput={onInputBoxChanged}
          placeholder="Search..."
        />
      </div>
    </div>
  );
});
