export const publishers = [40, '40a', '40b'];
export const softwares = ['40c', '40d'];
export const REPORTS = {
  BusinessApp_Auditable: 1,
  BusinessApp_NonAuditable: 2,
  EndUserDistraction: 3,
  AllAuditable: 5,
  LineOfBusiness: 6,
  AllMicrosoft: 38,
  MicrosoftAuditable: 12,
  AdobeAuditable: 13,
  PeerToPeer: 15,
  Games: 16,
  Malware: 18,
  OperatingSystems: 20,
  PublishersAtoZ: 40,
  PublishersByInstallCount: '40a',
  PublishersByFinancialFootprint: '40b',
  SoftwareByCategory: '40c',
  SoftwareByLicenseType: '40d',
  IBMAuditable: 44
};
