import * as React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MuiDialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import MuiTabPanel from '@material-ui/lab/TabPanel';
import { styled } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import MuiIconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from '../../../styles';
import BtnLight from '../../buttons/ButtonLight';
import AccountAccess from './components/AccountAccess';
import Roles from './components/Roles';
import GetNewDevicesGrid from '../../grids/GetNewDevicesGrid';
import GetMissingDevicesGrid from '../../grids/GetMissingDevicesGrid';

const IconButton = withStyles({
  root: {
    padding: 0
  }
})(MuiIconButton);

const TabPanel = withStyles({
  root: {
    padding: 0
  }
})(MuiTabPanel);

const Dialog = withStyles({
  paper: {
    minWidth: 650
  }
})(MuiDialog);

const DialogTitle = withStyles({
  root: {
    background: '#3B4652',
    color: '#FFF',
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 10,
    padding: '8px 12px'
  }
})(MuiDialogTitle);

export default function NewAndPastDevices(props) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  return (
    <div>
      <BtnLight onClick={handleClickOpen}>Manage User</BtnLight>
      <Dialog open={open} onClose={handleClose} className={classes.root}>
        <DialogTitle>
          Manage User
          <IconButton aria-label="close" onClick={handleClose} className={classes.iconStyle}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box className={classes.dFlex}>
            <Box
              sx={{
                width: 150,
                height: 80,
                backgroundImage: 'url(https://www.pngarts.com/files/6/User-Avatar-in-Suit-PNG.png)',
                backgroundColor: '#eee',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center'
              }}
            />
            <Box sx={{ ml: 10 }}>
              <Typography>
                Email Verified: <span>Yes</span>
              </Typography>
              <Typography>
                Locked Out: <input type="checkbox" />
              </Typography>
              <Typography>
                Last Login: <span>3/2/2017</span>
              </Typography>
            </Box>
          </Box>
          <Box className={classes.removeIndicator}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="tabs">
                  <Tab
                    className={clsx(classes.tab, classes.dogtagTab)}
                    label="Account Access"
                    value="1"
                  />
                  <Tab className={clsx(classes.tab, classes.dogtagTab)} label="Roles" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <AccountAccess />
              </TabPanel>
              <TabPanel value="2">
                <Roles />
              </TabPanel>
            </TabContext>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
