// material
import { Stack, Container, Card, Typography, Grid, Paper, styled } from '@material-ui/core';
// components
import ProviderGrid from '../../../components/grids/ProviderGrid';
import AddProviderDialog from './components/AddProviderDialog';
import Page from '../../../components/Page';
import BtnLight from '../../../components/buttons/ButtonLight';
import useStyles from '../../../styles';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

export default function PageFunc() {
  const classes = useStyles();
  return (
    <Page title="Provider Management | AssetLabs" className={classes.bodyContainer}>
      <Container className={classes.fullWidth}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4">Provider Management</Typography>
          {/* <BtnLight>new provider</BtnLight> */}
          <AddProviderDialog />
        </Stack>
        <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
          <ProviderGrid />
        </Stack>
      </Container>
    </Page>
  );
}
