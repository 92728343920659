import React, { useEffect, useState, useReducer } from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import MuiDialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Autocomplete from '@material-ui/core/Autocomplete';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Input from '@material-ui/core/Input';
import MuiTab from '@material-ui/core/Tab';
import { styled } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import MuiIconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/core/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import Grow from '@material-ui/core/Grow';
import BtnLight from '../buttons/ButtonLight';
import useStyles from '../../styles';
import { postCategorizationModal } from '../../server';
import Overlay from '../Overlay';

const MODAL_TYPE = {
  categorization: 0,
  license: 1,
  msrp: 2
};

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const IconButton = withStyles({
  root: {
    padding: 0
  }
})(MuiIconButton);

const Dialog = withStyles({
  paper: {
    minWidth: '30%'
  }
})(MuiDialog);

const DialogTitle = withStyles({
  root: {
    background: '#3B4652',
    color: '#FFF',
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 20,
    padding: '8px 12px'
  }
})(MuiDialogTitle);

export default function DialogBox(props) {
  const [dropDownList, setDropDownList] = React.useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [subCategoryId, setSubCategoryId] = React.useState();
  const [licenseTypeId, setLicenseTypeId] = React.useState();
  const classes = useStyles();

  const [formInput, setFormInput] = useReducer((state, newState) => ({ ...state, ...newState }), {
    // SubCategoryId: '',
    msrp: ''
  });

  useEffect(() => {
    const list =
      props.modalType === MODAL_TYPE.categorization
        ? props.lists.subCategories
        : props.lists.licenseTypes;

    setDropDownList(list);

    if (props.categorizationData) {
      const ct = props.categorizationData;
      if (props.modalType === MODAL_TYPE.categorization) {
        setSubCategoryId({ subCategoryId: ct.subCategoryId, subCategoryName: ct.subCategoryName });
      } else {
        setLicenseTypeId({ licenseTypeId: ct.licenseTypeId, licenseType: ct.licenseType });
      }
    }
  }, [props]);

  const handleInput = (evt) => {
    const {
      target: { name }
    } = evt;
    const newValue = evt.target.value;
    setFormInput({ [`${name}`]: newValue });
  };

  const handleChange = (evt, newValue) => {
    const {
      target: { id }
    } = evt;
    if (id.includes('licenseType')) setLicenseTypeId(newValue);
    if (id.includes('subCategory')) setSubCategoryId(newValue);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleSave = (evt) => {
    evt.preventDefault();

    let url;
    const lineId = props.categorizationData?.productLineId;
    const id =
      props.modalType === MODAL_TYPE.categorization
        ? props.categorizationData.subCategoryId
        : props.categorizationData.licenseTypeId;

    if (props.modalType === MODAL_TYPE.categorization) {
      const id = subCategoryId.subCategoryId;
      url = `/Reports/SetProductLineSubCategory?ProductLineId=${lineId}&SubCategoryId=${id}`;
    } else if (props.modalType === MODAL_TYPE.license) {
      const id = licenseTypeId.licenseTypeId;
      url = `/Reports/SetProductLineLicenseType?ProductLineId=${lineId}&SubCategoryId=${id}`;
    } else if (props.modalType === MODAL_TYPE.msrp) {
      const val = formInput.msrp;
      url = `/Reports/SetProductLineMSRP?ProductLineId=${lineId}&MSRP=${val}`;
    }

    setLoading(true);
    postCategorizationModal(url)
      .then((response) => {
        setLoading(false);
        if (response.status === true && !response.errors) {
          setOpen(true);
          props.close();
          props.update();
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // const getOptions = (list) => {
  //   let options = '';
  //   if (props.modalType === MODAL_TYPE.categorization) {
  //     options = list.map((item) => (
  //       <option value={item.subCategoryId} className={classes.selectOptions}>
  //         {item.subCategoryName}
  //       </option>
  //     ));
  //   } else if (props.modalType === MODAL_TYPE.license) {
  //     options = list.map((item) => (
  //       <option value={item.licenseTypeId} className={classes.selectOptions}>
  //         {item.licenseType}
  //       </option>
  //     ));
  //   }

  //   return options;
  // };

  const getInputControl = () => {
    let control = '';
    if (props.modalType === MODAL_TYPE.categorization) {
      control = (
        <Autocomplete
          disablePortal
          id="subCategoryId"
          getOptionLabel={(option) => option?.subCategoryName || ''}
          options={dropDownList}
          value={subCategoryId}
          renderOption={(props, option) => (
            <span
              key={option.label}
              {...props}
            >{`${option.subCategoryName} > ${option.categoryName}`}</span>
          )}
          onChange={handleChange}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <input
                name="subCategoryId"
                type="text"
                style={{
                  width: 400,
                  height: 25,
                  background: 'url(assets/caret_down.png) right 4px center no-repeat scroll'
                }}
                {...params.inputProps}
              />
            </div>
          )}
        />

        // <NativeSelect
        //   sx={{ mt: 1 }}
        //   defaultValue={
        //     props.modalType === MODAL_TYPE.categorization
        //       ? props.categorizationData.subCategoryId
        //       : props.categorizationData.licenseTypeId
        //   }
        //   inputProps={{
        //     name: 'SubCategoryId',
        //     id: 'SubCategoryId'
        //   }}
        //   className={clsx(classes.selectDropDialog, classes.selectBordered)}
        //   onChange={handleInput}
        // >
        //   {getOptions(dropDownList)}
        // </NativeSelect>
      );
    } else if (props.modalType === MODAL_TYPE.license) {
      control = (
        <Autocomplete
          disablePortal
          id="licenseTypeId"
          getOptionLabel={(option) => option?.licenseType || ''}
          options={dropDownList}
          value={licenseTypeId}
          onChange={handleChange}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <input
                name="licenseTypeId"
                type="text"
                style={{
                  width: 400,
                  height: 25,
                  background: 'url(assets/caret_down.png) right 4px center no-repeat scroll'
                }}
                {...params.inputProps}
              />
            </div>
          )}
        />
      );
    } else if (props.modalType === MODAL_TYPE.msrp) {
      control = (
        <Box sx={{ display: 'flex', mt: 1 }}>
          <Typography sx={{ mt: '-3px', mr: '4px' }}>$</Typography>
          <Input
            fullWidth
            required
            defaultValue={props.categorizationData?.msrp}
            type="number"
            name="msrp"
            id="msrp"
            onChange={handleInput}
            className={classes.simpleInput}
          />
        </Box>
      );
    }

    return control;
  };

  return (
    <>
      <Dialog
        // open={open}
        PaperProps={{ style: { overflowY: 'visible' } }}
        open={props.open}
        onClose={props.close}
        className={clsx(classes.root)}
      >
        <DialogTitle>
          {props.modalData.title}
          <IconButton aria-label="close" onClick={props.close} className={classes.dialogClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ overflowY: 'visible' }}>
          <Typography variant="h6">{props.modalData.selectLabel}</Typography>
          <form>
            <FormControl fullWidth>{dropDownList && getInputControl()}</FormControl>
          </form>
        </DialogContent>
        <Divider variant="middle" />
        <Box sx={{ p: '10px 20px', textAlign: 'right' }}>
          <BtnLight onClick={handleSave} variant="contained" size="small">
            {props.modalData.btnLabel}
          </BtnLight>
        </Box>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={handleClose}
          invisible
        >
          {/* <Grow easing={{ enter: 'cubic-bezier(0, 2.5, .8, 1)' }} in={loading} timeout={800}>
            <Box sx={{ backgroundColor: 'white !important' }} className={classes.overlay}>
              <Typography sx={{ color: '#364150 !important' }} className={classes.overlayText}>
                <CircularProgress size={16} sx={{ mr: 1 }} /> Saving data...
              </Typography>
            </Box>
          </Grow> */}
          <Overlay>Loading...</Overlay>
        </Backdrop>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: '100%', background: '#2196f3' }}
        >
          Saved successfully
        </Alert>
      </Snackbar>
    </>
  );
}
