import React, { useState } from 'react';
import * as clsx from 'clsx';
// material
import { Stack, Typography, IconButton, Grid, Box } from '@material-ui/core';
// icons
import SettingsIcon from '@material-ui/icons/Settings';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ViewListIcon from '@material-ui/icons/ViewList';
import PrintIcon from '@material-ui/icons/Print';
import SoftwareState from './softwareState';
import useStyles from '../../../../styles';
import NewAndPastDevices from '../../../../components/modals/NewAndPastDevices';

// control component used in product line devices
export default function Controls(props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className={classes.controlsContainer}
      >
        {/* left icon buttons */}
        <Grid sx={{ display: 'flex' }}>
          <IconButton
            aria-label="delete"
            className={classes.iconBtn}
            sx={{ width: 165 }}
            disableRipple
          >
            <SettingsIcon className={classes.selectIcon} />
            <SoftwareState
              refreshDeviceData={props.refreshDeviceData}
              selectedRows={props.selectedRows}
              getStatus={props.getStatus}
              gridApi={props.gridApi}
            />
          </IconButton>
          <IconButton
            aria-label="delete"
            className={classes.iconBtn}
            disableRipple
            onClick={() => setOpen(true)}
          >
            <ImportExportIcon className={classes.iconBtnIcon} />
            <Typography variant="p" className={classes.mlHalf}>
              Inventory
            </Typography>
          </IconButton>
        </Grid>
        {/* right icon buttons */}
        <Grid>
          <IconButton
            onClick={props.exportToExcel}
            aria-label="export"
            className={classes.iconBtn}
            disableRipple
          >
            <img alt="export" src="/assets/icons/excel_export.png" />
            <Typography variant="p" className={classes.mlHalf}>
              Export
            </Typography>
          </IconButton>
          {/* <IconButton aria-label="delete" className={classes.iconBtn} disableRipple>
            <PrintIcon className={classes.iconBtnIcon} />
            <Typography variant="p" className={classes.mlHalf}>
              Print
            </Typography>
          </IconButton> */}
        </Grid>
      </Stack>
      <Box>
        <NewAndPastDevices open={open} setOpen={setOpen} />
      </Box>
    </>
  );
}
