// material
import React, { useEffect, useState, useReducer } from 'react';
import { Box, Autocomplete, Container, TextField, Typography } from '@material-ui/core';
// components
import Page from '../../components/Page';
import useStyles from '../../styles';
import CrossReferenceGrid from '../../components/grids/CrossReferenceGrid';
import Controls from './components/controls';
import {
  getStatuses,
  getSubCategories,
  getAllProductLines,
  getCrossReferenceReport
} from '../../server';

export default function SoftwareByLicenseType() {
  const classes = useStyles();
  const [selectedRows, setSelectedRows] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [dStatuses, setdStatuses] = useState({});
  const [rowData, setRowData] = useState([]);
  const [dSubCategories, setdSubCategories] = useState({});
  const [productLines, setProductLines] = useState([]);
  const [productLineId1, setProductLineId1] = useState({});
  const [productLineId2, setProductLineId2] = useState({});
  const [btnValue, setBtnValue] = useState('');

  const handleChange = (event, newValue) => {
    const {
      target: { id }
    } = event;
    if (id.includes('productLineId1')) setProductLineId1(newValue);
    if (id.includes('productLineId2')) setProductLineId2(newValue);
  };

  useEffect(() => {
    const promises = [getData()];

    if (Object.keys(dStatuses).length === 0) {
      promises.push(getStatuses());
    }
    if (Object.keys(dSubCategories).length === 0) {
      promises.push(getSubCategories());
    }
    if (productLines.length === 0) {
      promises.push(getAllProductLines());
    }

    Promise.all(promises).then(([data, statuses, subCategories, productLinesData]) => {
      if (statuses) {
        setdStatuses(mapStatuses(statuses));
      }
      if (subCategories) {
        setdSubCategories(mapSubCategories(subCategories));
      }
      if (productLinesData) {
        setProductLines(productLinesData.data?.reportList);
      }
      if (data) {
        updateData(data);
      }
    });
  }, []);

  useEffect(() => {
    if (
      (productLineId1 && productLineId1.productlineid) ||
      (productLineId2 && productLineId2.productlineid)
    ) {
      getData();
    }
  }, [productLineId1, productLineId2, status, btnValue]);

  const getData = async () => {
    if (
      productLineId1 &&
      productLineId1.productlineid &&
      productLineId2 &&
      productLineId2.productlineid &&
      btnValue === 'run'
    ) {
      getCrossReferenceReport(productLineId1, productLineId2)
        .then((data) => updateData(data))
        .catch(() => {
          updateData([]);
        });
    }
    setBtnValue('');
  };

  const updateData = (data) => {
    if (data?.data) {
      setRowData(data.data.crossReferenceReport);
    }
  };

  const propData = {
    rowData,
    dStatuses,
    dSubCategories
  };

  return (
    <Page title="Reports | AssetLabs" className={classes.reportsBody}>
      <Container className={classes.fullWidth}>
        <div className={classes.cardContainer}>
          <Typography variant="h6">Cross Reference Software Titles</Typography>
          <Typography variant="p">
            Add any 2 Software Titles to identify devices where both software exist on the same
            device
          </Typography>
          <Box sx={{ display: 'flex', my: 2 }}>
            <Box>
              <Typography variant="h5" sx={{ fontWeight: 500 }}>
                Select Software Title <span style={{ fontWeight: 600 }}>A</span>
              </Typography>
              <Autocomplete
                disablePortal
                id="productLineId1"
                getOptionLabel={(option) => option?.productline || ''}
                options={productLines}
                value={productLineId1}
                onChange={handleChange}
                disabled={productLines.length === 0}
                renderInput={(params) => (
                  <div ref={params.InputProps.ref}>
                    <input
                      name="productLineId1"
                      type="text"
                      style={{ width: 400, height: 25 }}
                      {...params.inputProps}
                    />
                  </div>
                )}
              />
            </Box>
            <Box sx={{ ml: 4 }}>
              <Typography variant="h5" sx={{ fontWeight: 500 }}>
                Select Software Title <span style={{ fontWeight: 600 }}>B</span>
              </Typography>
              <Autocomplete
                disablePortal
                id="productLineId2"
                getOptionLabel={(option) => option?.productline || ''}
                options={productLines}
                value={productLineId2}
                onChange={handleChange}
                disabled={productLines.length === 0}
                renderInput={(params) => (
                  <div ref={params.InputProps.ref}>
                    <input
                      name="productLineId2"
                      type="text"
                      style={{ width: 400, height: 25 }}
                      {...params.inputProps}
                    />
                  </div>
                )}
              />
            </Box>
            <button
              type="button"
              style={{ height: 25, width: 50, marginTop: 'auto', marginLeft: 10 }}
              onClick={() => setBtnValue('run')}
            >
              Run
            </button>
          </Box>
          <Controls selectedRows={selectedRows} getStatus={(status) => setStatus(status)} />
          <CrossReferenceGrid
            {...propData}
            getSelectedRows={(selectedRows) => {
              setSelectedRows(selectedRows);
            }}
          />
        </div>
      </Container>
    </Page>
  );
}

function mapStatuses(statuses) {
  const obj = {};
  statuses.data.subCategory.forEach((x) => {
    obj[x.statusId] = x.value;
  });
  return obj;
}

function mapSubCategories(subCategories) {
  const obj = {};
  subCategories.data.subCategory.forEach((x) => {
    obj[x.subCategoryId] = x.subCategoryName;
  });
  return obj;
}
