import PropTypes from 'prop-types';
// material
import { Button } from '@material-ui/core';
import useStyles from '../../styles';

// ----------------------------------------------------------------------

export default function BtnLight({ children, ...rest }) {
  const classes = useStyles();
  return (
    <Button className={classes.btnLight} {...rest}>
      {children}
    </Button>
  );
}

BtnLight.propTypes = {
  children: PropTypes.string.isRequired
};
