import React, { useState, useEffect, useContext } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import productsGridFolderNode from './productsGridFolderNode';
import productsGridCustomHeader from './productsGridCustomHeader';
import { formatter, deFormat } from '../../helpers';
import PublisherFilter from '../../pages/reports/components/PublisherFilter';
import PublisherFloatingFilter from '../../pages/reports/components/PublisherFloatingFilter';
import { publishers, softwares, REPORTS } from '../../constants/reports';
import '../../styles/css/Grid.css';

// const publishers = [12, '12a', '12b'];

// const REPORTS = {
//   BusinessApp_Auditable: 0,
//   BusinessApp_NonAuditable: 1,
//   EndUserDistraction: 2,
//   AllAuditable: 3,
//   LineOfBusiness: 4,
//   AllMicrosoft: 5,
//   MicrosoftAuditable: 6,
//   AdobeAuditable: 7,
//   PeerToPeer: 8,
//   Games: 9,
//   Malware: 10,
//   OperatingSystems: 11,
//   PublishersAtoZ: 12,
//   PublishersByInstallCount: '12a',
//   PublishersByFinancialFootprint: '12b',
//   SoftwareByCategory: '12c',
//   SoftwareByLicenseType: '12d',
//   IBMAuditable: 13
// };

export default function ReportsGrid(props) {
  return (
    <AgGridReact
      className={publishers.includes(props.report) ? 'publishersGrid' : 'reportsGrid'}
      components={{
        productsGridFolderNode
      }}
      frameworkComponents={{
        customPublisherFilter: PublisherFilter,
        customPublisherFloatingFilter: PublisherFloatingFilter,
        customHeader: productsGridCustomHeader
      }}
      headerHeight="25"
      floatingFiltersHeight="31"
      groupHeaderHeight="25"
      rowHeight="25"
      suppressAggFuncInHeader="true"
      // rowMultiSelectWithClick={false}
      // rowSelection="single"
      icons={{
        groupExpanded: '<img src="/assets/minus.gif"/>',
        groupContracted: '<img src="/assets/plus.gif"/>'
      }}
      defaultColDef={{
        flex: 1,
        sortable: true,
        resizable: true,
        filter: true,
        floatingFilter: true,
        menuTabs: ['filterMenuTab', 'generalMenuTab'],
        // minWidth: 150,
        cellStyle: (params) => {
          const styles = {};
          if (
            params.node?.field === 'productFamily' ||
            params.node?.field === 'commonName' ||
            params.node?.field === 'productLine'
          ) {
            styles['font-size'] = '12px';
            if (params.node?.field !== 'productLine') {
              styles['font-weight'] = '500 !important';
            }
          }

          return styles;
        }
      }}
      autoGroupColumnDef={{
        flex: 1,
        minWidth: 300,
        headerName: 'Product-Line',
        floatingFilterComponent: 'customPublisherFloatingFilter',
        floatingFilterComponentParams: { suppressFilterButton: true },
        filter: 'customPublisherFilter',
        valueGetter: (params) => {
          const model = params.data.model ? `(${params.data.model})` : '';
          const displayVal = `${params.data.productLine} ${model} ${params.data.majorVersion}`;
          return displayVal;
        },
        field: 'productName',
        cellRendererParams: {
          suppressCount: true
        }
      }}
      skipHeaderOnAutoSize
      autoSizePadding="0"
      groupDefaultExpanded={
        props.report === REPORTS.MicrosoftAuditable ||
        props.report === REPORTS.AllMicrosoft ||
        props.report === REPORTS.PeerToPeer ||
        props.report === REPORTS.Games ||
        props.report === REPORTS.Malware ||
        props.report === REPORTS.OperatingSystems ||
        props.report === REPORTS.AdobeAuditable
          ? 2
          : null
      }
      groupDisplayType="singleColumn"
      animateRows
      onGridReady={props.onGridReady}
      rowData={props.rowData}
      onRowClicked={props.onSelectionChanged}
      showOpenedGroup
    >
      <AgGridColumn
        headerName="Publisher"
        field="commonName"
        cellRenderer="productsGridFolderNode"
        rowGroup
        hide
      />
      {/* {props.report === REPORTS.AllMicrosoft ||
      props.report === REPORTS.MicrosoftAuditable ||
      props.report === REPORTS.AdobeAuditable ? (
        
      <></>) : null} */}
      <AgGridColumn field="productFamily" cellRenderer="productsGridFolderNode" rowGroup hide />
      <AgGridColumn field="productLine" rowGroup hide />
      <AgGridColumn field="productName" hide filter="agSetColumnFilter" />
      {props.report === REPORTS.PublishersByFinancialFootprint && (
        <AgGridColumn
          field="msrp"
          headerClass="gridMenuHidden"
          comparator={(valueA, valueB, nodeA, nodeB, isInverted) => {
            valueA = deFormat(valueA);
            valueB = deFormat(valueB);
            if (valueA === valueB) return 0;
            return valueA > valueB ? 1 : -1;
          }}
          minWidth={props.report === REPORTS.PublishersByFinancialFootprint ? 100 : 70}
          headerName="Financial"
          cellStyle={() => ({ textAlign: 'right', color: '#9c9b9b' })}
          filter={false}
          valueGetter={(params) => formatter.format(parseInt(params.data?.msrp, 10))}
          aggFunc={(params) => {
            let val = '';
            if (
              props.report === REPORTS.PublishersByFinancialFootprint ||
              params.rowNode.field === 'productLine'
            ) {
              let sum = 0;
              params.values.forEach((x) => {
                if (!Number.isNaN(deFormat(x))) {
                  sum += deFormat(x);
                }
              });
              val = sum;
            }

            return formatter.format(val);
            // return Number.isNaN(val) === false ? formatter.format(val) : val;
          }}
        />
      )}
      <AgGridColumn
        field="quantity"
        minWidth={props.report === REPORTS.PublishersByInstallCount ? 60 : 65}
        headerName="Count"
        headerComponent="customHeader"
        headerComponentParams={{ inventoryDate: props.inverntoryDates?.currentInventoryDate }}
        headerClass="gridMenuHidden"
        headerTooltip="Count is the raw count of installs from your inventory tool which may may include duplication of installs"
        cellStyle={() => ({ textAlign: 'right', color: '#9c9b9b' })}
        filter={false}
        // filter="agSetColumnFilter"
        aggFunc={(params) => {
          let val = '';
          // if (
          //   props.report === REPORTS.PublishersByInstallCount ||
          //   props.report === REPORTS.OperatingSystems ||
          //   params.rowNode.field === 'productLine'
          // ) {

          // }

          let sum = 0;
          params.values.forEach((value) => (sum += parseInt(value, 10)));
          val = sum;

          return val;
        }}
      />
      <AgGridColumn
        minWidth={70}
        field="prevQuantity"
        headerClass="gridMenuHidden"
        headerComponent="customHeader"
        headerComponentParams={{ inventoryDate: props.inverntoryDates?.previousInventoryDate }}
        headerName="Change"
        filter={false}
        // filter="agSetColumnFilter"
        cellRenderer={(params) => {
          let val = params.value;
          const img = '<img width="23" alt="export" src="/assets/new.gif" />';
          if (val === 'NEW') {
            val = `<span>${img}</span>`;
          } else {
            val = `<span>${val}</span>`;
          }
          return val;
        }}
        cellStyle={(params) => {
          let delta;
          if (params.data) {
            if (!params.data.prevQuantity) {
              delta = null;
            } else {
              delta = params.data.quantity - params.data.prevQuantity;
            }
          } else {
            delta = params.node?.aggData?.prevQuantity;
          }

          let styleObj;
          if (delta > 0) {
            styleObj = { color: 'green' };
          } else if (delta === 'NEW' || delta === 0 || delta === null) {
            styleObj = { color: 'red', fontSize: '9px' };
          } else if (delta < 0) {
            styleObj = { color: 'red' };
          }

          return { ...{ textAlign: 'right' }, ...styleObj };
        }}
        valueGetter={(params) => getDeltaValue(params.data?.quantity, params.data?.prevQuantity)}
        aggFunc={(params) => {
          let value = '';
          if (params.rowNode.field === 'productLine') {
            let sumPrevQuantity = 0;
            let sumQuantity = 0;

            params.rowNode.allLeafChildren?.forEach((node) => {
              if (node?.data) {
                sumPrevQuantity += node.data.prevQuantity;
                sumQuantity += node.data.quantity;
              }
            });

            value = getDeltaValue(sumQuantity, sumPrevQuantity);
          }

          return value;
        }}
      />
      <AgGridColumn
        field="deviceLicenseCount"
        minWidth={55}
        headerClass="gridMenuHidden"
        headerName="Effective Count"
        headerTooltip="Effective count is the total count removing duplication and non-production instances. It is the count used for determining licensable quantities."
        filter={false}
        cellStyle={{ textAlign: 'right' }}
        aggFunc={(params) => {
          let val = '';
          if (
            props.report === REPORTS.PublishersByInstallCount ||
            params.rowNode.field === 'productLine'
          ) {
            let sum = 0;
            params.values.forEach((value) => (sum += parseInt(value, 10)));
            val = sum;
          }

          return val;
        }}
      />
      <AgGridColumn
        field="licenseType"
        minWidth={100}
        filter="agSetColumnFilter"
        cellStyle={(params) => {
          let val = null;
          if (params.node?.field !== 'productLine') {
            val = { opacity: '0' };
          }

          return val;
        }}
        aggFunc={(params) => {
          let val = '';
          if (params.rowNode.field === 'productLine') {
            [val] = params.values;
          }

          return val;
        }}
      />
      <AgGridColumn headerName="Category" headerClass="category-header">
        <AgGridColumn
          field="subCategoryName"
          width={200}
          headerName="SubCategory"
          minWidth="120"
          filter="agSetColumnFilter"
          cellStyle={(params) => {
            let val = null;
            if (params.node?.field !== 'productLine') {
              val = { opacity: '0' };
            }

            return val;
          }}
          aggFunc={(params) => {
            let val = '';
            if (params.rowNode.field === 'productLine') {
              [val] = params.values;
            }

            return val;
          }}
        />
        <AgGridColumn
          field="categoryName"
          headerName="Category"
          columnGroupShow="open"
          minWidth="120"
          filter="agSetColumnFilter"
          cellStyle={(params) => {
            let val = null;
            if (params.node?.field !== 'productLine') {
              val = { opacity: '0' };
            }

            return val;
          }}
          aggFunc={(params) => {
            let val = '';
            if (params.rowNode.field === 'productLine') {
              [val] = params.values;
            }

            return val;
          }}
        />
      </AgGridColumn>
    </AgGridReact>
  );
}

function getDeltaValue(quantity, prevQuantity) {
  let value;
  if (prevQuantity === null || prevQuantity === undefined) {
    value = 'NEW';
  } else {
    const diff = quantity - prevQuantity;
    if (diff > 0) {
      value = `+${diff}`;
    } else if (diff === 0) {
      value = '-';
    } else {
      value = diff;
    }
  }

  return value;
}
