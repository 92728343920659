import * as React from 'react';
// material
import {
  Stack,
  Container,
  Paper,
  Box,
  Typography,
  Tab,
  Link,
  Breadcrumbs,
  styled
} from '@material-ui/core';
import { TabContext, TabList, Tabs } from '@material-ui/lab';
import MuiTabPanel from '@material-ui/lab/TabPanel';
import { withStyles } from '@material-ui/styles';
// components
import AccountMembers from './components/AccountMembers';
import AccountInfo from './components/AccountInfo';
import Page from '../../../components/Page';
import useStyles from '../../../styles';

// const Tabs = withStyles({
//   fixed: {
//     height: 30
//   }
// })(MuiTabs);

const TabPanel = withStyles({
  root: {
    padding: 0
  }
})(MuiTabPanel);

export default function PageFunc() {
  const classes = useStyles();
  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const breadcrumbs = [
    <Link underline="none" key="1" color="inherit" fontSize="small">
      Admin
    </Link>,
    <Typography key="3" color="text.primary" fontSize="small">
      Manage Account
    </Typography>
  ];
  return (
    <Page title="Manage Account | AssetLabs" className={classes.reportsBody}>
      <Container className={classes.fullWidth}>
        <div className={classes.cardContainer}>
          <Typography variant="h6">Manage Account</Typography>
          <Breadcrumbs sx={{ mb: 1, mt: 0.2 }} separator=">" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
          <Box className={classes.tabsContainer}>
            <TabContext value={value} sx={{ height: 30 }}>
              <Box>
                <TabList onChange={handleChange} className={classes.tabsPanel}>
                  <Tab label="Account Members" value="1" className={classes.accountTab} />
                  <Tab label="Account Information" value="2" className={classes.accountTab} />
                </TabList>
              </Box>
              <TabPanel value="1">
                <AccountMembers />
              </TabPanel>
              <TabPanel value="2">
                <AccountInfo />
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </Container>
    </Page>
  );
}
