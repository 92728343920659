// import MuiTypography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Input from '@material-ui/core/Input';
// import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import { styled, withStyles } from '@material-ui/styles';
import BtnDanger from '../../../buttons/ButtonDanger';

const User = styled('div')({
  width: '31%',
  height: 80,
  marginTop: 10,
  marginLeft: 10,
  background: '#eee',
  paddingTop: 10,
  paddingLeft: 10,
  '&:hover': {
    background: '#ddd'
  }
});

const ariaLabel = { 'aria-label': 'description' };

export default function AccountAccess(props) {
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex', mt: 2, mb: 1 }}>
          <Box sx={{ minWidth: 120, width: '20%' }}>
            <FormControl fullWidth>
              {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Age
            </InputLabel> */}
              <NativeSelect
                defaultValue={30}
                inputProps={{
                  name: 'age',
                  id: 'uncontrolled-native'
                }}
              >
                <option value={10}>Ten</option>
                <option value={20}>Twenty</option>
                <option value={30}>Thirty</option>
              </NativeSelect>
            </FormControl>
          </Box>
          <Input sx={{ ml: 2, minWidth: 120, width: '80%' }} inputProps={ariaLabel} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <User>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <input type="checkbox" />
              <Box sx={{ ml: 2 }}>
                <div>Umar Munir</div>
                <div style={{ fontSize: 11 }}>
                  Devices: <span>26,467</span>
                </div>
                <div style={{ fontSize: 11 }}>
                  Date: <span>11/25/2021</span>
                </div>
              </Box>
            </Box>
          </User>
          <User>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <input type="checkbox" />
              <Box sx={{ ml: 2 }}>
                <div>Umar Munir</div>
                <div style={{ fontSize: 11 }}>
                  Devices: <span>26,467</span>
                </div>
                <div style={{ fontSize: 11 }}>
                  Date: <span>11/25/2021</span>
                </div>
              </Box>
            </Box>
          </User>
          <User>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <input type="checkbox" />
              <Box sx={{ ml: 2 }}>
                <div>Umar Munir</div>
                <div style={{ fontSize: 11 }}>
                  Devices: <span>26,467</span>
                </div>
                <div style={{ fontSize: 11 }}>
                  Date: <span>11/25/2021</span>
                </div>
              </Box>
            </Box>
          </User>
          <User>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <input type="checkbox" />
              <Box sx={{ ml: 2 }}>
                <div>Umar Munir</div>
                <div style={{ fontSize: 11 }}>
                  Devices: <span>26,467</span>
                </div>
                <div style={{ fontSize: 11 }}>
                  Date: <span>11/25/2021</span>
                </div>
              </Box>
            </Box>
          </User>
        </Box>
      </Box>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 2 }}>
        <BtnDanger>Delete Member</BtnDanger>
      </Box>
    </>
  );
}
