import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import NativeSelect from '@material-ui/core/NativeSelect';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/core/Alert';
import useStyles from '../../../../styles';
import {
  getStatuses,
  UpdateDevicesSoftwareState,
  UpdateProductLineDeviceSoftwareState
} from '../../../../server';

const Alert = React.forwardRef((contextData, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...contextData} />
));

export default function Sof(props) {
  const classes = useStyles();
  const [rowData, setRowData] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [defaultValue, setDefaultValue] = useState('default');
  const array = [];

  useEffect(() => {
    // setRowData(null);
    getStatuses().then((data) => updateData(data));
  }, []);

  const showToastWithText = (text) => {
    if (text) setAlertText(text);
    setTimeout(() => {
      setShowToast(true);
    }, 0);
  };

  const handleChange = (event) => {
    const label = event?.target?.options[event?.target?.selectedIndex]?.label;
    const selectedRows = props.gridApi.getSelectedRows();
    const deviceARPIdArr = [];
    for (let i = 0; i < selectedRows?.length; i += 1) {
      if (selectedRows[i]?.deviceARPId) {
        deviceARPIdArr.push(selectedRows[i]?.deviceARPId);
      }
    }

    showToastWithText('Updating...');
    if (props.grid === 'allDevices') {
      UpdateDevicesSoftwareState(event.target.value, deviceARPIdArr).then((data) => {
        if (data.status === true) {
          updateGrid(label, deviceARPIdArr, props.gridApi);
          showToastWithText('Status has been updated');
        }
      });
    } else {
      // DeviceAprIDs
      UpdateProductLineDeviceSoftwareState(event.target.value, deviceARPIdArr).then((data) => {
        if (data.status === true) {
          updateGrid(label, deviceARPIdArr, props.gridApi);
          showToastWithText('Status has been updated');
        }
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  const updateData = (data) => {
    for (let i = 0; i < data?.data?.subCategory.length; i += 1) {
      if (
        !array.includes(data?.data?.subCategory[i].value) &&
        data.data.subCategory[i].section === 'SoftwareDeviceState' &&
        props.grid !== 'allDevices'
      ) {
        array.push({
          id: data?.data?.subCategory[i].statusId,
          value: data?.data?.subCategory[i].value
        });
      }
      if (
        !array.includes(data?.data?.subCategory[i].value) &&
        data?.data?.subCategory[i].section === 'HardwareStates' &&
        props.grid === 'allDevices'
      ) {
        array.push({
          id: data?.data?.subCategory[i].statusId,
          value: data?.data?.subCategory[i].value
        });
      }
    }
    setRowData(array);
  };

  return (
    <>
      <NativeSelect
        onChange={handleChange}
        name="softwareState"
        inputProps={{ 'aria-label': 'softwareState' }}
        value={defaultValue}
        defaultValue={defaultValue}
        className={clsx(classes.mlHalf, classes.selectDrop)}
      >
        <option disabled selected value="default">
          {props.grid === 'allDevices' ? 'Set Hardware States' : 'Set Software States'}
        </option>
        {rowData?.map((x) => (
          <option value={x.id}>{x.value}</option>
        ))}
      </NativeSelect>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={showToast}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: '100%', background: '#2196f3' }}
        >
          {alertText}
        </Alert>
      </Snackbar>
    </>
  );
}

function updateGrid(label, deviceARPIdArr, gridApi) {
  if (label) {
    gridApi.forEachNode((rowNode) => {
      if (deviceARPIdArr.includes(rowNode.data.deviceARPId)) {
        rowNode.setDataValue('statusId', label);
      }
    });
  }
}
