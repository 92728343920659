import React, { useEffect, useState, useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import {
  Box,
  Breadcrumbs,
  Link,
  Container,
  Typography,
  Dialog as MuiDialog
} from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
// ag-grid
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
// components
import Controls from './components/controls';
import DeviceSummary from '../../components/modals/DeviceSummary';
import { ReportContext } from '../../context/ReportContext';
import Page from '../../components/Page';
import useStyles from '../../styles';
import { formatDate } from '../../helpers';
import { getDeviceListByPulseIdAndType, getStatuses, getSubCategories } from '../../server';
import Overlay from '../../components/Overlay';

const deltaIndicator = (params) => {
  const element = document.createElement('span');
  const imageElement = document.createElement('img');
  const dataSpan = document.createElement('span');
  const data = document.createTextNode(params.value);
  if (params.data.subCategoryID === 261) {
    imageElement.src = '/assets/client_icon_grid.png';
  } else {
    imageElement.src = '/assets/server_icon_grid.png';
  }
  dataSpan.appendChild(data);
  element.appendChild(imageElement);
  element.appendChild(dataSpan);
  imageElement.classList.add('gridIcon');
  dataSpan.classList.add('gridSpan');
  return element;
};

const Button = withStyles({
  root: {
    // display: 'none'
  }
})(MuiButton);

const activeTab = {
  borderRadius: '5px 5px 0 0',
  marginRight: 0.75,
  marginBottom: 0,
  fontWeight: 400,
  fontSize: 14,
  minHeight: 10,
  maxHeight: 30,
  lineHeight: 1,
  boxShadow: '2px -7px 8px -7px rgba(99, 99, 99, 0.3)',
  background: '#F8F8F8',
  color: '#000000'
};

const inactiveTab = {
  borderRadius: '5px 5px 0 0',
  marginRight: 0.75,
  marginBottom: 0,
  color: '#000000',
  fontWeight: 400,
  fontSize: 14,
  minHeight: 10,
  maxHeight: 30,
  lineHeight: 1,
  background: '#9CB9D0',
  boxShadow: '2px -7px 8px -7px rgba(99, 99, 99, 0.3)'
};

export default function SoftwareByLicenseType(props) {
  const { pathname } = useLocation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [arpId, setArpId] = useState(0);
  const [rowIndex, setRowIndex] = useState(null);
  const [dStatuses, setdStatuses] = useState({});
  const [rowData, setRowData] = useState(null);
  const [gridApi, setGridApi] = useState();
  const [dSubCategories, setdSubCategories] = useState({});
  const [reportType, setReportType] = useState(null);
  const [deviceId, setDeviceId] = useState(0);
  const [url, setUrl] = useState(props.url);
  const [fullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    refreshDeviceData();
  }, [url]);

  const Dialog = withStyles(
    fullScreen
      ? {
          container: {
            height: 'unset !important'
          },
          paper: {
            // minWidth: '97%',
            minWidth: '100%',
            height: '100vh !important',
            margin: '0px !important'
          }
        }
      : {
          paper: {
            minWidth: '97%'
          }
        }
  )(MuiDialog);

  const handleFullScreen = () => {
    setFullScreen(true);
  };

  const handleFullScreenExit = () => {
    setFullScreen(false);
  };

  const setReportParams = (params) => {
    setGridApi(params.api);
  };

  const refreshDeviceData = () =>
    new Promise((resolve, reject) => {
      const promises = [getDeviceListByPulseIdAndType(url)];

      if (Object.keys(dStatuses).length === 0) {
        promises.push(getStatuses());
      }
      if (Object.keys(dSubCategories).length === 0) {
        promises.push(getSubCategories());
      }

      Promise.all(promises).then(([data, statuses, subCategories]) => {
        let mapedStatuses;
        if (statuses) {
          mapedStatuses = mapStatuses(statuses);
          setdStatuses(mapedStatuses);
        } else {
          mapedStatuses = dStatuses;
        }
        if (subCategories) {
          setdSubCategories(mapSubCategories(subCategories));
        }
        if (data?.data?.model_list) {
          updateData(
            data.data.model_list.map((item) => {
              item.statusId = mapedStatuses[item.statusId];
              return item;
            })
          );
        }

        resolve();
      });
    });

  const updateData = (data) => {
    if (data.data !== null || data.data !== undefined) {
      setRowData(data);
    }
  };

  const exportToExcel = () => {
    if (gridApi) {
      gridApi.exportDataAsExcel({
        fileName: `Devices.xlsx`
      });
    }
  };

  const externalFilterChanged = (newValue) => {
    switch (newValue) {
      case 'clients': {
        setReportType('Clients');
        setUrl('Reports/GetDeviceListByPulseIdAndType?deviceType=Clients');
        setRowData(null);
        break;
      }
      case 'servers': {
        setReportType('Servers');
        setUrl('Reports/GetDeviceListByPulseIdAndType?deviceType=Servers');
        setRowData(null);
        break;
      }
      case 'unknownOS': {
        setReportType('Unknown OS');
        setUrl('Reports/GetDeviceListByPulseIdAndType?deviceType=OS');
        setRowData(null);
        break;
      }
      default:
        setUrl('Reports/GetDeviceListByPulseIdAndType?deviceType=All');
    }
  };

  const breadcrumbs = [
    <Link underline="none" key="1" color="inherit" fontSize="small">
      Devices
    </Link>,
    <Typography key="3" color="text.primary" fontSize="small">
      {reportType === null ? props.name : reportType}
    </Typography>
  ];

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Page title="Reports | AssetLabs" className={classes.reportsBody}>
      <Container className={classes.fullWidth}>
        <div className={classes.cardContainer}>
          <Typography variant="h6">{reportType === null ? props.name : reportType}</Typography>
          <Breadcrumbs sx={{ mb: 1, mt: 0.2 }} separator=">" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
          {/* {rowData ? ( */}
          <>
            <Box>
              <Button
                component={RouterLink}
                to="/devices/all"
                disableRipple
                sx={
                  pathname.includes('/all') && reportType !== 'Unknown OS' ? activeTab : inactiveTab
                }
                onClick={() => externalFilterChanged('all')}
                disabled={rowData === null}
              >
                All
              </Button>
              <Button
                component={RouterLink}
                to="/devices/clients"
                disableRipple
                sx={
                  pathname.includes('/clients') && reportType !== 'Unknown OS'
                    ? activeTab
                    : inactiveTab
                }
                onClick={() => externalFilterChanged('clients')}
                disabled={rowData === null}
              >
                Clients
              </Button>
              <Button
                component={RouterLink}
                to="/devices/servers"
                disableRipple
                sx={
                  pathname.includes('/servers') && reportType !== 'Unknown OS'
                    ? activeTab
                    : inactiveTab
                }
                onClick={() => externalFilterChanged('servers')}
                disabled={rowData === null}
              >
                Servers
              </Button>
              <Button
                // component={RouterLink}
                // to="/devices/all"
                disableRipple
                sx={reportType === 'Unknown OS' ? activeTab : inactiveTab}
                onClick={() => externalFilterChanged('unknownOS')}
                disabled={rowData === null}
              >
                Unknown OS
              </Button>
            </Box>
            <Controls
              {...{
                exportToExcel,
                grid: 'allDevices',
                refreshDeviceData,
                name: props.name,
                gridApi
              }}
            />
            <div
              style={{
                height: 'calc(100vh - 198px)',
                width: '100%'
              }}
              className="ag-theme-alpine"
            >
              <AgGridReact
                onGridReady={setReportParams}
                components={{
                  deltaIndicator
                }}
                defaultColDef={{
                  flex: 1,
                  minWidth: 80,
                  sortable: true,
                  filter: true,
                  floatingFilter: true,
                  resizable: true
                }}
                // autoGroupColumnDef={{ minWidth: 200 }}
                headerHeight="25"
                floatingFiltersHeight="31"
                rowHeight="25"
                animateRows
                rowSelection="multiple"
                rowData={rowData}
                statusBar={{
                  statusPanels: [
                    {
                      statusPanel: 'agTotalAndFilteredRowCountComponent',
                      align: 'left'
                    }
                  ]
                }}
              >
                <AgGridColumn
                  cellClass="deviceName"
                  field="deviceIdentifier"
                  headerName="Device Name"
                  filter="agTextColumnFilter"
                  headerCheckboxSelection
                  checkboxSelection
                  minWidth={200}
                  onCellClicked={(params) => {
                    setOpen(true);
                    setArpId(params.data.deviceARPId);
                    setRowIndex(params.rowIndex);
                    setDeviceId(params.data.deviceId);
                  }}
                  cellRenderer="deltaIndicator"
                />
                <AgGridColumn
                  field="statusId"
                  headerName="Production State"
                  filter="agSetColumnFilter"
                  // valueGetter={(params) => {
                  //   if (params.data) {
                  //     return dStatuses[params.data?.statusId];
                  //   }
                  // }}
                  // cellStyle={(params) => {
                  //   if (params.data.statusId === 110) {
                  //     return { backgroundColor: '#ffffcc' };
                  //   }
                  // }}
                  cellStyle={{ backgroundColor: '#ffffcc' }}
                />
                <AgGridColumn
                  field="subCategoryID"
                  headerName="OS Type"
                  filter="agSetColumnFilter"
                  valueGetter={(params) => {
                    if (params.data) {
                      return dSubCategories[params.data?.subCategoryID];
                    }
                  }}
                />
                <AgGridColumn field="manufacturer" filter="agSetColumnFilter" minWidth={70} />
                <AgGridColumn field="model" filter="agTextColumnFilter" />
                <AgGridColumn
                  field="operatingSystem"
                  headerName="Operating System"
                  filter="agSetColumnFilter"
                  hide
                />
                <AgGridColumn
                  field="isVirtual"
                  headerName="Machine Type"
                  filter="agTextColumnFilter"
                />
                <AgGridColumn
                  field="serialNumber"
                  headerName="Serial"
                  filter="agTextColumnFilter"
                />
                <AgGridColumn
                  field="cpuCount"
                  headerName="CPU"
                  filter="agNumberColumnFilter"
                  enableValue
                  hide
                />
                <AgGridColumn
                  field="coresPerCPU"
                  headerName="Cores"
                  filter="agNumberColumnFilter"
                  enableValue
                  minWidth={70}
                />
                <AgGridColumn
                  field="birthDate"
                  headerName="Birth date"
                  filter="agDateColumnFilter"
                  valueGetter={(params) =>
                    params.data?.birthDate !== '' ? formatDate(params.data?.birthDate) : ''
                  }
                />
                <AgGridColumn
                  field="firstSeen"
                  headerName="First Seen"
                  filter="agDateColumnFilter"
                  valueGetter={(params) =>
                    params.data?.firstSeen !== '' ? formatDate(params.data?.firstSeen) : ''
                  }
                />
                <AgGridColumn
                  field="deviceUserName"
                  headerName="Username"
                  filter="agTextColumnFilter"
                />
                <AgGridColumn field="source" headerName="Source" filter="agSetColumnFilter" />
              </AgGridReact>
            </div>
          </>
          {/* ) : (
            <Overlay>Loading...</Overlay>
          )} */}
          {rowData && open && rowData.length > 0 && (
            <Box>
              <Dialog open={open} onClose={handleClose} className={classes.root}>
                <DeviceSummary
                  {...{
                    handleClose,
                    open,
                    arpId,
                    setArpId: (arpId) => setArpId(arpId),
                    rowIndex,
                    setRowIndex,
                    devicesData: rowData,
                    refreshDeviceData,
                    deviceId,
                    grid: 'allDevices',
                    dStatuses,
                    handleFullScreen,
                    handleFullScreenExit,
                    fullScreen
                  }}
                />
              </Dialog>
            </Box>
          )}
        </div>
      </Container>
    </Page>
  );
}

function mapStatuses(statuses) {
  const obj = {};
  statuses?.data?.subCategory.forEach((x) => {
    obj[x.statusId] = x.value;
  });
  return obj;
}

function mapSubCategories(subCategories) {
  const obj = {};
  subCategories?.data?.subCategory.forEach((x) => {
    obj[x.subCategoryId] = x.subCategoryName;
  });
  return obj;
}
