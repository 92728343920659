import { useState, useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/styles';
import MuiTypography from '@material-ui/core/Typography';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { styled } from '@material-ui/core/styles';
import { orderBy } from 'lodash';
import { formatDate } from '../../helpers';
import '../../styles/css/Grid.css';
import { ReportContext } from '../../context/ReportContext';
import useStyles from '../../styles';
import { getProductLineSupportDetails } from '../../server';

const BodyCell = styled('td')({
  padding: '0.25rem',
  textAlign: 'left',
  fontSize: 12,
  '&:first-child': {
    borderRadius: '5px 0 0 5px'
  },
  '&:last-child': {
    borderRadius: '0 5px 5px 0'
  }
});

const HeadCell = styled('th')({
  padding: '0.25rem',
  textAlign: 'left',
  background: '#203F64',
  fontWeight: 400,
  verticalAlign: 'baseline',
  '&:first-child': {
    borderRadius: '5px 0 0 5px'
  },
  '&:last-child': {
    borderRadius: '0 5px 5px 0'
  }
});

const Table = styled('table')({
  fontSize: 12,
  color: '#fff',
  border: '1px solid #fff',
  width: '100%'
});

const Row = styled('tr')({
  background: '#95ADBF',
  '&:nth-child(2n)': {
    background: '#778FA0'
  }
});

const Typography = withStyles({
  root: {
    fontSize: 14,
    fontWeight: 600,
    textAlign: 'center'
  }
})(MuiTypography);

export default function GridExample(props) {
  const contextData = useContext(ReportContext);
  const [rowData, setRowData] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    // for (let i = 0; i < props.data.data.reportList.length; i += 1) {
    //   array.push(props.data.data.reportList[i].productId);
    // }
    getProductLineSupportDetails(contextData.level[0].allLeafChildren[0].data.productLineId).then(
      (data) => {
        updateData(data);
      }
    );
  }, [contextData]);

  const updateData = (res) => {
    if (res?.data?.reportList) {
      setRowData(orderBy(res.data.reportList, ['formattedModel'], ['asc']));
    }
  };

  return (
    <>
      {rowData && rowData.length > 0 && (
        <>
          <Typography sx={{ py: 0.75 }}>Support Details</Typography>
          <Table sx={{ width: '100%', margin: '0 auto' }}>
            <thead>
              <Row>
                <HeadCell>Product</HeadCell>
                <HeadCell>Launch Date</HeadCell>
                <HeadCell>Mainstream End of Support</HeadCell>
                <HeadCell>Extended Support</HeadCell>
                {/* <HeadCell># of Installs</HeadCell> */}
                <HeadCell>In Mainstream Support</HeadCell>
                <HeadCell>In Extended Support</HeadCell>
                <HeadCell>No Longer Supported</HeadCell>
              </Row>
            </thead>
            <tbody>
              {rowData.map((row, index) => {
                const extendSupportClass = row?.installIn_ExtendSupport ? classes.yellowCell : null;
                const mainStreamClass = row?.installIn_MainStream ? classes.greenCell : null;
                const noSupportClass = row?.installIn_NoSupport ? classes.redCell : null;
                let rowElement = (
                  <Row key={index}>
                    <BodyCell
                      sx={{ fontSize: 12, minWidth: '90px' }}
                    >{`${row?.formattedModel}`}</BodyCell>
                    <BodyCell sx={{ fontSize: 12 }}>
                      {row?.launchDate !== null ? formatDate(row?.launchDate) : null}
                    </BodyCell>
                    <BodyCell sx={{ fontSize: 12 }}>
                      {row?.mainstreamEndDate !== null ? formatDate(row?.mainstreamEndDate) : null}
                    </BodyCell>
                    <BodyCell sx={{ fontSize: 12 }}>
                      {row?.extendedSupport !== null ? formatDate(row?.extendedSupport) : null}
                    </BodyCell>
                    {/* <BodyCell sx={{ fontSize: 12 }}>{row?.quantity}</BodyCell> */}
                    <BodyCell
                      className={mainStreamClass}
                      sx={{ textAlign: 'center', fontSize: 12 }}
                    >
                      {row?.installIn_MainStream}
                    </BodyCell>
                    <BodyCell
                      className={extendSupportClass}
                      sx={{ textAlign: 'center', fontSize: 12 }}
                    >
                      {row?.installIn_ExtendSupport}
                    </BodyCell>
                    <BodyCell className={noSupportClass} sx={{ textAlign: 'center', fontSize: 12 }}>
                      {row?.installIn_NoSupport}
                    </BodyCell>
                  </Row>
                );
                if (
                  !formatDate(row?.launchDate) &&
                  !formatDate(row?.mainstreamEndDate) &&
                  !formatDate(row?.extendedSupport)
                ) {
                  rowElement = (
                    <Row key={index}>
                      <BodyCell
                        sx={{ fontSize: 12, minWidth: '90px' }}
                      >{`${row?.formattedModel}`}</BodyCell>
                      <BodyCell></BodyCell>
                      <BodyCell></BodyCell>
                      <BodyCell></BodyCell>
                      <BodyCell sx={{ fontSize: 12 }}>{row?.quantity}</BodyCell>
                      <BodyCell></BodyCell>
                      <BodyCell></BodyCell>
                      <BodyCell></BodyCell>
                    </Row>
                  );
                }

                return rowElement;
              })}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
}
