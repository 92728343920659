import MuiTypography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { styled, withStyles } from '@material-ui/styles';
import BtnDanger from '../../../buttons/ButtonDanger';

const Typography = withStyles({
  root: {
    display: 'inline-block',
    marginLeft: 10
  }
})(MuiTypography);

const User = styled('div')({
  width: '31%',
  height: 80,
  marginTop: 10,
  marginLeft: 10,
  background: '#eee',
  paddingTop: 10,
  paddingLeft: 10,
  '&:hover': {
    background: '#ddd'
  }
});

export default function Roles(props) {
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', mt: 1 }}>
          <User>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <input type="checkbox" />
              <Typography sx={{ display: 'inline-block' }}>Admin</Typography>
            </Box>
          </User>
          <User>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <input type="checkbox" />
              <Typography sx={{ display: 'inline-block' }}>Provider</Typography>
            </Box>
          </User>
          <User>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <input type="checkbox" />
              <Typography sx={{ display: 'inline-block' }}>Guest</Typography>
            </Box>
          </User>
          <User>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <input type="checkbox" />
              <Typography sx={{ display: 'inline-block' }}>Demo</Typography>
            </Box>
          </User>
        </Box>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 2 }}>
        <BtnDanger>Delete Member</BtnDanger>
      </Box>
    </>
  );
}
