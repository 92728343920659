import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  styled,
  Paper,
  Grid,
  Dialog,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  TextField,
  IconButton,
  FormControlLabel,
  FormGroup,
  Checkbox
} from '@material-ui/core';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import MuiTypography from '@material-ui/core/Typography';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from '../../../../styles';
import BtnLight from '../../../../components/buttons/ButtonLight';
import { getCurrentAccounts, getAccountSubscriptions } from '../../../../server';

const ItemPaper = styled(Paper)(({ theme }) => ({
  padding: 0,
  borderRadius: 0,
  display: 'flex',
  alignItems: 'center',
  width: '100%'
}));

const Key = styled('div')({
  width: 200,
  float: 'left'
});

const Value = styled('span')({
  fontWeight: 500
});

const Typography = withStyles({
  root: {
    fontSize: 14
  }
})(MuiTypography);

const DialogActions = withStyles({
  root: {
    backgroundColor: '#364150',
    color: '#F4F6F8'
  }
})(MuiDialogActions);

const DialogContent = withStyles({
  root: {
    backgroundColor: '#364150',
    color: '#F4F6F8'
  }
})(MuiDialogContent);

const DialogContentText = withStyles({
  root: {
    backgroundColor: '#364150',
    color: '#F4F6F8'
  }
})(MuiDialogContentText);

const DialogTitle = withStyles({
  root: {
    backgroundColor: '#364150',
    color: '#F4F6F8'
  }
})(MuiDialogTitle);

export default function DialogBox() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState([]);
  const [currentAccounts, setCurrentAccounts] = useState([]);
  const [accountSubscriptions, setAccountSubscriptions] = useState([]);
  const [data, setData] = useState(4930);

  useEffect(() => {
    getCurrentAccounts().then((x) => setCurrentAccounts(x?.data?.modelList));
  }, []);

  useEffect(() => {
    setAccountSubscriptions([]);
    getAccountSubscriptions(data).then((x) => setAccountSubscriptions(x?.data?.subscriptionList));
  }, [data]);

  const handleChange = (e) => {
    setData(e.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <BtnLight onClick={handleClickOpen}>new subscription</BtnLight>
      <Dialog open={open} onClose={handleClose} className={classes.root}>
        <DialogTitle>
          New Subscription
          <IconButton aria-label="close" onClick={handleClose} className={classes.iconStyle}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ItemPaper component="form" className={classes.mb10} sx={{ minWidth: 450 }}>
            {/* <InputLabel id="demo-simple-select-label">Choose account</InputLabel> */}
            <FormControl fullWidth>
              <Select sx={{ height: 30 }} onChange={handleChange}>
                <MenuItem value={10} selected disabled>
                  Choose account
                </MenuItem>
                {currentAccounts.map((x) => (
                  <MenuItem value={x?.accountId}>{x?.accountName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </ItemPaper>
          <ItemPaper component="form" className={classes.mb20} sx={{ minWidth: 450 }}>
            {/* <InputLabel id="demo-simple-select-label">Choose subscription</InputLabel> */}
            <FormControl fullWidth>
              <Select sx={{ height: 30 }}>
                <MenuItem value={10} selected disabled={accountSubscriptions.length === 0}>
                  Choose subscription
                </MenuItem>
                {accountSubscriptions.map((x) => (
                  <MenuItem value={x?.subscriptionId}>{x?.subscriptionName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </ItemPaper>
          <Grid>
            <Typography variant="h6" className={classes.fontSlim}>
              <Key>Subscription name: </Key>
              <Value>name</Value>
            </Typography>
            <Typography variant="h6" className={classes.fontSlim}>
              <Key>Duration: </Key>
              <Value>time</Value>
            </Typography>
            <Typography variant="h6" className={classes.fontSlim}>
              <Key>Unit price: </Key>
              <Value>cost</Value>
            </Typography>
            <Typography variant="h6" className={classes.fontSlim}>
              <Key>Device count: </Key>
              <Value>0</Value>
            </Typography>
            <Typography variant="h6" className={classes.fontSlim}>
              <Key>Device range: </Key>
              <Value>1-100</Value>
            </Typography>
            <Typography variant="h6" className={classes.fontSlim}>
              <Key>Subscription cost type: </Key>
              <Value>Quarterly</Value>
            </Typography>
            <Typography variant="h6" className={classes.fontSlim}>
              <Key>Total price: </Key>
              <Value>$10,000</Value>
            </Typography>
          </Grid>
          <Grid>
            <TextField
              margin="dense"
              id="providerNumber"
              label="Provider P.O. Number"
              type="number"
              fullWidth
              variant="standard"
              color="secondary"
              className={classes.textInput}
            />
            <TextField
              margin="dense"
              id="customerNumber"
              label="Customer Reference Number"
              type="number"
              fullWidth
              variant="standard"
              color="secondary"
              className={classes.textInput}
            />
            <TextField
              margin="dense"
              id="subscriptionStartDate"
              // label="Customer Reference Number"
              type="date"
              fullWidth
              variant="standard"
              color="secondary"
              className={classes.textInput}
            />
            <TextField
              margin="dense"
              id="subscriptionStartDate"
              // label="Customer Reference Number"
              type="date"
              fullWidth
              variant="standard"
              color="secondary"
              className={classes.textInput}
            />
            {/* <FormGroup>
              <FormControlLabel
                control={<Checkbox sx={{ color: '#fff' }} />}
                label="Complimentary?"
              />
            </FormGroup> */}
            {/* <LocalizationProvider
              dateAdapter={AdapterDateFns}
              fullWidth
              variant="standard"
              color="secondary"
              className={classes.textInput}
            >
              <DatePicker
                label="subscriptionStartDate"
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
                fullWidth
                variant="standard"
                color="secondary"
                className={classes.textInput}
              />
            </LocalizationProvider> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <BtnLight variant="contained" size="small" onClick={handleClose}>
            Add subscription
          </BtnLight>
        </DialogActions>
      </Dialog>
    </div>
  );
}
