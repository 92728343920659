import { getCookie, getFileName } from '../helpers';
import { publishers, softwares } from '../constants/reports';

// const BASE_URL = 'http://localhost:39350/api';
// const BASE_URL = 'http://216.157.108.132:81/api';
const BASE_URL = 'https://assetlabs.net:8086/api';

// const publishers = [12, '12a', '12b'];
// const softwares = ['12c', '12d'];
const virtualModelMachineList = 'VMware Virtual Platform|VMware, Inc.|Virtual Machine|VMware7';

function isAuthentic() {
  let val = false;
  const authed = getCookie('authed');

  if (authed === 'true') {
    val = true;
  } else {
    window.location.href = '/login';
  }

  return val;
}

function getCommonResponse(res) {
  if (res && res.status) {
    return {
      status: res.status < 299,
      errors: res.status < 299 ? null : []
    };
  }
}

export const getUserAccounts = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Account/UserAccounts`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const changeUserAccount = async (accountId) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Account/ChangeUserAccount?accountId=${accountId}`;
  const response = await fetch(url, {
    method: 'post',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getStatuses = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Devices/GetStatuses`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getSubCategories = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Devices/getSubCategories`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getLicenseTypes = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/GetLicenseTypes`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getAllProductLines = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Devices/GetAllProductLines`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getUpdateDeviceSoftwareStates = async (status, device) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Devices/UpdateDeviceSoftwareStates?DeviceAprIDs=${device}&StatusID=${status}`;
  const response = await fetch(url, {
    method: 'post',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getUpdateDeviceBulkHardwareStates = async (status, device) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Devices/UpdateDeviceBulkHardwareStates?DeviceId=${device}&StatusID=${status}`;
  const response = await fetch(url, {
    method: 'post',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getUpdateSingleDeviceStates = async (status, device) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Devices/UpdateSingleDeviceStates?DeviceId=${device}&StatusID=${status}`;
  const response = await fetch(url, {
    method: 'post',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const UpdateProductLineDeviceSoftwareState = async (status, deviceAprIDs) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Devices/UpdateProductLineDeviceSoftwareState?DeviceAprIDs=${deviceAprIDs}&StatusID=${status}`;
  const response = await fetch(url, {
    method: 'post',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const UpdateDevicesSoftwareState = async (status, device) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Devices/UpdateDevicesSoftwareState?DeviceId=${device}&StatusID=${status}`;
  const response = await fetch(url, {
    method: 'post',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getDeviceSoftwareStates = async (productId) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/DeviceSoftwareStates?productIds=${productId}&virtualModelMachineList=${virtualModelMachineList}`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getPreviousInventoryUpdateDate = async (productId) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/PreviousInventoryUpdateDate`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getPublisherEntitlements = async (publisherId) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/GetPublisherEntitlements?publisherId=${publisherId}`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getProductLineVersionStatistics = async (productLineIds) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/GetProductLineVersionStatistics?accountGuid=0&productLineIds=${productLineIds}`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getProductLineSupportDetails = async (productLineIds) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/GetProductLineSupportDetails?accountGuid=0&productLineIds=${productLineIds}`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getEntitlementQuantities = async (productLineId) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/EntitlementQuantities?productLineId=${productLineId}`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getDeviceAgeAnalysis = async (productIds) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/DeviceAgeAnalysis?productIds=${productIds}&virtualModelMachineList=${virtualModelMachineList}`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getProductLineQtys = async (productLineIds) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/GetProductLineQtys?accountGuid&productIds=${productLineIds}`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getMissingDevicesWithProduct = async (productIds) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/GetMissingDevicesWithProduct?productIds=${productIds}&virtualModelMachineList=${virtualModelMachineList}`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getNetNewDevicesWithProduct = async (productIds) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/GetNetNewDevicesWithProduct?productIds=${productIds}&virtualModelMachineList=${virtualModelMachineList}`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getRAWSoftware = async (deviceARPIds, data) => {
  if (!isAuthentic()) return;

  if (data === 'allDevices') {
    const url = `${BASE_URL}/Reports/Report_GetRAWSoftware?deviceARPIds=0&deviceId=${deviceARPIds}`;
    const response = await fetch(url, {
      method: 'get',
      credentials: 'include'
    });
    const data = await response.json();
    return data;
  }
  {
    const url = `${BASE_URL}/Reports/Report_GetRAWSoftware?deviceARPIds=${deviceARPIds}&deviceId=0`;
    const response = await fetch(url, {
      method: 'get',
      credentials: 'include'
    });
    const data = await response.json();
    return data;
  }
};

export const getNormalizedSoftware = async (deviceARPIds, data) => {
  if (!isAuthentic()) return;

  if (data === 'allDevices') {
    const url = `${BASE_URL}/Reports/Report_GetNormalizedSoftware?deviceARPIds=0&deviceId=${deviceARPIds}`;
    const response = await fetch(url, {
      method: 'get',
      credentials: 'include'
    });
    const data = await response.json();
    return data;
  }
  {
    const url = `${BASE_URL}/Reports/Report_GetNormalizedSoftware?deviceARPIds=${deviceARPIds}&deviceId=0`;
    const response = await fetch(url, {
      method: 'get',
      credentials: 'include'
    });
    const data = await response.json();
    return data;
  }
};

export const getDeviceDetails = async (DeviceArpId, data) => {
  if (!isAuthentic()) return;

  if (data === 'allDevices') {
    const url = `${BASE_URL}/Reports/GetDeviceDetails?VirtualMachine=%22%22&DeviceID=${DeviceArpId}&DeviceArpId=0`;
    const response = await fetch(url, {
      method: 'get',
      credentials: 'include'
    });
    const data = await response.json();
    return data;
  }
  {
    const url = `${BASE_URL}/Reports/GetDeviceDetails?VirtualMachine=%22%22&DeviceID=0&DeviceArpId=${DeviceArpId}`;
    const response = await fetch(url, {
      method: 'get',
      credentials: 'include'
    });
    const data = await response.json();
    return data;
  }
};

export const getDeviceListByPulseIdAndType = async (link) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/${link}`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getRawNormalizedStat = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/GetRaw_Normalized_Stat`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getStreamLineStat = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/GetStreamLineStat`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getCategorizationByProductLineId = async (lineId) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/GetCategorizationByProductLineId?ProductLineId=${lineId}`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const postUpdateEntitlements = async (licenseFormData) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/UpdateEntitlements`;
  const response = await fetch(url, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify(licenseFormData),
    headers: { 'Content-Type': 'application/json' }
  });
  const data = getCommonResponse(response);
  return data;
};

export const getCrossReferenceReport = async (productLineId1, productLineId2) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/GetCrossReferenceReport?productLineId1=${productLineId1?.productlineid}&productLineId2=${productLineId2?.productlineid}`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const postCategorizationModal = async (link) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}${link}`;
  const response = await fetch(url, {
    method: 'post',
    credentials: 'include'
  });
  const data = getCommonResponse(response);
  return data;
};

export const postForgotPassword = async (formInput) => {
  const url = `${BASE_URL}/Account/ForgotPassword?email=${formInput.emailAddress}`;
  const response = await fetch(url, {
    method: 'post',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const postUserLogin = async (formInput) => {
  const url = `${BASE_URL}/Account/UserLogin`;
  const response = await fetch(url, {
    method: 'post',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      emailAddress: formInput.emailAddress,
      password: formInput.password
    })
  });
  const data = await response.json();
  return data;
};

export const getGridReport = async (contextReportData, REPORTS) => {
  if (!isAuthentic()) return;
  let url = `${BASE_URL}/Reports/GetGridReport?reportName=${contextReportData.report}`;

  if (
    publishers.includes(contextReportData.report) ||
    softwares.includes(contextReportData.report)
  ) {
    url = `${BASE_URL}/Reports/GetGridReport?reportName=${REPORTS.PublishersAtoZ}`;
  }

  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const resendPasswordLink = async (formInput) => {
  const url = `${BASE_URL}/Account/ResendPasswordLink?email=${formInput.emailAddress}`;

  const response = await fetch(url, {
    method: 'post',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getPublihserSummary = async (
  publisherIdArr,
  reportId
  // productLineIdArr,
  // productIdArr
) => {
  if (!isAuthentic()) return;

  try {
    if (publisherIdArr.length === 0) {
      throw new Error('Publisher id is required');
    }

    reportId = softwares.includes(reportId) || publishers.includes(reportId) ? 12 : reportId;
    const url = `${BASE_URL}/Reports/GetPublihserSummary`;

    const response = await fetch(url, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        publisherID: publisherIdArr[0],
        reportName: reportId,
        productLineIds: '', // productLineIdArr.toString(),
        productIds: '' // productIdArr.toString()
      }),
      headers: { 'Content-Type': 'application/json' }
    });
    const data = await response.json();
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

export const GetProviderLogoPath = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/File/GetProviderLogoPath`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const UploadProviderLogo = async (uploadedFiles) => {
  if (!isAuthentic()) return;

  const data = new FormData();

  uploadedFiles?.forEach((file) => {
    data.append('logoFile', file, file.name);
  });

  const url = `${BASE_URL}/File/UploadProviderLogo`;
  const response = await fetch(url, {
    method: 'post',
    credentials: 'include',
    body: data
  });
  const res = await response.json();
  return res;
};

export const GetUploadedFiles = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/File/GetUploadedFiles`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const UploadFiles = async (uploadedFiles) => {
  if (!isAuthentic()) return;

  const data = new FormData();

  uploadedFiles?.forEach((file) => {
    data.append('formFiles', file, file.name);
  });

  const url = `${BASE_URL}/File/UploadFile`;
  const response = await fetch(url, {
    method: 'post',
    credentials: 'include',
    body: data
  });
  const res = await response.json();
  return res;
};

export const DownloadFile = async (path) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/File/DownloadFile?filepath=${path}`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const blob = await response.blob();
  return { blob, fileName: getFileName(response) };
};

export const GetGridAccountProductLinesWL = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/GetGridAccountProductLinesWL`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const GetReportNames = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/GetReportNames`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const AddToWatchList = async (productLineID) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/AddToWatchList?ProductLineID=${productLineID}`;
  const response = await fetch(url, {
    method: 'post',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const RemoveFromWatchList = async (productLineID) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/RemoveFromWatchList?ProductLineID=${productLineID}`;
  const response = await fetch(url, {
    method: 'post',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

// ------ Main Dashboard----- //

export const GetClientDevicesCount = async (productLineID) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/DashBoard/GetClientDevicesCount`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const GetServerDevicesCount = async (productLineID) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/DashBoard/GetServerDevicesCount`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const TopByCost = async (productLineID) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/DashBoard/TopByCost`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const GetLicenseingWatchList = async (productLineID) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/DashBoard/GetLicenseingWatchList`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const TopLicenseTypes = async (productLineID) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/DashBoard/TopLicenseTypes`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const TopCategories = async (productLineID) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/DashBoard/TopCategories`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const GetSoftwareTitlesWL = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/GetSoftwareTitlesWL`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getBlackList = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/GetBlackList`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const postAddToBlackList = async (productLineID) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/AddToBlackList?ProductLineID=${productLineID}`;
  const response = await fetch(url, {
    method: 'post',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const postRemoveFromBlackList = async (productLineID) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/RemoveFromBlackList?ProductLineID=${productLineID}`;
  const response = await fetch(url, {
    method: 'post',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getReportELPMicrosoft = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/Report_ELPMicrosoft`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getReportCMDB = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/GetReport_CMDB`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getReportAllPublishers = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Reports/Report_AllPublishers`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const GetAccountMembers = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Account/GetAccountMembers`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const SubscriptionSummary = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Account/SubscriptionSummary`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const AccountAssignmentList = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Account/AccountAssignmentList`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const UserAssigned = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Account/UserAssigned`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const GetSubServBundle = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Account/GetSubServBundle`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getProvidersList = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Account/GetProvidersList`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getAllSubscription = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Account/AllSubscription`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getManageAccount = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Account/ManageAccount_Members`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const postCreateAccount = async (name, email) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Account/CreateAccount`;
  const response = await fetch(url, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify({
      accountName: name,
      accountAdminEmail: email
    }),
    headers: { 'Content-Type': 'application/json' }
  });
  const data = await response.json();
  return data;
};

export const getAccountAssignmentList = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Account/GetAccountAssignmentList`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getAccountInformation = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Account/AccountInformation`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getCurrentAccounts = async () => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Account/GetCurrentAccounts?UserGuid=0&ProviderId=0`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};

export const getAccountSubscriptions = async (id) => {
  if (!isAuthentic()) return;

  const url = `${BASE_URL}/Account/AccountSubscriptions?AccountId=${id}`;
  const response = await fetch(url, {
    method: 'get',
    credentials: 'include'
  });
  const data = await response.json();
  return data;
};
