// material
import { Stack, Typography } from '@material-ui/core';
import { styled, withStyles } from '@material-ui/styles';
// components
import AddUserDialog from './AddUserDialog';
import MemberGrid from '../../../../components/grids/MemberGrid';
import useStyles from '../../../../styles';

const Heading = styled('p')({
  fontSize: 15,
  fontWeight: 500
});

export default function PageFunc() {
  const classes = useStyles();
  return (
    <>
      <Stack
        className={classes.controlsRibbon}
        py={0.5}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        px={1}
      >
        <Heading>Account Members</Heading>
        <AddUserDialog />
      </Stack>
      <Stack direction="row" justifyContent="center">
        <MemberGrid />
      </Stack>
    </>
  );
}
