function styles(theme) {
  return {
    overlay: {
      color: '#B0AEB0',
      backgroundColor: '#404B5C',
      textAlign: 'center',
      border: '2px solid #B0AEB0',
      borderRadius: 5,
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 15
      // paddingRight: 10
      // padding: 10,
    },
    overlayText: {
      fontSize: '22px',
      fontStyle: 'italic',
      fontWeight: 400,
      color: '#B0AEB0'
      // marginTop: 10
    },
    greet: {
      marginTop: -8,
      minWidth: 260
    },
    checkbox: {
      marginBottom: 20,
      marginLeft: -10
    },
    check: {
      color: '#444',
      fontSize: 12
    },
    formLinks: {
      textDecoration: 'none',
      color: '#2196f3',
      display: 'block'
      // [theme.breakpoints.down(840)]: {
      //   display: 'inline-block',
      //   marginRight: 8
      // }
    },
    // formWidth: {
    //   width: 200,
    //   [theme.breakpoints.down(840)]: {
    //     width: '100%'
    //   }
    // },
    footerLinks: {
      textDecoration: 'none',
      color: '#2196f3'
    },
    pl10Perc: {
      paddingLeft: '10%'
      // [theme.breakpoints.down(840)]: {
      //   paddingLeft: 0
      // }
    },
    pr10Perc: {
      paddingRight: '10%'
      // [theme.breakpoints.down(840)]: {
      //   paddingRight: 0
      // }
    },
    // authHide: {
    //   [theme.breakpoints.down(840)]: {
    //     display: 'none'
    //   }
    // },
    authRemember: {
      marginLeft: 20,
      fontSize: 12
    },
    authInput: {
      '& .MuiOutlinedInput-root': {
        width: 260,
        height: 34,
        fontSize: 12
        // '& .MuiOutlinedInput-notchedOutline > legend > span': {
        //   marginBottom: -5,
        //   marginTop: 5,
        //   paddingBottom: 0
        // }
      },
      '& .MuiInputLabel-root': {
        fontSize: 11,
        lineHeight: 1,
        marginTop: -4,
        width: 'auto'
      },
      '& .MuiInputBase-input': {
        paddingTop: 8,
        paddingBottom: 8
      }
    },
    authImage: {
      marginLeft: 'auto',
      height: '600'
      // [theme.breakpoints.down(840)]: {
      //   display: 'none'
      // }
    },
    // authLoginLink: {
    //   [theme.breakpoints.down(840)]: {
    //     marginBottom: 20
    //   },
    //   [theme.breakpoints.down(440)]: {
    //     marginLeft: 20
    //   }
    // },
    authLeft: {
      maxWidth: '50%'
      // [theme.breakpoints.down(840)]: {
      //   maxWidth: '100%'
      // }
    },
    authLogo: {
      width: '210px'
      // [theme.breakpoints.down(440)]: {
      //   width: '150px'
      // }
    },
    authLogoCaption: {
      fontSize: 11,
      zIndex: 10,
      paddingLeft: 10,
      width: 250,
      marginBottom: 20,
      color: '#666'
    },
    authBody: {
      background: 'linear-gradient(to right, #404B5C, #2D343F)',
      backgroundRepeat: 'repeat',
      height: '100vh',
      minHeight: 600,
      minWidth: 880
      // [theme.breakpoints.down(840)]: {
      //   background: '#fff'
      // }
    },
    authSection: {
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)'
    },
    authCard: {
      background: '#ffffff',
      boxShadow: 'rgba(0, 0, 0, 0.6) 0px .5px 15px',
      margin: '0 auto',
      width: 880,
      height: 600,
      minHeight: 400
      // [theme.breakpoints.down(840)]: {
      //   minWidth: 300,
      //   maxWidth: 400,
      //   height: '60vh',
      //   paddingBottom: 10,
      //   boxShadow: 'none'
      // }
    },
    authHeading: {
      fontSize: 22,
      color: '#000'
    },
    authText: {
      fontWeight: '400',
      fontSize: 12,
      maxWidth: '100px',
      lineHeight: 1.5
    },
    authForm: {
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: 0
    },
    authBtn: {
      marginLeft: 'auto'
      // [theme.breakpoints.down(440)]: {
      //   marginLeft: 30,
      //   marginBottom: 15
      // }
    },
    authFooterText: {
      fontSize: 12,
      color: '#9A9EA4'
      // [theme.breakpoints.down(840)]: {
      //   marginTop: 60,
      //   background: '#eee',
      //   padding: 30,
      //   marginLeft: -20,
      //   marginRight: -20,
      //   color: '#303030'
      // },
      // [theme.breakpoints.down(392)]: {
      //   display: 'none'
      // }
    },
    formLinksContainer: {
      // [theme.breakpoints.down(840)]: {
      //   marginBottom: 20
      // }
    },
    cardFooter: {
      fontSize: 12,
      background: '#F4F5F7',
      paddingTop: 35,
      paddingBottom: 35
      // [theme.breakpoints.down(840)]: {
      //   padding: 0,
      //   marginTop: 20,
      //   background: 'none'
      // }
    },
    authFooterSettings: {
      marginTop: '36px'
      // [theme.breakpoints.down(840)]: {
      //   marginTop: -20,
      //   paddingRight: 25
      // }
    },
    loginFooterSettings: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      marginTop: '52px',
      marginLeft: -86,
      marginRight: -175,
      paddingLeft: 85
      // [theme.breakpoints.down(840)]: {
      //   justifyContent: 'center',
      //   minWidth: '100%',
      //   maxWidth: '100%',
      //   marginTop: -10,
      //   marginLeft: 0,
      //   marginRight: 0,
      //   paddingLeft: 0,
      //   paddingRight: 0
      // }
    },
    cardFooterText: {
      fontSize: 12
    },
    resetCardFooter: {
      marginTop: '63px'
    },
    resendCardFooter: {
      marginTop: '80px'
    }
  };
}

export default styles;
