// material
import { Stack, Container, Card, Typography, Grid, Paper, styled } from '@material-ui/core';
// components
import Page from '../components/Page';
import useStyles from '../styles';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

export default function PageFunc() {
  const classes = useStyles();
  return (
    <Page title="Empty | AssetLabs" className={classes.bodyContainer}>
      <Container className={classes.fullWidth}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Empty
          </Typography>
        </Stack>

        {/* <Card>
          <Typography variant="h6" component="p" sx={{ fontWeight: 400, p: 4 }}>
            Something goes here
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={8} md={12}>
              <Item>xs=8 md=12</Item>
            </Grid>
            <Grid item xs={4}>
              <Item>xs=4</Item>
            </Grid>
            <Grid item xs={4}>
              <Item>xs=4</Item>
            </Grid>
            <Grid item xs={8}>
              <Item>xs=8</Item>
            </Grid>
          </Grid>
        </Card> */}
      </Container>
    </Page>
  );
}
