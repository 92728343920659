// material
import React, { useEffect, useState, useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Breadcrumbs, Link, Container, Typography } from '@material-ui/core';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
// components
// import Controls from './components/controls';
// import DeviceSummary from '../../../components/modals/DeviceSummary';
// import { ReportContext } from '../../../context/ReportContext';
import Page from '../../../components/Page';
import useStyles from '../../../styles';
// import { formatDate } from '../../../helpers';
import {
  GetAccountMembers,
  GetSubServBundle,
  UserAssigned,
  AccountAssignmentList,
  SubscriptionSummary
} from '../../../server';
// import Overlay from '../../../components/Overlay';

const deltaIndicator = (params) => {
  const element = document.createElement('span');
  const imageElement = document.createElement('img');
  const dataSpan = document.createElement('span');
  const data = document.createTextNode(params.value);
  if (params.data.subCategoryID === 261) {
    imageElement.src = '/assets/client_icon_grid.png';
  } else {
    imageElement.src = '/assets/server_icon_grid.png';
  }
  dataSpan.appendChild(data);
  element.appendChild(imageElement);
  element.appendChild(dataSpan);
  imageElement.classList.add('gridIcon');
  dataSpan.classList.add('gridSpan');
  return element;
};

const Button = withStyles({
  root: {
    // display: 'none'
  }
})(MuiButton);

const activeTab = {
  borderRadius: '5px 5px 0 0',
  marginRight: 0.75,
  marginBottom: 0,
  fontWeight: 400,
  fontSize: 14,
  minHeight: 10,
  maxHeight: 30,
  lineHeight: 1,
  boxShadow: '2px -7px 8px -7px rgba(99, 99, 99, 0.3)',
  background: '#F8F8F8',
  color: '#000000'
};

const inactiveTab = {
  borderRadius: '5px 5px 0 0',
  marginRight: 0.75,
  marginBottom: 0,
  color: '#000000',
  fontWeight: 400,
  fontSize: 14,
  minHeight: 10,
  maxHeight: 30,
  lineHeight: 1,
  background: '#9CB9D0',
  // paddingTop: 1.75,
  boxShadow: '2px -7px 8px -7px rgba(99, 99, 99, 0.3)'
};

export default function SoftwareByLicenseType(props) {
  const { pathname } = useLocation();
  const classes = useStyles();
  const [accountMembers, setAccountMembers] = useState();

  useEffect(() => {
    GetAccountMembers().then((res) => {
      if (res.status) {
        setAccountMembers(res.data);
      }
    });
  }, []);

  const breadcrumbs = [
    <Link underline="none" key="1" color="inherit" fontSize="small">
      Admin
    </Link>,
    <Typography key="3" color="text.primary" fontSize="small">
      Account Members & Subscriptions
    </Typography>
  ];

  return (
    <Page title="Reports | AssetLabs" className={classes.reportsBody}>
      <Container className={classes.fullWidth}>
        <div className={classes.cardContainer}>
          <Typography variant="h6">Account Members & Subscriptions</Typography>
          <Breadcrumbs sx={{ mb: 1, mt: 0.2 }} separator=">" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
          {/* {rowData ? ( */}
          <>
            <Box>
              <Button
                component={RouterLink}
                to="/admin/member-and-account"
                disableRipple
                sx={pathname.includes('/member-and-account') ? activeTab : inactiveTab}
                // onClick={() => externalFilterChanged('member-and-account')}
                // disabled={rowData === null}
              >
                Members, Accounts & Roles
              </Button>
              <Button
                component={RouterLink}
                to="/admin/account-subscriptions"
                disableRipple
                sx={pathname.includes('/account-subscriptions') ? activeTab : inactiveTab}
                // onClick={() => externalFilterChanged('account-subscriptions')}
                // disabled={rowData === null}
              >
                Account Subscriptions
              </Button>
              <Button
                component={RouterLink}
                to="/admin/account-assignments"
                disableRipple
                sx={pathname.includes('/account-assignments') ? activeTab : inactiveTab}
                // onClick={() => externalFilterChanged('account-assignments')}
                // disabled={rowData === null}
              >
                Account Assignments
              </Button>
              <Button
                component={RouterLink}
                to="/admin/unassigned-members"
                disableRipple
                sx={pathname.includes('/unassigned-members') ? activeTab : inactiveTab}
                // onClick={() => externalFilterChanged('unassigned-members')}
                // disabled={rowData === null}
              >
                Unassigned Members
              </Button>
            </Box>
            <div
              style={{
                height: 'calc(100vh - 220px)',
                width: '100%'
              }}
              className="ag-theme-alpine"
            >
              <AgGridReact
                defaultColDef={{
                  flex: 1,
                  minWidth: 80,
                  sortable: true,
                  filter: true,
                  floatingFilter: true,
                  resizable: true
                }}
                headerHeight="25"
                floatingFiltersHeight="31"
                rowHeight="25"
                animateRows
                rowSelection="multiple"
                rowData={[]}
                statusBar={{
                  statusPanels: [
                    {
                      statusPanel: 'agTotalAndFilteredRowCountComponent',
                      align: 'left'
                    }
                  ]
                }}
              >
                <AgGridColumn
                  cellClass="deviceName"
                  field="deviceIdentifier"
                  headerName="Account"
                  filter="agTextColumnFilter"
                  headerCheckboxSelection
                  checkboxSelection
                  minWidth={200}
                />
                <AgGridColumn
                  headerName="Highest Role"
                  field="manufacturer"
                  filter="agSetColumnFilter"
                  minWidth={70}
                />
              </AgGridReact>
            </div>
          </>
        </div>
      </Container>
    </Page>
  );
}
