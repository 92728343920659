import React, { useEffect, useState, useReducer, useContext } from 'react';
import clsx from 'clsx';
import MuiTypography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/core/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import Input from '@material-ui/core/Input';
import Grow from '@material-ui/core/Grow';
import { withStyles, makeStyles } from '@material-ui/styles';
import useStyles from '../../../../../../styles';
import { formatter } from '../../../../../../helpers';
import { ReportContext } from '../../../../../../context/ReportContext';
import { getEntitlementQuantities, postUpdateEntitlements } from '../../../../../../server';
import Overlay from '../../../../../../components/Overlay';

const posTile = { background: '#007D52' };
const negTile = { background: '#CF4545' };
const neutralTile = { background: '#A9A8A8' };
const posVal = { textAlign: 'center', fontWeight: 600, color: '#3E4B5C' };
const negVal = { textAlign: 'center', fontWeight: 600, color: '#CF4545' };

const Typography = withStyles({
  root: {
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
    maxWidth: 250,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
})(MuiTypography);

const Alert = React.forwardRef((contextData, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...contextData} />
));

const SUM_VALUES = {
  licenseExemptCount: 0,
  licenseSavings: 1,
  entitlementCurrent: 2,
  entitlementPrev: 3
};

const Counter = styled('div')(({ theme }) => ({
  // height: 100,
  minWidth: 100,
  // width: '33%',
  maxWidth: 250,
  // marginTop: 10,
  paddingLeft: 10,
  paddingRight: 10,
  paddingBottom: 2,
  paddingTop: 0,
  background: '#434F5E',
  display: 'inline-block',
  '&:not(:last-child)': {
    marginRight: 5
  }
  // [theme.breakpoints.up('lg')]: {}
}));

const CounterContent = styled('div')({
  position: 'relative',
  top: '50%',
  transform: 'translateY(-50%)'
});

const CounterHits = styled('p')({
  fontSize: 35,
  color: '#F4F4F4',
  textAlign: 'center'
});

const CounterLabel = styled('p')({
  fontWeight: 400,
  fontSize: 15,
  color: '#F4F4F4',
  textAlign: 'center'
});

const Table = styled('table')({
  fontSize: 14,
  borderCollapse: 'collapse',
  width: '100%'
});

const Row = styled('tr')({
  background: '#D3D3D2'
});

const WRow = styled('tr')({
  background: '#FFF'
});

const BodyCell = styled('td')({
  padding: '0.25rem',
  textAlign: 'left',
  border: '1px solid #999'
});

const HeadCell = styled('th')({
  padding: '0.25rem',
  textAlign: 'center',
  border: '1px solid #999',
  background: '#A2A3A8',
  color: '#F4F4F4',
  fontWeight: 400
});

export default function Tab() {
  const classes = useStyles();
  const contextData = useContext(ReportContext);
  const [licenseData, setlicenseData] = useState(null);
  const [summaryData, setsummaryData] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {}
  );

  const handleInput = (evt) => {
    const {
      target: { name }
    } = evt;
    const newValue = evt.target.value;
    setFormInput({ [`${name}`]: newValue });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    const licenseFormData = [];
    Object.keys(formInput).forEach((key) => {
      licenseFormData.push({
        productId: key,
        licenseQuantity: formInput[key]
      });
    });

    setLoading(true);
    postUpdateEntitlements(licenseFormData)
      .then((response) => {
        setLoading(false);
        if (response.status === true && !response.errors) {
          setOpen(true);
          const lineId = contextData?.level[0]?.allLeafChildren[0].data?.productLineId;
          updateData(lineId);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const lineId = contextData?.level[0]?.allLeafChildren[0].data?.productLineId;
    if (lineId) {
      updateData(lineId);
    }
  }, [contextData]);

  const updateData = (lineId) => {
    getEntitlementQuantities(lineId).then((res) => {
      if (res && res.data) {
        const obj = {};
        res.data.model_list.forEach((item) => {
          obj[item.productId] = item.licenseQuantity;
        });
        setFormInput(obj);
        setlicenseData(res.data.model_list);
        setsummaryData(res.data.licensesCountModel);
      }
    });
  };

  const getClassName = (val) => {
    let className = null;
    if (val > 0) {
      className = classes.positiveCol;
    } else if (val < 0) {
      className = classes.negativeCol;
    }

    return className;
  };

  return (
    <Box
      sx={{
        overflowX: 'auto',
        height: 'calc(100vh - 168px)',
        background: '#EDEEF0',
        padding: 1.75,
        position: 'relative'
      }}
    >
      <Box className={classes.dFlex}>
        <Box
          sx={{
            background: '#FFFFFF',
            borderRadius: 1,
            mr: 1.75,
            padding: 2,
            boxShadow:
              'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px'
          }}
        >
          {summaryData && (
            <Box
              className={clsx(classes.dFlex, classes.flexBetween)}
              sx={{ minWidth: 420, maxWidth: 500 }}
            >
              <Counter>
                <CounterContent>
                  <CounterHits>{summaryData?.deploymentCount}</CounterHits>
                  <CounterLabel gutterBottom sx={{ mt: 1 }}>
                    Deployments
                  </CounterLabel>
                </CounterContent>
              </Counter>
              <Counter
                sx={
                  // eslint-disable-next-line no-nested-ternary
                  summaryData?.compliance === 0 ||
                  summaryData?.compliance === undefined ||
                  summaryData?.compliance === null
                    ? neutralTile
                    : summaryData?.compliance > 0
                    ? posTile
                    : negTile
                }
              >
                <CounterContent>
                  <CounterHits>{summaryData?.compliance}</CounterHits>
                  <CounterLabel gutterBottom sx={{ mt: 1 }}>
                    Compliance
                  </CounterLabel>
                </CounterContent>
              </Counter>
              <Counter
                sx={
                  // eslint-disable-next-line no-nested-ternary
                  summaryData?.riskExposure === 0 ||
                  summaryData?.riskExposure === undefined ||
                  summaryData?.riskExposure === null
                    ? neutralTile
                    : summaryData?.riskExposure > 0
                    ? posTile
                    : negTile
                }
              >
                <CounterContent>
                  <CounterHits>{formatter.format(summaryData?.riskExposure)}</CounterHits>
                  <CounterLabel gutterBottom sx={{ mt: 1 }}>
                    Risk Exposure (Estimate)
                  </CounterLabel>
                </CounterContent>
              </Counter>
            </Box>
          )}
          <Typography gutterBottom sx={{ mt: 3 }}>
            License Input
          </Typography>
          <form id="license_form">
            <Table sx={{ width: 350, margin: '0 auto' }}>
              <thead>
                <Row>
                  <HeadCell>Version</HeadCell>
                  <HeadCell>Installs</HeadCell>
                  <HeadCell>Licenses</HeadCell>
                  <HeadCell>Entitlements</HeadCell>
                </Row>
              </thead>
              <tbody>
                {licenseData &&
                  licenseData.map((item, index) => (
                    <Row
                      key={item.productId}
                      className={item.inventoryQuantity > 0 ? classes.specialRow : null}
                    >
                      <BodyCell sx={{ textAlign: 'right' }}>{item.versionModelFormatted}</BodyCell>
                      <BodyCell sx={{ textAlign: 'right' }}>{item.inventoryQuantity}</BodyCell>
                      <BodyCell style={{ maxWidth: 100 }}>
                        <Input
                          fullWidth
                          required
                          defaultValue={item.licenseQuantity}
                          type="number"
                          name={item.productId}
                          id={item.productId}
                          onChange={handleInput}
                          className={classes.simpleInput}
                          sx={{ direction: 'rtl' }}
                        />
                      </BodyCell>
                      <BodyCell sx={{ textAlign: 'right' }}>{item.entitlementQuantity}</BodyCell>
                    </Row>
                  ))}
              </tbody>
            </Table>
          </form>
          <input
            type="button"
            value="Update"
            onClick={handleSubmit}
            style={{ display: 'table', margin: '10px auto 0', padding: '4px 12px' }}
          />
          <Table sx={{ mt: 1.5, width: 350, marginLeft: 'auto', marginRight: 'auto' }}>
            <thead>
              <Row>
                <HeadCell colSpan="2">Entitlements Available</HeadCell>
              </Row>
              <Row>
                <HeadCell>For Current Version</HeadCell>
                <HeadCell>For Previous Versions</HeadCell>
              </Row>
            </thead>
            <tbody>
              <WRow>
                <BodyCell sx={summaryData?.currentVersion >= 0 ? posVal : negVal}>
                  {summaryData?.currentVersion}
                </BodyCell>
                <BodyCell sx={summaryData?.previousVersion >= 0 ? posVal : negVal}>
                  {summaryData?.previousVersion}
                </BodyCell>
              </WRow>
            </tbody>
          </Table>
        </Box>
        <Box
          sx={{
            background: '#FFFFFF',
            borderRadius: 1,
            padding: 2,
            boxShadow:
              'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px'
          }}
        >
          <Box className={clsx(classes.dFlex, classes.flexCenter)}>
            <Counter>
              <CounterContent>
                <CounterHits>
                  {formatter.format(getSum(SUM_VALUES.licenseSavings, summaryData))}
                </CounterHits>
                <CounterLabel gutterBottom sx={{ mt: 1 }}>
                  License Intelligence Automatic Savings
                </CounterLabel>
              </CounterContent>
            </Counter>
          </Box>
          <Typography gutterBottom sx={{ mt: 3 }}>
            License Exemptions through Product Usage Rights
          </Typography>
          <Table sx={{ width: 328 }}>
            <thead>
              <Row>
                <HeadCell>Exemption</HeadCell>
                <HeadCell>Count</HeadCell>
                <HeadCell sx={{ maxWidth: 60, textAlign: 'center' }}>License Savings</HeadCell>
              </Row>
            </thead>
            <tbody>
              {summaryData && (
                <>
                  <WRow>
                    <BodyCell sx={{ textAlign: 'center' }}>Duplicate Entries</BodyCell>
                    <BodyCell sx={{ textAlign: 'right' }}>
                      {summaryData?.duplicateEntriesCount}
                    </BodyCell>
                    <BodyCell
                      sx={{ textAlign: 'right' }}
                      className={getClassName(summaryData?.duplicateEntriesMSRP)}
                    >
                      {formatter.format(summaryData?.duplicateEntriesMSRP)}
                    </BodyCell>
                  </WRow>
                  <WRow>
                    <BodyCell sx={{ textAlign: 'center' }}>Downgrade Rights</BodyCell>
                    <BodyCell sx={{ textAlign: 'right' }}>
                      {summaryData?.downGradeRightsCount}
                    </BodyCell>
                    <BodyCell
                      sx={{ textAlign: 'right' }}
                      className={getClassName(summaryData?.downGradeRightsMSRP)}
                    >
                      {formatter.format(summaryData?.downGradeRightsMSRP)}
                    </BodyCell>
                  </WRow>
                  <WRow>
                    <BodyCell sx={{ textAlign: 'center' }}>MSDN Dev/Test</BodyCell>
                    <BodyCell sx={{ textAlign: 'right' }}>
                      {summaryData?.msdN_DevTestCount}
                    </BodyCell>
                    <BodyCell
                      sx={{ textAlign: 'right' }}
                      className={getClassName(summaryData?.msdN_DevTestMSRP)}
                    >
                      {formatter.format(summaryData?.msdN_DevTestMSRP)}
                    </BodyCell>
                  </WRow>
                  <Row>
                    <BodyCell sx={{ textAlign: 'center' }}>Decomissioned Ghost</BodyCell>
                    <BodyCell sx={{ textAlign: 'right' }}>
                      {summaryData?.decommissionedCount}
                    </BodyCell>
                    <BodyCell
                      sx={{ textAlign: 'right' }}
                      className={getClassName(summaryData?.decommissionedMSRP)}
                    >
                      {formatter.format(summaryData?.decommissionedMSRP)}
                    </BodyCell>
                  </Row>
                  <Row>
                    <BodyCell sx={{ textAlign: 'center' }}>3rd Party</BodyCell>
                    <BodyCell sx={{ textAlign: 'right' }}>{summaryData?.thirdPartyCount}</BodyCell>
                    <BodyCell
                      sx={{ textAlign: 'right' }}
                      className={getClassName(summaryData?.thirdPartyMSRP)}
                    >
                      {formatter.format(summaryData?.thirdPartyMSRP)}
                    </BodyCell>
                  </Row>
                  <WRow>
                    <BodyCell sx={{ textAlign: 'right', fontWeight: 600 }}>Totals</BodyCell>
                    <BodyCell sx={{ textAlign: 'right', fontWeight: 600 }}>
                      {getSum(SUM_VALUES.licenseExemptCount, summaryData)}
                    </BodyCell>
                    <BodyCell
                      sx={{ textAlign: 'right', fontWeight: 600 }}
                      className={getClassName(getSum(SUM_VALUES.licenseSavings, summaryData))}
                    >
                      {formatter.format(getSum(SUM_VALUES.licenseSavings, summaryData))}
                    </BodyCell>
                  </WRow>
                </>
              )}
            </tbody>
          </Table>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: '100%', background: '#2196f3' }}
        >
          Saved successfully
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={handleClose}
        invisible
      >
        <Overlay in={loading} onClick={handleClose} timeout={800}>
          Loading...
        </Overlay>
        {/* <Grow easing={{ enter: 'cubic-bezier(0, 2.5, .8, 1)' }} in={loading} timeout={800}>
          <Box sx={{ backgroundColor: 'white !important' }} className={classes.overlay}>
            <Typography sx={{ color: '#364150 !important' }} className={classes.overlayText}>
              <CircularProgress size={16} sx={{ mr: 1 }} /> Saving data...
            </Typography>
          </Box>
        </Grow> */}
      </Backdrop>
    </Box>
  );
}

function getSum(key, data) {
  let sum = 0;
  if (!data) return sum;
  switch (key) {
    case SUM_VALUES.entitlementPrev:
    case SUM_VALUES.entitlementCurrent:
      if (!Array.isArray(data)) return sum;
      data.forEach((item) => {
        let qty = 0;
        if (key === SUM_VALUES.entitlementPrev) {
          if (item.inventoryQuantity <= 0) {
            qty = parseInt(item.entitlementQuantity, 10);
          }
        } else if (key === SUM_VALUES.entitlementCurrent) {
          if (item.inventoryQuantity > 0) {
            qty = parseInt(item.entitlementQuantity, 10);
          }
        }

        if (!Number.isNaN(qty)) {
          sum += qty;
        }
      });

      break;
    case SUM_VALUES.licenseExemptCount:
      sum +=
        parseInt(data.thirdPartyCount, 10) +
        parseInt(data.decommissionedCount, 10) +
        parseInt(data.msdN_DevTestCount, 10) +
        parseInt(data.downGradeRightsCount, 10) +
        parseInt(data.duplicateEntriesCount, 10);
      break;
    case SUM_VALUES.licenseSavings:
      sum +=
        parseInt(data.thirdPartyMSRP, 10) +
        parseInt(data.decommissionedMSRP, 10) +
        parseInt(data.msdN_DevTestMSRP, 10) +
        parseInt(data.downGradeRightsMSRP, 10) +
        parseInt(data.duplicateEntriesMSRP, 10);
      break;

    default:
      break;
  }

  return sum;
}
