import React, { useState } from 'react';
import * as clsx from 'clsx';
// material
import { Stack, Typography, IconButton, Grid, Box } from '@material-ui/core';
// icons
import SettingsIcon from '@material-ui/icons/Settings';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ViewListIcon from '@material-ui/icons/ViewList';
import PrintIcon from '@material-ui/icons/Print';
import SoftwareState from './devices/softwareState';
import useStyles from '../../../styles';

export default function Controls(props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  return (
    <>
      <Stack className={clsx(classes.controlsContainerCR, classes.controlsRibbon)}>
        <Typography variant="h4" sx={{ color: '#768795', ml: 1 }}>
          Resulting Devices with both Software A and B
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 0.5 }}>
          {/* left icon buttons */}
          <Grid>
            <IconButton
              aria-label="delete"
              className={classes.iconBtnCR}
              s
              sx={{ ml: 1 }}
              disableRipple
            >
              <SettingsIcon className={classes.selectIcon} />
              Hellos
              <SoftwareState selectedRows={props.selectedRows} getStatus={props.getStatus} />
            </IconButton>
            <IconButton
              onClick={props.exportToExcel}
              aria-label="export"
              className={classes.iconBtnCR}
              sx={{ ml: 1 }}
              disableRipple
            >
              <img alt="export" src="/assets/icons/excel_export.png" style={{ marginLeft: 2 }} />
              <Typography variant="p" className={classes.mlHalf}>
                Export
              </Typography>
            </IconButton>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
}
