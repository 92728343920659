import React from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { formatter } from '../../helpers';

// const quantityComponent = (params) => {
//   const element = document.createElement('div');
//   const imageElement = document.createElement('img');
//   const dataSpan = document.createElement('span');
//   const data = document.createTextNode(params.value);
//   imageElement.src = '/assets/publisher_grid.gif';
//   let colName;

//   if (params.column.colId === 'entitlementQuantity') {
//     colName = 'entitlementQuantity';
//   } else {
//     colName = 'licenseQuantity';
//   }

//   if (params.data[colName] > 0) {
//     imageElement.src = '/assets/caret_up.png';
//     element.appendChild(imageElement);
//     dataSpan.style.color = 'green';
//   } else if (params.data[colName] < 0) {
//     imageElement.src = '/assets/caret_down.png';
//     element.appendChild(imageElement);
//     dataSpan.style.color = 'red';
//   }

//   dataSpan.appendChild(data);
//   element.style.display = 'flex';
//   element.style.alignItems = 'center';
//   element.appendChild(dataSpan);
//   imageElement.style.verticalAlign = 'text-bottom';
//   dataSpan.style.marginLeft = 'auto';
//   return element;
// };

export default function GridExample(props) {
  return (
    <div
      style={{
        height: 'calc(100vh - 267px)',
        width: '100%'
      }}
      className="ag-theme-alpine"
    >
      <AgGridReact
        defaultColDef={{
          flex: 1,
          minWidth: 40,
          sortable: true,
          filter: true,
          floatingFilter: true,
          resizable: true
        }}
        // autoGroupColumnDef={{ minWidth: 200 }}
        rowSelection="single"
        headerHeight="25"
        floatingFiltersHeight="31"
        rowHeight="25"
        animateRows="true"
        rowData={props.rowData}
      >
        <AgGridColumn headerName="Product-Line">
          <AgGridColumn
            minWidth="220"
            field="productLine"
            headerName=""
            filter="agTextColumnFilter"
          />
          <AgGridColumn
            minWidth="60"
            field="licenseType"
            headerName="License Type"
            filter={false}
          />
          <AgGridColumn
            minWidth="40"
            cellStyle={{ textAlign: 'right' }}
            field="deployed"
            headerName="Deployed"
            filter={false}
          />
        </AgGridColumn>
        <AgGridColumn headerName="Entitlements Available">
          <AgGridColumn
            field="currentCount"
            cellStyle={(params) => getStyles(params, 'currentCount')}
            headerName="Current Version"
            filter={false}
          />
          <AgGridColumn
            field="lowerVersion"
            cellStyle={(params) => getStyles(params, 'lowerVersion')}
            headerName="Lower Versions"
            filter={false}
          />
        </AgGridColumn>
        <AgGridColumn
          minWidth="40"
          cellStyle={{ textAlign: 'right' }}
          field="msrp"
          filter={false}
          headerName="MSRP"
          valueGetter={(params) => formatter.format(params.data?.msrp)}
        />
        <AgGridColumn headerName="">
          <AgGridColumn
            minWidth="95"
            cellStyle={{ textAlign: 'right' }}
            field="tuneUp"
            filter={false}
            headerName="$ True Up Cost Estimate"
            valueGetter={(params) => formatter.format(params.data?.tuneUp)}
          />
        </AgGridColumn>
      </AgGridReact>
    </div>
  );
}

function getStyles(params, colName) {
  let style = { textAlign: 'right' };
  if (params.data[colName] > 0) {
    style = { ...style, color: 'green' };
  }

  if (params.data[colName] < 0) {
    style = { ...style, color: 'red' };
  }
  return style;
}
