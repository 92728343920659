import React, { useReducer, useState, useEffect } from 'react';
import clsx from 'clsx';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography, TextField, Checkbox, Link } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/core/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import CircularProgress from '@material-ui/core/CircularProgress';
import BtnLight from '../../components/buttons/ButtonLight';
import useStyles from '../../styles/index';
import { eraseCookie, setCookie } from '../../helpers';
import { postUserLogin } from '../../server';

const currentYear = new Date().getFullYear();
const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

export default function Login(props) {
  const classes = useStyles();
  const label = { inputProps: { 'aria-label': 'Remember Me' } };
  const navigate = useNavigate();

  const [formInput, setFormInput] = useReducer((state, newState) => ({ ...state, ...newState }), {
    password: '',
    emailAddress: ''
  });

  useEffect(() => {
    eraseCookie('authed');
  }, []);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = (evt) => {
    localStorage.clear();
    evt.preventDefault();
    setLoading(true);
    postUserLogin(formInput)
      .then((response) => {
        setLoading(false);
        if (response.status === 401) {
          setOpen(true);
        } else {
          const expiryMinutes = 60;
          setCookie('authed', true, expiryMinutes);
          localStorage.setItem('user_email', response.data.userEmail);
          navigate(state?.path || '/publishers/by-install-count');
        }
      })
      .catch((error) => {
        setLoading(false);
        setOpen(true);
      });
  };

  const handleInput = (evt) => {
    const {
      target: { name }
    } = evt;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };

  return (
    <Box className={classes.authBody}>
      <Box className={classes.authSection}>
        <Box
          className={classes.authCard}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box className={classes.authForm} sx={{ mt: 0 }}>
            <Box className={classes.authLogo}>
              <img
                src="./static/logo/AssetLabs-White-Blue-Text.jpg"
                alt=""
                width="250px"
                height="auto"
              />
              <Typography
                className={clsx(classes.authLogoCaption, classes.mb40, classes.textCenter)}
              >
                Software Asset Management: Client Portal
              </Typography>
            </Box>
            <Box className={classes.mt10}>
              <Typography
                variant="h5"
                className={clsx(
                  classes.greet,
                  classes.authHide,
                  classes.fontWeightNormal,
                  classes.formWidth
                )}
                color="text.secondary"
              >
                Welcome!
              </Typography>
              <form onSubmit={handleSubmit}>
                <Box className={clsx(classes.mt20, classes.mb20)}>
                  <TextField
                    fullWidth
                    required
                    type="text"
                    name="emailAddress"
                    id="emailAddress"
                    label="Email"
                    variant="outlined"
                    onChange={handleInput}
                    className={classes.authInput}
                  />
                </Box>
                <Box className={classes.mb10}>
                  <TextField
                    fullWidth
                    required
                    type="password"
                    id="password"
                    minLength="3"
                    name="password"
                    label="Password"
                    variant="outlined"
                    onChange={handleInput}
                    className={classes.authInput}
                  />
                </Box>
                <Box className={classes.checkbox}>
                  <Checkbox {...label} defaultChecked color="default" id="checkbox" />
                  <>{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
                  <label className={classes.check} htmlFor="checkbox">
                    Remember Me
                  </label>
                </Box>
                <BtnLight disabled={loading} type="submit">
                  login
                </BtnLight>
              </form>
              <Box className={clsx(classes.mt20, classes.formLinksContainer)}>
                <Link
                  href="https://assetlabs.net/App/Login/ForgotPassword.aspx"
                  className={clsx(
                    classes.mb10,
                    classes.formLinks,
                    classes.fontWeightNormal,
                    classes.fontSmall
                  )}
                >
                  Forgot Password?
                </Link>
                {/* <Link
                  href="/resend-email"
                  className={clsx(classes.formLinks, classes.fontWeightNormal, classes.fontSmall)}
                >
                  Resend Activation Link
                </Link> */}
              </Box>
            </Box>
            {/* <Box
              className={clsx(classes.cardFooter, classes.loginFooterSettings)}
            >
              <Typography sx={{ fontSize: 12 }}>
                Don't have an AssetLabs account?&nbsp;
                <Link href="#" className={clsx(classes.footerLinks, classes.fontWeightNormal)}>
                  See a Demo
                </Link>
              </Typography>
            </Box> */}
          </Box>
          <img src="./static/office_image.jpg" alt="" className={classes.authImage} />
        </Box>
        <Box className={clsx(classes.mt20, classes.textCenter, classes.authFooter)}>
          <Typography className={classes.authFooterText}>
            &copy; {currentYear} AssetLabs Inc. All rights reserved.&nbsp;
            <Link
              href="https://www.assetlabs.com/contact/"
              className={clsx(classes.footerLinks, classes.fontWeightNormal)}
            >
              Contact Us
            </Link>
          </Typography>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Invalid credentials.
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={handleClose}
      >
        <Grow easing={{ enter: 'cubic-bezier(0, 2.5, .8, 1)' }} in={loading} timeout={800}>
          <Box className={clsx(classes.overlay, classes.dFlex, classes.alignCenter)}>
            <CircularProgress color="inherit" size={22} />
            <Typography className={clsx(classes.overlayText, classes.mx20)}>
              Logging In...
            </Typography>
          </Box>
        </Grow>
      </Backdrop>
    </Box>
  );
}
