import React, { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import Chart from 'react-apexcharts';
// import Overlay from '../Overlay';
import { ReportContext } from '../../context/ReportContext';
import { getProductLineQtys } from '../../server';
import 'chartjs-adapter-date-fns';

const colors = ['#091D34', '#133863', '#2369B8', '#609CE1', '#E1ECF9'];

export default function MultiAxisLine() {
  const contextData = useContext(ReportContext);
  const [labels, setlabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const array = [];
  const series = datasets;
  const options = {
    chart: {
      height: 350,
      type: 'line',
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    markers: {
      size: 5
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      }
    },
    xaxis: {
      type: 'datetime'
    }
  };

  useEffect(() => {
    for (let i = 0; i < contextData.level[0].allLeafChildren.length; i += 1) {
      array.push(contextData.level[0].allLeafChildren[i].data.productId);
    }
    getProductLineQtys(array).then((res) => {
      updateData(res.data);
    });
  }, [contextData]);

  const updateData = (data) => {
    if (!data || !data.reportList) return;
    let index = 0;
    // const arrXaxis = [];
    const datasetResult = _(data.reportList)
      .groupBy((x) => x.productId)
      .map((values, key) => {
        const dataSet = [];

        values.forEach((o) => {
          dataSet.push({
            x: o.pulseDate,
            y: o.quantity
          });
        });
        const dSet = {
          name: values[0].productName,
          data: dataSet,
          // labels: [],
          backgroundColor: colors[index],
          borderColor: colors[index]
        };

        index += 1;
        return dSet;
      })
      .value();
    // console.log(arrXaxis);
    // console.log(datasetResult);
    // setlabels(arrXaxis);
    console.log(datasetResult);
    setDatasets(datasetResult);
  };

  return (
    <>
      <div id="chart">
        <Chart options={options} series={series} type="line" height={350} />
      </div>
    </>
  );
}
